import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Dropzone from 'pages/landing/addBlog/Dropzone'

const useStyles = makeStyles(() => ({
  imageContainer: {
    marginRight: '1rem',
    gap: '1rem'
  },
  imagePreview: {
    height: '4.5rem'
  },
  dropzone: {
    marginRight: 0
  }
}))

export default function ImagePicker({
  imageSrc,
  imageAlt = '',
  onChange,
  className = '',
  ...props
}) {
  const classes = useStyles()

  return (
    <Grid
      className={clsx(className, classes.imageContainer)}
      wrap="nowrap"
      container
      alignItems="center"
    >
      {imageSrc && (
        <img className={classes.imagePreview} src={imageSrc} alt={imageAlt} />
      )}
      <Dropzone className={classes.dropzone} onChange={onChange} {...props} />
      {imageSrc && (
        <IconButton onClick={() => onChange(null, null)}>
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  )
}
