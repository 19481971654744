import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardSubtitle,
  CardTitle,
  CardActions
} from 'components/cards/Card'

export default function GroupCard({ editTo, group, onDelete }) {
  return (
    <Card>
      <CardContent>
        <div>
          <CardTitle>{group['display_name']}</CardTitle>
          <CardSubtitle>
            {group['includes_coins']?.length || 0} included coins
          </CardSubtitle>
          <CardSubtitle>
            {group['includes_groups']?.length || 0} groups
          </CardSubtitle>
        </div>

        <CardActions>
          <IconButton component={Link} to={editTo}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </CardContent>
    </Card>
  )
}

GroupCard.propTypes = {
  editTo: PropTypes.string,
  group: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}
