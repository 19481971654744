import { toast } from 'react-toastify'
import { addPdf } from 'services/api/pdf'
import { formatValues } from 'utils/pdf'

export default function usePdf() {
  const onAdd = async (values, onSuccess) => {
    try {
      await addPdf(formatValues(values))
      toast.success('Successfully added')
      onSuccess?.()
    } catch (e) {
      console.error(e)
      toast.error('Oops error')
    }
  }

  return {
    onAdd
  }
}
