import useUsers from 'hooks/useUsers'
import TablePageContainer from 'components/TablePageContainer'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from '@material-ui/core'
import { useMemo, useState } from 'react'
import format from 'date-fns/format'
import { DATE_FORMAT } from 'utils/date'
import TableActionsCell from 'components/table/TableActionsCell'
import Button from 'components/buttons/Buttons'
import EditIcon from '@material-ui/icons/Edit'
import AddUserDrawer from 'components/AddUserDrawer'

const COLS = [
  { name: 'email', label: 'Email' },
  { name: 'date', label: 'Date Added' },
  { name: 'role', label: 'Role' },
  { name: 'actions', label: '', align: 'right' }
]

export default function Users() {
  const users = useUsers()
  const [userId, onUserId] = useState('')

  const Renderer = useMemo(
    () => ({
      email: (row) => row['email'],
      date: (row) =>
        row['date_created']
          ? format(new Date(row['date_created']), DATE_FORMAT)
          : 'N/A',
      role: (row) => row['role'] || 'N/A',
      actions: (row) => (
        <TableActionsCell align="right">
          <Button tooltip="Edit User" onClick={() => onUserId(row['email'])}>
            <EditIcon />
          </Button>
        </TableActionsCell>
      )
    }),
    []
  )

  return (
    <>
      <TablePageContainer cols={COLS}>
        {users.data.map((row) => (
          <TableRow key={row.email}>
            {COLS.map((col) => (
              <TableCell key={col.name} align={col.align}>
                {Renderer[col.name](row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TablePageContainer>

      <AddUserDrawer
        userId={userId}
        open={!!userId}
        onClose={() => onUserId('')}
        revalidate={users.revalidate}
      />
    </>
  )
}
