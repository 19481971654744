import { useState } from 'react'
import useSWR from 'swr'

// API Service
import { buildRoute, fetcher } from '../services/api/common'

export const useBribeGauges = (voteToken) => {
  const [filters, onFilters] = useState({
    page: 1,
    per_page: 25
  })

  let route = null
  if (voteToken) {
    route = buildRoute('/get_gauges', {
      ...filters,
      vote_token_symbol: voteToken
    })
  }

  const { data, error, mutate } = useSWR(route, fetcher)

  const onPage = (page) => {
    onFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !data && !error
  // TODO: Remove placeholder data
  const gauges = data?.data || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    gauges,
    filters,
    onPage,
    maxPages,
    mutate
  }
}
