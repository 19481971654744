import useSWR, { cache } from 'swr'
import { getAllBridgesCombinations } from 'services/api/bridges'

const key = '/get_all_bridge_combinations'

export default function useAllBridgesCombinations() {
  const { data, error, mutate } = useSWR(key, getAllBridgesCombinations, {
    revalidateOnMount: !cache.has(key),
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const isLoading = !data && !error
  const combinations = data || []
  return {
    combinations,
    isLoading,
    mutate
  }
}
