import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerActions from 'components/drawer/DrawerActions'
import Button from '@material-ui/core/Button'
import DrawerContent from 'components/drawer/DrawerContent'
import Select from 'components/form/Select'
import {
  INITIAL_FARMS_FILTERS,
  RISK_LEVEL_OPTIONS,
  STATUS_FILTERS,
  TVL_MIN_FILTERS
} from 'utils/filters'
import { useState } from 'react'
import PropTypes from 'prop-types'

function FarmsFiltersDrawerContent({ filters, onFilters, onClose }) {
  const [innerFilters, setInnerFilters] = useState(filters)

  const handleApply = () => {
    onFilters(innerFilters)
    onClose()
  }

  const handleReset = () => {
    onFilters(INITIAL_FARMS_FILTERS)
    onClose()
  }

  const handleChange = (name, value) => {
    setInnerFilters((filters) => ({
      ...filters,
      [name]: value
    }))
  }

  return (
    <>
      <DrawerHead title="Apply Filters" onClose={onClose} />

      <DrawerContent>
        <Select
          name="Status"
          options={STATUS_FILTERS}
          value={STATUS_FILTERS.find((o) => o?.value === innerFilters.active)}
          onChange={(e, value) =>
            handleChange('active', value ? value.value : null)
          }
        />
        <Select fullWidth name="Source" options={[]} />

        <Select
          name="Risk Level"
          options={RISK_LEVEL_OPTIONS}
          value={RISK_LEVEL_OPTIONS.find(
            (o) => o?.value === innerFilters.riskLevel
          )}
          onChange={(e, value) =>
            handleChange('riskLevel', value ? value.value : null)
          }
        />
        <Select
          name="TVL min"
          options={TVL_MIN_FILTERS}
          value={TVL_MIN_FILTERS.find((o) => o?.value === innerFilters.tvl_min)}
          onChange={(e, value) =>
            handleChange('tvl_min', value ? value.value : null)
          }
        />
      </DrawerContent>

      <DrawerActions>
        <Button variant="contained" color="default" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" color="primary" onClick={handleApply}>
          Apply
        </Button>
      </DrawerActions>
    </>
  )
}

FarmsFiltersDrawerContent.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  onClose: PropTypes.func
}

export default function FarmsFiltersDrawer({ open, ...props }) {
  return (
    <Drawer open={open}>
      <FarmsFiltersDrawerContent {...props} />
    </Drawer>
  )
}

FarmsFiltersDrawer.propTypes = {
  open: PropTypes.bool
}
