import Drawer from 'components/drawer/Drawer'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DrawerHead from 'components/drawer/DrawerHead'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import DrawerContent from 'components/drawer/DrawerContent'
import DrawerActions from 'components/drawer/DrawerActions'
import {
  BLOCKCHAIN_OPTIONS,
  BRIDGE_COMBINATIONS_FIELDS,
  RANK_OPTIONS
} from 'utils/forms'
import useValues from 'hooks/useValues'
import { createOption } from 'utils/form'
import Select from 'components/form/Select'
import PropTypes from 'prop-types'
import useBridge from 'hooks/useBridge'
import { useHistory, useParams } from 'react-router-dom'
import useAllBridgesCombinations from 'hooks/useAllBridgesCombinations'
import { useEffect } from 'react'

const useStyles = makeStyles(() => ({
  paper: {
    width: 500
  },
  content: {
    flex: 1,
    height: '100%'
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  }
}))

function Content({ closeTo, onClose }) {
  const classes = useStyles()
  const { values, onChange } = useValues()
  const { onAdd } = useBridge()
  const params = useParams()
  const history = useHistory()
  const { combinations } = useAllBridgesCombinations()

  const isEdit = !!params.name

  const onSuccess = () => {
    isEdit ? history.push(closeTo) : onClose?.()
  }

  useEffect(() => {
    if (params.name) {
      const comb = combinations.find(
        (c) => c['bridgeName'] === decodeURIComponent(params.name)
      )
      if (comb) {
        Object.keys(comb).forEach((key) => {
          if (key === 'blockchains') {
            onChange(
              key,
              comb[key].map((b) => createOption(b, b))
            )
          } else if (key === 'rank') {
            onChange(key, createOption(comb[key], comb[key]))
          } else {
            onChange(key, comb[key])
          }
        })
      }
    }
  }, [params.name, combinations.length])

  const handleSave = () => {
    onAdd(values, onSuccess)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.content}
    >
      <DrawerHead
        title={isEdit ? 'Edit Bridge Combination' : 'Add Bridge Combination'}
        closeTo={closeTo}
        onClose={onClose}
      />

      <DrawerContent>
        {BRIDGE_COMBINATIONS_FIELDS.map((key) => {
          if (key === 'blockchains') {
            return (
              <Grid container alignItems="center" key={key}>
                <Select
                  multiple
                  name={key}
                  options={BLOCKCHAIN_OPTIONS}
                  className={classes.input}
                  onChange={(e, o) => {
                    onChange(key, o)
                  }}
                  value={
                    values['blockchains']?.value
                      ?.filter(Boolean)
                      ?.map((b) =>
                        typeof b === 'string' ? createOption(b, b) : b
                      ) || []
                  }
                />
              </Grid>
            )
          }
          if (key === 'rank') {
            return (
              <Grid container alignItems="center" key={key}>
                <Select
                  name={key}
                  options={RANK_OPTIONS}
                  className={classes.input}
                  onChange={(e, o) => {
                    onChange(key, o)
                  }}
                  value={values['rank']?.value || null}
                />
              </Grid>
            )
          }
          return (
            <Grid container alignItems="center" key={key}>
              <TextField
                className={classes.input}
                label={key}
                variant="outlined"
                value={values[key]?.value || ''}
                size="small"
                margin="normal"
                onChange={(e) => onChange(key, e.target.value)}
              />
            </Grid>
          )
        })}
      </DrawerContent>

      <DrawerActions
        cancelTo="/bridges"
        onClose={onClose}
        onSave={handleSave}
      />
    </Grid>
  )
}

Content.propTypes = {
  closeTo: PropTypes.string,
  onClose: PropTypes.func
}

export function AddBridgeDrawer({ open, ...props }) {
  const classes = useStyles()
  return (
    <Drawer open={open} classes={{ paper: classes.paper }} anchor="right">
      <Content {...props} />
    </Drawer>
  )
}

AddBridgeDrawer.propTypes = {
  open: PropTypes.bool
}
