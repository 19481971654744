import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerContent from 'components/drawer/DrawerContent'
import DrawerActions from 'components/drawer/DrawerActions'
import Button from 'components/buttons/Buttons'
import useUser from 'hooks/useUser'
import { useForm, Controller } from 'react-hook-form'
import { useEffect } from 'react'
import Input from 'components/form/Input'
import { SelectRoles } from 'components/form/SelectRoles'
import { formatApiForm } from 'utils/users'

function Content(props) {
  const user = useUser({
    userId: props.userId
  })

  const form = useForm({
    defaultValues: {
      email: '',
      role: ''
    }
  })

  useEffect(() => {
    if (user.data.email) {
      form.reset(formatApiForm(user.data))
    }
  }, [JSON.stringify(user.data)])

  const handleSubmit = (values) => {
    user.onEdit(values, () => {
      props.revalidate?.()
      props.onClose()
    })
  }

  return (
    <>
      <DrawerHead
        title={props.userId ? 'Edit User' : 'Add User'}
        onClose={props.onClose}
        closeTo={props.closeTo}
      />

      <DrawerContent>
        <Controller
          control={form.control}
          render={(e) => <Input label="Email" value={e.field.value} disabled />}
          name="email"
        />
        <Controller
          control={form.control}
          render={(e) => (
            <SelectRoles
              label="Role"
              value={(() => {
                return e.field.value
              })()}
              onChange={(_e, __e) => {
                form.setValue(e.field.name, __e?.value || '')
              }}
            />
          )}
          name="role"
        />
      </DrawerContent>

      <DrawerActions>
        <Button color="secondary">Reset</Button>
        <Button onClick={() => form.handleSubmit(handleSubmit)()}>Save</Button>
      </DrawerActions>
    </>
  )
}

export default function AddUserDrawer(props) {
  return (
    <Drawer open={props.open}>
      <Content {...props} />
    </Drawer>
  )
}
