// TODO: Remove
/* eslint-disable no-unused-vars */

// API Service
import Api from 'services/Api'

/**
 * Edits the given gauge.
 * @param {string} gaugeId - Gauge's id.
 * @param {Record<string, any>} data - Update data.
 * @returns Response.
 */
export const editBribeGauge = async (gaugeId, data) => {
  try {
    const response = await Api.post(`/edit_gauge?id=${gaugeId}`, data)
    return response.data
  } catch (error) {
    return { error }
  }
}
