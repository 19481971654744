import { stringifyUrl } from 'query-string'
import { toNumber } from 'lodash'

// API instance
import Api from '../Api'

export async function fetcher(url) {
  const response = await Api.get(url)
  return response.data
}

/**
 * Stringifies the request params.
 * @param {string} route - Route endpoint.
 * @param {Record<string, any>} params - Data to add to query params.
 * @returns Stringified url.
 */
export const buildRoute = (route, params) => {
  return stringifyUrl({ url: route, query: params })
}

/**
 * Formats the form data into a request ready payload.
 * @param {Record<string, string>} data
 * @returns {Record<string, string>} Request ready payload.
 */
export const formatFormData = (data) => {
  const formattedData = {}
  for (const key of Object.keys(data)) {
    let value = data[key].value
    if (value) {
      const numberCast = toNumber(value)
      if (!Number.isNaN(numberCast)) {
        value = numberCast
      }
    }
    formattedData[key] = value
  }
  return formattedData
}
