import useSWR from 'swr'
import { getAssetDetails } from 'services/api/assets'
import { stringifyURL } from 'utils/url'

export function getKey(params) {
  return stringifyURL('/get_asset_details', params)
}

export default function useAssetDetails(id) {
  const params = {
    assetId: id
  }

  const { data, error } = useSWR(id ? getKey(params) : null, getAssetDetails)

  const isLoading = !data && !error
  const asset = data || {}
  return {
    isLoading,
    asset
  }
}
