import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { getCareersContents } from 'services/api/careers'
import useSingleCareer from 'hooks/useSingleCareer'

function getKey(params) {
  return stringifyURL('/get_careers', params)
}

export default function useCareers() {
  const params = {
    page: 1
  }

  const { data, error, mutate } = useSWR(
    () => getKey(params),
    getCareersContents
  )

  const singleCareer = useSingleCareer({ mutate })

  const isLoading = !data && !error
  const careers = data || []

  return {
    isLoading,
    careers,
    ...singleCareer
  }
}
