// Pages
import Assets from 'pages/Assets'
import FarmAssets from 'pages/FarmAssets'
import Farms from 'pages/Farms'
import Groups from 'pages/Groups'
import Settings from 'pages/Settings'
import Bridges from 'pages/Bridges'
import Landing from 'pages/Landing'
import Leaderboards from 'pages/Leaderboards'
import Errors from 'pages/Errors'
import Pdfs from 'pages/Pdfs'
import Waitlist from 'pages/Waitlist'
import Users from 'pages/Users'
import Roles from 'pages/Roles'
import Exchanges from 'pages/Exchanges'
import { BribePlatforms } from 'pages/BribePlatforms'
import { BribeGauges } from 'pages/BribeGauges'
import { BribeGovernance } from 'pages/BribeGovernance'

const ASSETS_PAGE = { path: '/assets/:id?', Component: Assets }
const FARM_ASSETS_PAGE = {
  path: '/farms/:id/grid/:assetId?',
  Component: FarmAssets,
  exact: true
}
const FARMS_PAGE = { path: '/farms/:id?', Component: Farms }
const SETTINGS_PAGE = { path: '/settings', Component: Settings }
const GROUPS_PAGE = {
  path: '/groups/:action(add|edit)?/:id?',
  Component: Groups
}
const BRIDGES_PAGE = { path: '/bridges/:name?', Component: Bridges }
const LANDING_PAGE = { path: '/landing', Component: Landing }
const LEADERBOARDS_PAGE = {
  path: '/leaderboards/:id?',
  Component: Leaderboards
}
const ERRORS_PAGE = { path: '/errors', Component: Errors }
const PDFS_PAGE = { path: '/pdfs', Component: Pdfs }
const WAITLIST_PAGE = { path: '/waitlist', Component: Waitlist }
const USERS_PAGE = { path: '/users', Component: Users }
const ROLES_PAGE = { path: '/roles', Component: Roles }
const EXCHANGES_PAGE = { path: '/exchanges', Component: Exchanges }
const BRIBE_PLATFORMS_PAGE = {
  path: '/bribe-platforms',
  Component: BribePlatforms
}
const BRIBE_GAUGES_PAGE = { path: '/bribe-gauges', Component: BribeGauges }
const BRIBE_GOVERNANCE_PAGE = {
  path: '/bribe-governance',
  Component: BribeGovernance
}

export const ALL_PAGES = [
  ASSETS_PAGE,
  FARM_ASSETS_PAGE,
  FARMS_PAGE,
  SETTINGS_PAGE,
  GROUPS_PAGE,
  BRIDGES_PAGE,
  LANDING_PAGE,
  LEADERBOARDS_PAGE,
  ERRORS_PAGE,
  PDFS_PAGE,
  WAITLIST_PAGE,
  USERS_PAGE,
  ROLES_PAGE,
  EXCHANGES_PAGE,
  BRIBE_PLATFORMS_PAGE,
  BRIBE_GAUGES_PAGE,
  BRIBE_GOVERNANCE_PAGE
]

export const PALADIN_PAGES = [
  BRIBE_PLATFORMS_PAGE,
  BRIBE_GAUGES_PAGE,
  BRIBE_GOVERNANCE_PAGE
]
