import {
  Card,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  title: {
    marginBottom: '2rem'
  },
  spaceRight: {
    marginRight: '10px'
  },
  assetDetails: {
    margin: '2rem 0'
  },
  assetDetailsGrid: {
    width: '100%'
  },
  assetDetailsTitle: {
    marginRight: '20px'
  },
  assetToken: {
    padding: '10px'
  },
  urls: {
    cursor: 'pointer',
    color: '#2a99da'
  }
})

export const AssetToken = ({ assetToken, onEdit, onDelete }) => {
  const classes = useStyles()
  return (
    <>
      <Card className={classes.assetToken} title={assetToken.asset}>
        <Grid className={classes.assetDetailsGrid}>
          <Grid className={classes.titleContainer}>
            <Typography className={classes.assetDetailsTitle}>
              <span>{assetToken.asset}</span>
            </Typography>
            <div>
              <IconButton
                className={`${classes.spaceRight}`}
                size="small"
                onClick={() => onEdit(assetToken)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDelete(assetToken.asset)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Grid>
          <div>{assetToken.description}</div>
          <br />
          <div>
            Website URL:{' '}
            <a
              className={classes.urls}
              href={assetToken.website_url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {assetToken.website_url || '/'}
            </a>
          </div>
          <div>
            Contract URL:{' '}
            <a
              className={classes.urls}
              href={assetToken.contract_url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {assetToken.contract_url || '/'}
            </a>
          </div>
        </Grid>
      </Card>
    </>
  )
}
