import useSWR from 'swr'
import { getFarmDetails } from 'services/api/farms'
import { stringifyURL } from 'utils/url'

export function getKey(params) {
  return stringifyURL('/get_farm_details', params)
}

export default function useFarmDetails(id) {
  const params = {
    farmId: id
  }

  const { data, error } = useSWR(id ? getKey(params) : null, getFarmDetails)

  const isLoading = !data && !error
  const farm = data || {}
  return {
    isLoading,
    farm
  }
}
