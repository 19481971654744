import { KeyboardDatePicker } from '@material-ui/pickers'

export default function DatePicker(props) {
  return (
    <KeyboardDatePicker
      clearable
      placeholder="10/10/2018"
      format="dd/MM/yyyy"
      inputVariant="outlined"
      size="small"
      margin="normal"
      {...props}
    />
  )
}
