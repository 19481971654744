import { convertTitleToId } from 'utils/blog'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useSWR from 'swr'
import { addCareer, getSingleCareer, removeCareer } from 'services/api/careers'

export default function useSingleCareer(config = {}) {
  const history = useHistory()

  const { data, error, mutate } = useSWR(
    config.id ? `/get_careers?career_id=${config.id}` : null,
    getSingleCareer
  )

  const onAdd = async (values) => {
    try {
      const data = {
        ...values,
        date_added: values.date_added || new Date(),
        career_id: values.career_id || convertTitleToId(values.title)
      }
      await addCareer(data)

      toast.success(
        values.career_id
          ? 'Career edited successfully'
          : 'Career added successfully'
      )

      config.mutate?.()
      config.id && mutate()
      history.push('/landing/careers')
    } catch (e) {
      toast.error('Error adding career :(')
      console.error(e)
    }
  }

  const onRemove = async (id) => {
    try {
      await removeCareer(id)

      toast.success('Career removed successfully')

      config.mutate?.()
    } catch (e) {
      toast.error('Career removing blog :(')
      console.error(e)
    }
  }

  const singleCareer = data || {}
  const isSingleCareerLoading = !data && !error
  return {
    onAdd,
    onRemove,
    singleCareer,
    isSingleCareerLoading
  }
}
