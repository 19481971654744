import { Route, Switch } from 'react-router-dom'
import Login from 'pages/Login'
import AuthorizedApp from 'components/containers/AuthorizedApp'

function App() {
  return (
    <>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <AuthorizedApp />
      </Switch>
    </>
  )
}

export default App
