import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Select from 'components/form/Select'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import Dropzone from 'pages/landing/addBlog/Dropzone'
import DeleteIcon from '@material-ui/icons/Delete'
import { useState } from 'react'
import { ADDITIONAL_SERVICES } from 'utils/forms'
import useUpdateAsset from 'hooks/useUpdateAsset'

const useStyles = makeStyles({
  paper: {
    width: '100%'
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '64px 1fr 1fr 1fr 1fr 64px',
    gap: '1.25rem',
    marginBottom: '1.25rem'
  },
  btn: {
    height: 40
  },
  dropzone: {
    margin: 0,

    '& button': {
      margin: 0,
      height: 40,
      width: '100%'
    }
  },
  actions: {
    padding: '1.25rem'
  }
})

function addService() {
  return {
    type: '',
    text: '',
    icon: '',
    link: ''
  }
}

function Content({ onClose, id }) {
  const classes = useStyles()
  const [services, setServices] = useState([addService()])

  const updateAsset = useUpdateAsset()

  const handleAdd = () => {
    setServices((services) => [...services, addService()])
  }

  const handleRemove = (index) => {
    setServices((services) => services.filter((s, i) => i !== index))
  }

  const handleUpdate = (index, name, value) => {
    setServices((services) => {
      const copy = [...services]
      copy[index][name] = value
      return copy
    })
  }

  const disableRemove = () => {
    return services.length === 1
  }

  const disabledAdd = () => {
    return services.length === 3
  }

  const getValue = (index, name) => {
    return services[index][name]
  }

  const handleSubmit = () => {
    updateAsset.onSaveAsset(
      id,
      {
        additionalServices: services
      },
      onClose
    )
  }

  return (
    <>
      <DialogTitle>Additional Services</DialogTitle>

      <DialogContent>
        {services.map((row, index) => (
          <Grid container className={classes.row} key={index}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={handleAdd}
              disabled={disabledAdd()}
            >
              <AddIcon />
            </Button>

            <Select
              name="Type"
              margin={false}
              options={ADDITIONAL_SERVICES}
              value={
                ADDITIONAL_SERVICES.find(
                  (s) => s.value === getValue(index, 'type')
                ) || ''
              }
              onChange={(e, a) => handleUpdate(index, 'type', a?.value)}
            />

            <TextField
              label="Text"
              type="text"
              variant="outlined"
              size="small"
              value={getValue(index, 'text')}
              onChange={(e) => handleUpdate(index, 'text', e.target.value)}
            />

            <TextField
              label="Link"
              type="text"
              variant="outlined"
              size="small"
              value={getValue(index, 'link')}
              onChange={(e) => handleUpdate(index, 'link', e.target.value)}
            />

            <Dropzone
              fullWidth
              renderOnBtn
              title="Upload icon"
              className={classes.dropzone}
            />

            <Button
              variant="contained"
              color="secondary"
              className={classes.btn}
              onClick={() => handleRemove(index)}
              disabled={disableRemove()}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        ))}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="contained" color="default">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit()
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}

export default function AdditionalServicesDialog(props) {
  const classes = useStyles()
  return (
    <Dialog
      maxWidth="xl"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.paper
      }}
    >
      <Content {...props} />
    </Dialog>
  )
}
