import TablePageContainer from 'components/TablePageContainer'
import useFarms from 'hooks/useFarms'
import TableCell from '@material-ui/core/TableCell'
import { DATE_FORMAT, format, toDate } from 'utils/date'
import Button from '@material-ui/core/Button'
import { Link, useLocation, useParams } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ListIcon from '@material-ui/icons/List'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import TableRow from '@material-ui/core/TableRow'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import AddFarmDrawer from 'components/AddFarmDrawer'
import { useState } from 'react'
import AddAssetDrawer from 'components/AddAssetDrawer'
import IconButton from '@material-ui/core/IconButton'
import { FarmsContext } from 'utils/contexts'
import FarmsFiltersDrawer from 'components/FarmsFiltersDrawer'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const COLS = [
  { name: 'farm', label: 'Farm' },
  { name: 'farmId', label: 'Farm ID' },
  { name: 'blockchain', label: 'Blockchain' },
  { name: 'date_added', label: 'Date added' },
  { name: 'tvl_change_24h', label: 'Change (24h)' },
  { name: 'actions', label: '', align: 'right' }
]

const useStyles = makeStyles({
  button: {
    marginRight: '1rem'
  },
  arrowLink: {
    '& svg': {
      transform: 'translateX(1px)'
    }
  }
})

export default function Farms() {
  const classes = useStyles()
  const params = useParams()
  const location = useLocation()
  const [addDrawer, setAddDrawer] = useState(false)
  const [filtersDrawer, setFiltersDrawer] = useState(false)
  const {
    farms,
    isLoading,
    max,
    onSearch,
    onPage,
    page,
    mutate,
    onRemoveFarm,
    onFilters,
    onDuplicate,
    filters
  } = useFarms()

  const contextValue = {
    mutate
  }

  return (
    <FarmsContext.Provider value={contextValue}>
      <TablePageContainer
        page={page}
        maxPages={max}
        cols={COLS}
        onSearch={onSearch}
        onPageChange={onPage}
        loading={isLoading}
        onAdd={() => setAddDrawer(true)}
        onFilter={() => setFiltersDrawer(true)}
      >
        {farms.map((row, index) => (
          <TableRow key={`${row['farmId']}-${index}`}>
            <TableCell>{row['farmName']}</TableCell>
            <TableCell>{row['farmId']}</TableCell>
            <TableCell>{row['blockchain']}</TableCell>
            <TableCell>
              {format(toDate(row['dateAdded']), DATE_FORMAT) || 'N/A'}
            </TableCell>
            <TableCell>{row['tvlChange24h']}</TableCell>
            <TableCell align="right">
              <Tooltip title="Duplicate Farm">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => onDuplicate(row)}
                >
                  <FileCopyIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit Assets">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component={Link}
                  to={`/farms/${row['farmId']}/assets`}
                >
                  <ListIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit Farm">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component={Link}
                  to={`/farms/${row['farmId']}`}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete Farm">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => onRemoveFarm(row['farmId'])}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
              <IconButton
                component={Link}
                to={`/farms/${row['farmId']}/grid`}
                className={classes.arrowLink}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TablePageContainer>

      <AddFarmDrawer
        edit={!!params.id}
        open={
          addDrawer || (!!params.id && !location.pathname.includes('assets'))
        }
        onClose={() => setAddDrawer(false)}
        closeTo="/farms"
      />
      <AddAssetDrawer
        editFarm
        open={!!params.id && location.pathname.includes('assets')}
        closeTo="/farms"
      />
      <FarmsFiltersDrawer
        open={filtersDrawer}
        onClose={() => setFiltersDrawer(false)}
        filters={filters}
        onFilters={onFilters}
      />
    </FarmsContext.Provider>
  )
}
