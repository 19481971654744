import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import useAnnouncement from 'hooks/useAnnouncement'
import { useEffect, useState } from 'react'

const useStyles = makeStyles({
  root: {
    padding: '2rem 1rem'
  },
  actions: {
    padding: '1rem 0'
  },
  button: {
    marginRight: '1rem'
  }
})

export default function Settings() {
  const classes = useStyles()
  const { announcement, onUpdateAnnouncement, isUpdateLoading } =
    useAnnouncement()
  const [announcementText, setAnnouncement] = useState('')

  useEffect(() => {
    if (announcement.announcement) {
      setAnnouncement(announcement.announcement)
    }
  }, [announcement])

  return (
    <Grid container direction="column" className={classes.root}>
      <TextField
        label="Announcement"
        variant="outlined"
        rows={10}
        multiline
        InputLabelProps={{ disableAnimation: true, shrink: true }}
        defaultValue={announcementText}
        onChange={(e) => setAnnouncement(e.target.value)}
      />
      <Grid container justifyContent="flex-end" className={classes.actions}>
        <Button color="default" variant="contained" className={classes.button}>
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isUpdateLoading}
          onClick={() => onUpdateAnnouncement(announcementText)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}
