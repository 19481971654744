import { stringifyURL } from 'utils/url'
import { fetcher } from 'services/api/common'
import useSWR from 'swr'
import { toast } from 'react-toastify'
import { editUserRole } from 'services/api/users'

function getKey(params) {
  return params.email ? stringifyURL('/get_premium_user', params) : null
}

export default function useUser(config = {}) {
  const params = {
    email: config.userId
  }

  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onEdit = async (data, onSuccess) => {
    try {
      if (data.role) {
        await editUserRole(data.email, data.role)
      }
      toast.success('User updated')
      config.revalidate?.()
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error!')
      console.error(e)
    }
  }

  const isLoading = params.email ? !_data && !error : false
  const data = _data?.user || {}

  return {
    data,
    isLoading,
    revalidate,
    onEdit
  }
}
