import Grid from '@material-ui/core/Grid'
import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerActions from 'components/drawer/DrawerActions'
import Button from '@material-ui/core/Button'
import DrawerContent from 'components/drawer/DrawerContent'
import {
  BLOCKCHAIN_OPTIONS,
  BOOL_OPTIONS,
  LEADERBOARD_FIELDS,
  LEADERBOARD_FIELDS_LABEL
} from 'utils/forms'
import TextField from '@material-ui/core/TextField'
import Select from 'components/form/Select'
import { useEffect, useState } from 'react'
import useLeaderboard from 'hooks/useLeaderboard'
import { useHistory, useParams } from 'react-router-dom'
import LoadingOverlay from 'components/LoadingOverlay'
import { dataToFormValues } from 'utils/leaderboards'

const initialValues = {
  name: '',
  leaderboard_name: '',
  description: '',
  blockchain: null,
  category: '',
  change24h: '',
  change7d: '',
  hot: null
}

function Content({ onClose, closeTo }) {
  const params = useParams()
  const history = useHistory()
  const [values, setValues] = useState(initialValues)
  const { onAdd, isLeaderboardLoading, leaderboard } = useLeaderboard({
    id: params.id
  })

  const edit = !!params.id

  useEffect(() => {
    if (leaderboard.name) {
      setValues({ ...values, ...dataToFormValues(leaderboard) })
    }
  }, [leaderboard.name])

  const handleChange = (key, value) => {
    setValues((values) => ({
      ...values,
      [key]: value
    }))
  }

  const handleSuccess = () => {
    history.push(closeTo)
    onClose()
  }

  const handleSubmit = () => {
    onAdd(values, handleSuccess)
  }

  if (edit && isLeaderboardLoading) {
    return <LoadingOverlay />
  }

  return (
    <>
      <DrawerHead title="Add Leaderboard" onClose={onClose} closeTo={closeTo} />

      <DrawerContent>
        {LEADERBOARD_FIELDS.map((row) => (
          <Grid container key={row}>
            {row === 'blockchain' ? (
              <Select
                fullWidth
                name={LEADERBOARD_FIELDS_LABEL[row]}
                options={BLOCKCHAIN_OPTIONS}
                value={values[row]}
                onChange={(e, o) => handleChange(row, o)}
              />
            ) : row === 'hot' ? (
              <Select
                fullWidth
                name={LEADERBOARD_FIELDS_LABEL[row]}
                value={values[row]}
                options={BOOL_OPTIONS}
                onChange={(e, o) => handleChange(row, o)}
              />
            ) : (
              <TextField
                fullWidth
                label={LEADERBOARD_FIELDS_LABEL[row]}
                type="text"
                variant="outlined"
                size="small"
                margin="normal"
                value={values[row]}
                onChange={(e) => handleChange(row, e.target.value)}
              />
            )}
          </Grid>
        ))}
      </DrawerContent>

      <DrawerActions>
        <Button variant="contained" color="default" onClick={() => {}}>
          Reset
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </DrawerActions>
    </>
  )
}

export default function AddLeaderboardDrawer({ open, ...props }) {
  return (
    <Drawer open={open}>
      <Content {...props} />
    </Drawer>
  )
}
