import Api from 'services/Api'
import { parseUrl } from 'utils/url'

export async function getFarms(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getFarmDetails(url) {
  const { query } = parseUrl(url)

  const response = await Api.post('/edit', {
    method: 'GET',
    ...query
  })

  return response.data
}

export async function addFarm(fields) {
  const response = await Api.post('/add_farm', {
    fields
  })
  return response.data.data
}

export async function updateFarm(farmId, fields) {
  const response = await Api.put('/edit', {
    farmId,
    fields
  })
  return response.data
}

export async function removeFarm(farmId) {
  const response = await Api.post('/edit', {
    method: 'DELETE',
    farmIds: [farmId]
  })
  return response.data.data
}

export async function duplicateFarm(farm) {
  const response = await Api.post('/add_farm', {
    fields: farm
  })
  return response.data.data
}

export async function updateFarmAssets(farmId, fields) {
  const response = await Api.put('/edit', {
    assetsByFarmId: farmId,
    fields
  })
  return response.data
}
