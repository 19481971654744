import {
  addLeaderboard,
  getLeaderboard,
  removeLeaderboard
} from 'services/api/leaderboards'
import { toast } from 'react-toastify'
import { formatAddData } from 'utils/leaderboards'
import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { useLeaderboardsContext } from 'components/providers/LeaderboardsProvider'

function getKey(params) {
  return stringifyURL('/get_leaderboard', params)
}

export default function useLeaderboard(config = {}) {
  const leaderboardsContext = useLeaderboardsContext()

  const params = {
    name: config.id
  }

  const { data, error, mutate } = useSWR(
    () => (config.id ? getKey(params) : null),
    getLeaderboard
  )

  const onAdd = async (data, onSuccess) => {
    try {
      await addLeaderboard(formatAddData(data))
      toast.success(config.id ? 'Successfully updated' : 'Successfully added')
      leaderboardsContext.mutate()
      config.mutate?.()
      mutate?.()
      onSuccess()
    } catch (e) {
      toast.error(e)
      console.error(e)
    }
  }

  const onRemove = async (name) => {
    try {
      await removeLeaderboard(name)
      toast.success('Successfully removed')
      leaderboardsContext.mutate()
      config.mutate?.()
      mutate?.()
    } catch (e) {
      toast.error(e)
      console.error(e)
    }
  }

  const isLeaderboardLoading = !data && !error
  const leaderboard = data || {}
  return {
    isLeaderboardLoading,
    leaderboard,
    onAdd,
    onRemove
  }
}
