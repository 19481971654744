import { Dialog, IconButton, TextField, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/buttons/Buttons'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'

const useStyles = makeStyles({
  paper: {
    width: '100%',
    borderRadius: 0
  },
  head: {
    width: '100%',
    color: '#fff',
    background: '#3f51b5',
    padding: '10px 20px'
  },
  closeBtn: {
    color: '#fff'
  },
  body: {
    padding: 20
  }
})

export const AddFieldDialog = ({ open, onClose, onApply }) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  return (
    <Dialog open={open} maxWidth="xs" classes={{ paper: classes.paper }}>
      <Grid
        container
        className={classes.head}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Add new field</Typography>

        <span>
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </span>
      </Grid>

      <Grid container className={classes.body}>
        <Grid
          container
          className={classes.inputContainer}
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            placeholder="Field name"
            variant="outlined"
            size="small"
            className={classes.input}
            onChange={(event) => setValue(event.target.value)}
            value={value}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={value.length < 3}
            onClick={() => {
              onApply(value)
              onClose()
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}
