import { isFloat } from 'utils/form'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  input: {
    flex: 1,
    marginRight: '1rem'
  }
})

export default function FloatInput({ name, value, onChange, ...props }) {
  const classes = useStyles()
  return (
    <TextField
      className={classes.input}
      label={name}
      type="text"
      pattern="[+-]?\d+(?:[.,]\d+)?"
      value={value}
      onChange={(e) => {
        if (isFloat(e.target.value)) {
          onChange(name, e.target.value)
        }
      }}
      {...props}
    />
  )
}

FloatInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}
