import { addFarm, updateFarm } from 'services/api/farms'
import { mutate } from 'swr'
import { getKey } from 'hooks/useFarmDetails'
import { useState } from 'react'
import { formatFarmData } from 'utils/api'
import { toast } from 'react-toastify'

export default function useUpdateFarm() {
  const [isLoading, setLoading] = useState(false)

  const onSaveFarm = async (id, fields, onSuccess) => {
    try {
      setLoading(true)

      id
        ? await updateFarm(id, formatFarmData(fields))
        : await addFarm(formatFarmData(fields))

      id && mutate(getKey({ farmId: id }))

      toast.success(
        id ? 'Farm updated successfully' : 'Farm added successfully'
      )

      setLoading(false)
      onSuccess?.()
    } catch (e) {
      setLoading(false)

      e.message && toast.error(e.message)

      console.error(e)
    }
  }

  return {
    onSaveFarm,
    isLoading
  }
}
