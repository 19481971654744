import MuiButton from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

export default function Button(props) {
  const Component = <MuiButton variant="contained" color="primary" {...props} />

  if (props.tooltip) {
    return <Tooltip title={props.tooltip}>{Component}</Tooltip>
  }
  return Component
}
