import { convertTitleToId } from 'utils/blog'
import { addBlog, getSingleBlog, removeBlog } from 'services/api/blog'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useSWR from 'swr'

export default function useSingleBlog(config = {}) {
  const history = useHistory()

  const { data, error, mutate } = useSWR(
    config.id ? `/get_blog?blog_id=${config.id}` : null,
    getSingleBlog
  )

  const onAdd = async (values) => {
    try {
      const data = {
        ...values,
        date_added: values.date_added || new Date(),
        blog_id: values.blog_id || convertTitleToId(values.title)
      }
      await addBlog(data)

      toast.success(
        values.blog_id ? 'Blog edited successfully' : 'Blog added successfully'
      )

      config.mutate?.()
      config.id && mutate()
      history.push('/landing/blog')
    } catch (e) {
      toast.error('Error adding blog :(')
      console.error(e)
    }
  }

  const onRemove = async (id) => {
    try {
      await removeBlog(id)

      toast.success('Blog removed successfully')

      config.mutate?.()
    } catch (e) {
      toast.error('Error removing blog :(')
      console.error(e)
    }
  }

  const singleBlog = data || {}
  const isSingleBlogLoading = !data && !error
  return {
    onAdd,
    onRemove,
    singleBlog,
    isSingleBlogLoading
  }
}
