import { createContext } from 'react'

export const AssetsContext = createContext({
  mutate: () => {}
})

export const FarmsContext = createContext({
  mutate: () => {}
})

export const FarmAssetsContext = createContext({
  mutate: () => {},
  openEditDialog: () => {},
  onDuplicate: () => {}
})

export const BridgesContent = createContext({
  mutate: () => {}
})
