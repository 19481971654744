import useExchanges from 'hooks/useExchanges'
import TablePageContainer from 'components/TablePageContainer'
import TableRow from '@material-ui/core/TableRow'
import TableCell from 'components/table/TableCell'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

export default function Exchanges() {
  const exchanges = useExchanges()

  const COLS = [
    { name: 'asset', label: 'Asset', render: (row) => row.asset || '-' },
    {
      name: 'exchange',
      label: 'Exchange',
      render: (row) => row.exchangeName || '-'
    },
    {
      name: 'active',
      label: 'Active',
      render: (row) => (
        <div>
          <span>
            {typeof row.active === 'boolean'
              ? row.active
                ? 'Yes'
                : 'No'
              : '-'}
          </span>
          <IconButton onClick={() => exchanges.onActive(row.id, !row.active)}>
            {row.active ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </div>
      )
    },
    {
      name: 'blockchain',
      label: 'Blockchain',
      render: (row) => row.blockchain || '-'
    },
    {
      name: 'Liquidity',
      label: 'Liquidity',
      render: (row) => row.liquidityUSD?.toFixed(2) || '-'
    }
  ]

  return (
    <TablePageContainer
      cols={COLS}
      maxPages={exchanges.maxPages}
      page={exchanges.filters.page}
      onPageChange={exchanges.onPage}
    >
      {exchanges.exchanges.map((row) => (
        <TableRow key={row.id}>
          {COLS.map((col) => (
            <TableCell key={col.name}>{col.render(row)}</TableCell>
          ))}
        </TableRow>
      ))}
    </TablePageContainer>
  )
}
