import { toast } from 'react-toastify'
import {
  addBridgeCombination,
  deleteBridgeCombination
} from 'services/api/bridges'
import { formatBridgeValues } from 'utils/api'
import { useContext } from 'react'
import { BridgesContent } from 'utils/contexts'
import { useParams } from 'react-router-dom'

export default function useBridge(config = {}) {
  const params = useParams()
  const { mutate: mutateBridges } = useContext(BridgesContent)

  const onAdd = async (values, onSuccess) => {
    try {
      await addBridgeCombination(formatBridgeValues(values))
      toast.success(
        params.name
          ? 'Bridge combination updated successfully'
          : 'Bridge combination added successfully'
      )
      mutateBridges?.()
      onSuccess?.()
    } catch (e) {
      e.message && toast.error(e.message)
      console.error(e)
    }
  }

  const onDelete = async (name) => {
    try {
      await deleteBridgeCombination(name)
      toast.success('Bridge combination removed successfully')
      mutateBridges?.()
      config.mutate?.()
    } catch (e) {
      toast.error('Oops error!')
      console.error(e)
    }
  }

  return {
    onAdd,
    onDelete
  }
}
