import Drawer from 'components/drawer/Drawer'
import DrawerActions from 'components/drawer/DrawerActions'
import Button from '@material-ui/core/Button'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerContent from 'components/drawer/DrawerContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useForm, Controller } from 'react-hook-form'
import usePdf from 'hooks/usePdf'
import Dropzone from 'pages/landing/addBlog/Dropzone'

const useStyles = makeStyles({
  control: {
    margin: '1rem 0'
  },
  btn: {
    width: 'fit-content'
  }
})

function Content({ onClose, closeTo }) {
  const classes = useStyles()
  const { onAdd } = usePdf()

  const methods = useForm({
    defaultValues: {
      title: '',
      upload: null,
      active: true,
      date: null
    }
  })

  const handleSubmit = (values) => {
    onAdd(values, onClose)
  }

  return (
    <>
      <DrawerHead title="Add PDF" onClose={onClose} closeTo={closeTo} />

      <DrawerContent>
        <Controller
          control={methods.control}
          render={({ field: { value, name } }) => (
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              margin="normal"
              label="Title"
              value={value}
              onChange={(e) => methods.setValue(name, e.target.value)}
            />
          )}
          name="title"
        />

        <Grid container direction="column" className={classes.control}>
          <Controller
            control={methods.control}
            render={({ field: { value, name } }) => (
              <Dropzone
                value={value}
                onChange={(url, fileName) =>
                  methods.setValue(name, { url, name: fileName })
                }
              />
            )}
            name="upload"
          />
        </Grid>

        <Grid container className={classes.control}>
          <Controller
            control={methods.control}
            render={({ field: { value, name } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={() => methods.setValue(name, !value)}
                    name="checkedA"
                    color="primary"
                  />
                }
                label="Active"
              />
            )}
            name="active"
          />
        </Grid>
      </DrawerContent>

      <DrawerActions>
        <Button variant="contained" color="default" onClick={() => {}}>
          Reset
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => methods.handleSubmit(handleSubmit)()}
        >
          Apply
        </Button>
      </DrawerActions>
    </>
  )
}

export default function AddPdfDrawer({ open, ...props }) {
  return (
    <Drawer open={open}>
      <Content {...props} />
    </Drawer>
  )
}
