export function formatValues(values) {
  const format = { ...values }

  if (!format['date']) {
    format['date'] = new Date()
  }

  if (!format['upload']) {
    delete format['upload']
  } else {
    format['upload'] = format['upload'].url
  }

  return format
}
