import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { useState } from 'react'
import { fetcher } from 'services/api/common'

function getKey(params) {
  return stringifyURL('/get_emails_messages', params)
}

export default function useWaitlist() {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20
  })

  const params = {
    ...filters
  }

  const { data, error } = useSWR(() => getKey(params), fetcher)

  const onPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !data && !error
  const emails = data?.emails || []
  const maxPages = data?.max_pages || 0

  return {
    isLoading,
    emails,
    filters,
    maxPages,
    onPage
  }
}
