import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import makeStyles from '@material-ui/core/styles/makeStyles'

// Components
import Drawer from 'components/drawer/Drawer'

// Utils
import { buildNavigationItems } from 'common/utils/app.utils'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    padding: '1rem',
    position: 'relative',

    '&::before': {
      position: 'absolute',
      content: '""',
      opacity: 0,
      display: 'block',
      height: '100%',
      width: 3,
      left: 0,
      backgroundColor: theme.palette.secondary.main
    },

    '&:hover': {
      fontWeight: 500,

      '&::before': {
        opacity: 1
      }
    },

    '&[data-active="true"]': {
      fontWeight: 500,

      '&::before': {
        opacity: 1
      }
    }
  }
}))

export default function NavDrawer({ open, onClose }) {
  const navigationItems = useMemo(() => buildNavigationItems(), [])
  return (
    <Drawer size={300} open={open} onClose={onClose}>
      <List>
        {navigationItems.map((row, index) => (
          <NavLink {...row} key={index} onClick={onClose} />
        ))}
      </List>
    </Drawer>
  )
}

function NavLink({ href, title, deps, onClick }) {
  const classes = useStyles()
  const location = useLocation()

  const active = deps.some((d) => location.pathname.includes(d))

  return (
    <ListItem
      button
      onClick={onClick}
      className={classes.root}
      data-active={active}
      component={Link}
      to={href}
    >
      {title}
    </ListItem>
  )
}
