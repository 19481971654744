export function createOption(label, value) {
  return {
    label,
    value
  }
}

export function isFloat(value) {
  return /^[+-]?\d*(?:[.,]\d*)?$/.test(value)
}
