import useSWR from 'swr'

// API Service
import { fetcher } from '../services/api/common'

export const useVoteTokens = () => {
  const route = 'get_vote_tokens'
  const { data, error, mutate } = useSWR(route, fetcher)

  const isLoading = !data && !error
  // TODO: Remove placeholder data
  const voteTokens = data?.data || []

  return {
    isLoading,
    voteTokens,
    mutate
  }
}
