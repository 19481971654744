// API Service
import Api from 'services/Api'

/**
 * Edits the given platform.
 * @param {string} platform - Platform's name.
 * @param {Record<string, any>} data - Update data.
 * @returns Response.
 */
export const editBribePlatform = async (platform, data) => {
  try {
    const response = await Api.post(
      `/edit_bribe_platform?platform=${platform}`,
      data
    )
    return response.data
  } catch (error) {
    return { error }
  }
}
