import TablePageContainer from 'components/TablePageContainer'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { AddBridgeDrawer } from 'components/AddBridgeDrawer'
import { useState } from 'react'
import useAllBridgesCombinations from 'hooks/useAllBridgesCombinations'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { Link, useParams } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BridgesContent } from 'utils/contexts'
import useBridge from 'hooks/useBridge'

const COLS = [
  { name: 'name', label: 'Name' },
  { name: 'comment', label: 'Comment' },
  { name: 'fee', label: 'Fee' },
  { name: 'blockchains', label: 'Blockchains' },
  { name: 'url', label: 'URL' },
  { name: 'rank', label: 'Rank' },
  { name: 'actions', label: '' }
]

const useStyles = makeStyles({
  button: {
    marginRight: '1rem'
  },
  cell: {
    wordBreak: 'break-word'
  }
})

export default function Bridges() {
  const [addDrawer, setAddDrawer] = useState(false)
  const classes = useStyles()
  const params = useParams()

  const isEditDrawer = !!params.name

  const { combinations, mutate } = useAllBridgesCombinations()

  const { onDelete } = useBridge({ mutate })

  const contextValue = {
    mutate
  }

  return (
    <BridgesContent.Provider value={contextValue}>
      <TablePageContainer cols={COLS} onAdd={() => setAddDrawer(true)}>
        {combinations.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row['bridgeName']}</TableCell>
            <TableCell className={classes.cell}>{row['comment']}</TableCell>
            <TableCell>{row['fee']}</TableCell>
            <TableCell>
              {row['blockchains'].filter(Boolean).join(', ')}
            </TableCell>
            <TableCell>{row['url']}</TableCell>
            <TableCell>{row['rank'] || '-'}</TableCell>
            <TableCell align="right">
              <Tooltip title="Edit Bridge">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component={Link}
                  to={`/bridges/${encodeURIComponent(row['bridgeName'])}`}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete Asset">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onDelete(row['bridgeName'])}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TablePageContainer>

      <AddBridgeDrawer
        open={addDrawer || isEditDrawer}
        onClose={() => setAddDrawer(false)}
        closeTo="/bridges"
      />
    </BridgesContent.Provider>
  )
}
