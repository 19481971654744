import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'
import LoadingOverlay from 'components/LoadingOverlay'
import debounce from 'lodash.debounce'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import TuneIcon from '@material-ui/icons/Tune'
import Tooltip from '@material-ui/core/Tooltip'
import TableHeadCell from 'components/table/TableHeadCell'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    maxHeight: '100%',
    position: 'relative'
  },
  tableContainer: {
    flex: 1
  },
  tablePagination: {
    boxShadow: theme.shadows[5],
    padding: '1rem'
  },
  searchInput: {
    width: 200
  },
  input: {
    width: 60
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  button: {
    height: 40,
    marginLeft: '1rem'
  }
}))

export default function TablePageContainer({
  children,
  maxPages,
  page,
  cols,
  onSearch,
  loading,
  onPageChange,
  onAdd,
  onFilter
}) {
  const classes = useStyles()

  const handleInputChange = debounce((e) => {
    const value = e.target.value
    if (/^\d+$/.test(value)) {
      onPageChange(+value)
    }
  }, 400)

  return (
    <Grid container direction="column" className={classes.root}>
      {loading && <LoadingOverlay />}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {cols.map((col, index) => (
                <TableHeadCell key={index} {...col}>
                  {col.name === 'actions' ? (
                    <Grid className={classes.searchContainer}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Search"
                        onChange={(e) => onSearch(e.target.value)}
                        className={classes.searchInput}
                      />
                      <Tooltip title="Add New">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={onAdd}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Filters">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={onFilter}
                        >
                          <TuneIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  ) : (
                    col.label
                  )}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.tablePagination}
      >
        <Pagination
          count={maxPages}
          page={page}
          color="primary"
          onChange={(e, page) => {
            onPageChange(page)
          }}
        />
        <TextField
          variant="outlined"
          size="small"
          className={classes.input}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  )
}

TablePageContainer.propTypes = {
  children: PropTypes.node,
  maxPages: PropTypes.number,
  page: PropTypes.number,
  cols: PropTypes.array,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onAdd: PropTypes.func,
  onFilter: PropTypes.func
}
