import { useState } from 'react'
import useSWR from 'swr'

// API Service
import { buildRoute, fetcher } from '../services/api/common'

export const useBribePlatforms = () => {
  const [filters, onFilters] = useState({
    page: 1,
    per_page: 25
  })

  const { data, error, mutate } = useSWR(
    () => buildRoute('/get_bribe_platforms', filters),
    fetcher
  )

  const onPage = (page) => {
    onFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !data && !error
  const platforms = data?.data || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    platforms,
    filters,
    onPage,
    maxPages,
    mutate
  }
}
