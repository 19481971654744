import { useState, useMemo } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

// Components
import TablePageContainer from 'components/TablePageContainer'
import Button from 'components/buttons/Buttons'
import { EditBribeGovernanceDrawer } from 'components/EditBribeGovernanceDrawer'

// Hooks - API
import { useVoteTokens } from 'hooks/useVoteTokens'

export const BribeGovernance = () => {
  const [voteToken, setVoteToken] = useState(undefined)
  const { voteTokens } = useVoteTokens()

  /**
   * Memorising table columns.
   */
  const COLS = useMemo(
    () => [
      {
        name: 'vote_token',
        label: 'Vote token',
        render: (voteToken) => voteToken
      },
      {
        name: 'edit_governance',
        label: 'Edit Governance',
        align: 'center',
        render: (row) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setVoteToken(row)
              }}
            >
              <EditIcon />
            </Button>
          )
        }
      }
    ],
    [setVoteToken]
  )

  return (
    <>
      <TablePageContainer cols={COLS} maxPages={1} page={1}>
        {voteTokens.map((voteToken, index) => (
          <TableRow key={`${voteToken.id}-${index}`}>
            {COLS.map((col) => (
              <TableCell key={col.name} align={col.align}>
                {col.render(voteToken)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TablePageContainer>
      <EditBribeGovernanceDrawer
        open={!!voteToken}
        onClose={() => setVoteToken(undefined)}
        governance={{ vote_token: voteToken }}
        onSuccess={(data) => {
          // TODO: Finish
          console.log(data)
        }}
      />
    </>
  )
}
