import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    boxShadow: theme.shadows[5],

    '& button:first-child': {
      marginRight: '1rem'
    }
  },
  button: {
    marginRight: '1rem'
  }
}))

export default function DrawerActions({
  cancelTo,
  onClose,
  onSave,
  saveDisabled,
  children
}) {
  const classes = useStyles()
  return (
    <Grid container justifyContent="flex-end" className={classes.root}>
      {children || (
        <>
          <Button
            component={Link}
            to={cancelTo}
            variant="contained"
            color="default"
            className={classes.button}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </>
      )}
    </Grid>
  )
}

DrawerActions.propTypes = {
  cancelTo: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  saveDisabled: PropTypes.bool,
  children: PropTypes.node
}
