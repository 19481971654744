import Grid from '@material-ui/core/Grid'
import Select from 'components/form/Select'
import { CONTENT_TYPES } from 'utils/blog'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Dropzone from 'pages/landing/addBlog/Dropzone'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'

const InputLabelProps = {
  disableAnimation: true,
  shrink: true
}

const useStyles = makeStyles({
  contentBlock: {
    padding: '0.5rem 1rem 1rem 1rem',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: 15,
    marginBottom: '2rem'
  },
  select: {
    width: 300
  },
  listItemAdd: {
    margin: '1rem 0 0 0'
  },
  emptyList: {
    margin: '1rem'
  },
  listContainer: {
    padding: '1rem',
    border: '1px solid  rgba(0, 0, 0, 0.25)',
    borderRadius: 15,
    marginTop: '1rem'
  },
  liRemove: {
    height: 40,
    margin: '0.5rem 0 0 1rem'
  }
})

export default function useLandingContentUi({ setValue, remove }) {
  const params = useParams()
  const classes = useStyles()

  const editId = params.id !== 'new' ? params.id : ''

  const onTypeChange = useCallback((row, index, value) => {
    const current = row.type

    setValue(`content.${index}.type`, value || '')

    // if current type and new type both related to text, do not change content
    if (
      ['h2', 'h3', 'text'].includes(value) &&
      ['image', 'list'].includes(current)
    ) {
      setValue(`content.${index}.content`, '')
    }
    if (value === 'list') {
      setValue(`content.${index}.content`, [])
    }
    if (value === 'image') {
      setValue(`content.${index}.content`, {})
    }
  }, [])

  const renderContentRow = useCallback(
    (row, index) => (
      <Grid
        container
        direction="column"
        className={classes.contentBlock}
        key={row.id}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Select
            filterOptions={(options) => options}
            name="Content type"
            className={classes.select}
            options={CONTENT_TYPES}
            InputLabelProps={InputLabelProps}
            value={CONTENT_TYPES.find((o) => o.value === row.type) || ''}
            onChange={(e, o) => onTypeChange(row, index, o?.value)}
          />

          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => remove(index)}
          >
            <DeleteIcon />
          </Button>
        </Grid>

        {['text', 'h2', 'h3'].includes(row.type) && (
          <TextField
            multiline
            margin="normal"
            label="Content"
            variant="outlined"
            rows={10}
            InputLabelProps={InputLabelProps}
            placeholder="Content"
            value={row.content}
            onChange={(e) =>
              setValue(`content.${index}.content`, e.target.value)
            }
          />
        )}

        {row.type === 'list' && (
          <Grid container direction="column" className={classes.listContainer}>
            {row.content.length ? (
              <>
                {(typeof row.content === 'string' ? [] : row.content).map(
                  (li, ind) => (
                    <Grid container alignItems="center" key={li.id}>
                      <Grid container item xs={6}>
                        <TextField
                          fullWidth
                          label="List item"
                          placeholder=""
                          variant="outlined"
                          margin="normal"
                          size="small"
                          InputLabelProps={InputLabelProps}
                          value={li.content || ''}
                          onChange={(e) =>
                            setValue(
                              `content.${index}.content.${ind}.content`,
                              e.target.value
                            )
                          }
                        />
                      </Grid>

                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.liRemove}
                        onClick={() =>
                          setValue(
                            `content.${index}.content`,
                            row.content.filter((c) => c.id !== li.id)
                          )
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  )
                )}
              </>
            ) : (
              <Typography className={classes.emptyList}>
                No list items yet...
              </Typography>
            )}

            <div className={classes.listItemAdd}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  setValue(
                    `content.${index}.content`,
                    row.content.length
                      ? [...row.content, { content: '', id: Date.now() }]
                      : [{ content: '', id: Date.now() }]
                  )
                }
              >
                Add List Item
              </Button>
            </div>
          </Grid>
        )}

        {row.type === 'image' && (
          <Dropzone
            onChange={(url, name) => {
              setValue(`content.${index}.content.file`, url)
              setValue(`content.${index}.content.name`, name)
            }}
            value={row.content}
          />
        )}
      </Grid>
    ),
    []
  )

  return {
    editId,
    onTypeChange,
    renderContentRow
  }
}
