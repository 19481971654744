import { v4 as uuid } from 'uuid'

const ASSET_ARRAY_FIELDS = ['farmId', 'blockchain', 'dataQuality']

export function formatAssetData(data) {
  const formData = {}

  Object.keys(data).forEach((key) => {
    if (ASSET_ARRAY_FIELDS.includes(key)) {
      const value =
        typeof data[key].value === 'object'
          ? data[key].value.value
          : data[key].value
      if (key === 'farmId') {
        formData['farmId'] = {
          ...data[key],
          ...(data[key].value && {
            value
          })
        }
        formData['farm'] = {
          ...data[key],
          ...(data[key].value && {
            value
          })
        }
      } else {
        formData[key] = {
          ...data[key],
          ...(data[key].value && {
            value
          })
        }
      }
    } else {
      formData[key] = data[key]
    }
  })

  return formData
}

const FARM_ARRAY_FIELDS = ['blockchain', 'farmType']

export function formatFarmData(data) {
  const formData = {}

  Object.keys(data).forEach((key) => {
    if (FARM_ARRAY_FIELDS.includes(key)) {
      formData[key] = {
        ...data[key],
        ...(data[key].value && {
          value: data[key].value.value
        })
      }
    } else {
      formData[key] = data[key]
    }
  })

  return formData
}

export function removeDuplicates(data, field) {
  const seen = new Set()
  return data.filter((row) => {
    const duplicate = seen.has(row[field])
    seen.add(row[field])
    return !duplicate
  })
}

export function formatBridgeValues(data) {
  const formData = {}

  Object.keys(data).forEach((key) => {
    formData[key] =
      key === 'blockchains'
        ? data[key].value.map((o) => o.value)
        : key === 'rank'
        ? data[key].value?.value
        : data[key].value
  })

  return formData
}

export function formatDuplicateAsset(asset) {
  return {
    ...asset,
    assetId: `${asset.assetId}_${uuid()}`,
    asset: `${asset.asset}_Copy`,
    source: null
  }
}

export function formatDuplicateFarm(farm) {
  return {
    ...farm,
    farmId: `${farm.farmId}_${uuid()}`,
    farmName: `${farm.farmName}_Copy`,
    source: null
  }
}
