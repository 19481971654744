import Api from 'services/Api'
import { parseUrl } from 'utils/url'

export async function getLeaderboards(url) {
  const response = await Api.get(url)
  return response.data
}

export async function addLeaderboard(data) {
  const response = await Api.post('/add_leaderboard', data)
  return response.data
}

export async function removeLeaderboard(name) {
  const response = await Api.post('/remove_leaderboard', {
    name
  })
  return response.data
}

export async function getLeaderboard(url) {
  const parsed = parseUrl(url)
  const response = await Api.post(parsed.url, parsed.query)
  return response.data
}

// export async function upVoteLeaderboard(name) {
//   const response = await Api.post('/upvote_leaderboard', {
//     name
//   })
//   return response.data
// }
//
// export async function downVoteLeaderboard(name) {
//   const response = await Api.post('/upvote_leaderboard', {
//     name
//   })
//   return response.data
// }
