import { DataGrid } from '@material-ui/data-grid'
import {
  ASSET_GRID_FIELDS,
  BLOCKCHAIN_OPTIONS,
  BOOL_FIELDS,
  BOOL_OPTIONS,
  DATA_QUALITY_OPTIONS,
  FLOAT_FIELDS
} from 'utils/forms'
import useAssets from 'hooks/useAssets'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { TextField, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useFarmDetails from 'hooks/useFarmDetails'
import Pagination from '@material-ui/lab/Pagination'
import AddAssetDrawer from 'components/AddAssetDrawer'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'
import {
  BoolCell,
  DataQualityCell,
  EditButtonCell,
  FloatEditCell,
  HeaderCell
} from 'pages/farmAssets/components'
import useUpdateAsset from 'hooks/useUpdateAsset'
import { FarmAssetsContext } from 'utils/contexts'
import EditColDialog from 'pages/farmAssets/EditColDialog'
import Tooltip from '@material-ui/core/Tooltip'
import TuneIcon from '@material-ui/icons/Tune'
import AssetsFiltersDrawer from 'components/AssetsFiltersDrawer'
import { SCRAPE_SOURCE_STATUSES } from 'utils/filters'

const COLUMNS = ASSET_GRID_FIELDS.map((key) => ({
  field: key,
  headerName: key,
  width: 200,
  editable: true,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  renderHeader: HeaderCell,
  ...(key === 'assetId' && {
    width: 300
  }),
  ...(key === 'blockchain' && {
    type: 'singleSelect',
    valueOptions: BLOCKCHAIN_OPTIONS
  }),
  ...(key === 'source' && {
    type: 'singleSelect',
    valueOptions: SCRAPE_SOURCE_STATUSES
  }),
  ...(key === 'dataQuality' && {
    type: 'singleSelect',
    valueOptions: DATA_QUALITY_OPTIONS,
    renderCell: DataQualityCell
  }),
  ...(FLOAT_FIELDS.includes(key) && {
    renderEditCell: FloatEditCell
  }),
  ...(BOOL_FIELDS.includes(key) && {
    type: 'singleSelect',
    valueOptions: BOOL_OPTIONS,
    renderCell: BoolCell
  }),
  ...(key === 'EDIT_BUTTON' && {
    width: 75,
    headerName: '',
    editable: false,
    renderCell: EditButtonCell,
    // eslint-disable-next-line react/display-name
    renderHeader: () => <div />
  })
}))

const useStyles = makeStyles({
  root: {
    flex: 1
  },
  inputContainer: {
    padding: '1rem'
  },
  hintContainer: {
    padding: '0 1rem 1rem 1rem'
  },
  input: {
    margin: '0 1rem'
  },
  button: {
    height: 40,
    marginRight: '1rem',

    '&:last-child': {
      marginRight: 0
    }
  },
  paginationContainer: {
    flex: 1
  },
  colHeader: {
    overflow: 'hidden',
    fontWeight: 700
  }
})

export default function FarmAssets() {
  const classes = useStyles()
  const params = useParams()
  const [addDrawer, setAddDrawer] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [editField, setEditField] = useState(null)
  const [filtersDrawer, setFiltersDrawer] = useState(false)

  const { farm } = useFarmDetails(params.id)
  const {
    assets,
    onSearch,
    max,
    page,
    onPage,
    mutate,
    onDuplicate,
    filters,
    onFilters
  } = useAssets({
    farmIds: params.id
  })

  const handleSuccess = () => {
    mutate()
  }

  const filteredAssets = assets.filter((asset) => !!asset.assetId)

  const { onSaveAsset } = useUpdateAsset()

  const handleChange = (e, event) => {
    const field = e?.field
    let value =
      event?.target?.dataset?.type === 'float'
        ? Number(event?.target?.value)
        : e?.props?.value || event?.target?.value

    if (field && (value || typeof value === 'boolean')) {
      const fields = {
        [field]: {
          value
        }
      }
      onSaveAsset(e.id, fields, handleSuccess)
    }
  }

  const openEditDialog = (field) => {
    setEditDialog(true)
    setEditField(field)
  }

  const contextValue = {
    mutate,
    onDuplicate,
    openEditDialog
  }

  return (
    <FarmAssetsContext.Provider value={contextValue}>
      <Grid container direction="column" className={classes.root}>
        <Grid
          container
          className={classes.inputContainer}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">
            Assets of Farm: {farm['farmName']?.value || '-'}
          </Typography>

          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            className={classes.paginationContainer}
          >
            <Pagination
              count={max}
              page={page}
              onChange={(e, page) => onPage(page)}
              color="primary"
            />

            <TextField
              size="small"
              variant="outlined"
              label="Search"
              onChange={(e) => onSearch(e.target.value)}
              className={classes.input}
            />

            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => setAddDrawer(true)}
            >
              <AddIcon />
            </Button>

            <Tooltip title="Filters">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => setFiltersDrawer(true)}
              >
                <TuneIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography className={classes.hintContainer}>
          Please press Enter to save your edits
        </Typography>

        <DataGrid
          columns={COLUMNS}
          rows={filteredAssets}
          getRowId={(row) => row.assetId}
          onEditCellChangeCommitted={handleChange}
          hideFooterPagination
          hideFooter
          classes={{ columnHeader: classes.colHeader }}
        />

        <AddAssetDrawer
          edit={!!params.assetId}
          open={addDrawer || !!params.assetId}
          closeTo={`/farms/${params.id}/grid`}
          onClose={() => setAddDrawer(false)}
        />

        <EditColDialog
          open={editDialog}
          onClose={() => setEditDialog(false)}
          field={editField}
          mutate={mutate}
        />

        <AssetsFiltersDrawer
          open={filtersDrawer}
          onClose={() => setFiltersDrawer(false)}
          filters={filters}
          onFilters={onFilters}
        />
      </Grid>
    </FarmAssetsContext.Provider>
  )
}
