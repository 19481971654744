import TextField from '@material-ui/core/TextField'
import { forwardRef } from 'react'

const Input = forwardRef((props, ref) => {
  return (
    <TextField
      ref={ref}
      type="text"
      variant="outlined"
      size="small"
      margin="normal"
      {...props}
    />
  )
})

export default Input
