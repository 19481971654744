import useSWR from 'swr'
import { getGroups, updateGroup } from 'services/api/groups'
import { toast } from 'react-toastify'

export default function useGroups() {
  const { data, error, mutate } = useSWR(
    `/get_coins_and_coin_groups`,
    getGroups
  )

  const onUpdate = async (groups) => {
    try {
      await updateGroup(groups)
      mutate()
      toast.success('Groups successfully updated')
    } catch (e) {
      console.error(e)
      console.dir(e)
      toast.error(`Selected groups are already a part of other groups`)
    }
  }

  const isLoading = !data && !error
  const groups = data?.coin_groups?.groups || {}
  const coins = data?.coins || {}
  return {
    isLoading,
    groups,
    coins,
    onUpdate
  }
}
