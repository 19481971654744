import { createOption } from 'utils/form'

export const TOOLTIP_LOCK =
  'If you lock this field, scraping engine can not overwrite the field and it will be unchangeable value'

export const LEADERBOARD_FIELDS_LABEL = {
  name: 'Twitter ID',
  leaderboard_name: 'Leaderboard Name',
  description: 'Short description',
  blockchain: 'Blockchain',
  category: 'Category',
  change24h: 'Change 24h',
  change7d: 'Change 7d',
  hot: 'Hot'
}

export const LEADERBOARD_FIELDS = [
  'name',
  'leaderboard_name',
  'description',
  'blockchain',
  'category',
  'change24h',
  'change7d',
  'hot'
]

export const FARM_FIELDS = [
  'farmName',
  'farmType',
  'tvlStaked',
  'tvlChange24h',
  'tvlChange24hValue',
  'blockchain',
  'adSpot',
  'active',
  'dateAdded',
  'dateUpdated',
  'scam',
  'scamInfo',
  'source',
  'farmIconUrl'
]

export const ASSET_FIELDS = [
  'strategyDescription',
  'dataQuality',
  'active',
  'farmId',
  'url',
  'blockchain',
  'exchangeName',
  'exchangeUrl',
  'asset',
  'tokenA',
  'tokenB',
  'tvlStaked',
  'tvlExchange',
  'aprYearly',
  // 'aprWeekly',
  // 'aprDaily',
  // 'apyYearly',
  'rewardTokenA',
  'rewardTokenB',
  'dateAdded',
  'dateUpdated',
  'source',
  'investmentLink',
  'stakingLink',
  'depositFee',
  'withdrawalFee',
  'harvestLockup',
  'harvestLockupInfo',
  'transferTax',
  'transferTaxInfo',
  'antiWhale',
  'otherPoolEconomicsInfos',
  'yieldType',
  'assetAddress',
  'tokenAAddress',
  'tokenBAddress',
  'auditInfo',
  'assetId',
  'pid',
  'farmImage',
  'exchangeVersion',
  'exchangePicture',
  'assetPrice',
  'assetLockup',
  'multiplier',
  'underlyingFarm',
  'weight',
  'category',
  'rewardTokenAWeeklyAmount',
  'rewardTokenAWeeklyDollarAmount',
  'rewardTokenAPrice',
  'rewardTokenAAddress',
  'rewardTokenBPrice',
  'rewardTokenBWeeklyAmount',
  'rewardTokenBWeeklyDollarAmount',
  'rewardTokenBAddress',
  'yearlyTokenRewardPool',
  'manuallyCalculatedAPR',
  'otherFees',
  'info',
  'impermanentLoss',
  'impermanentLoss30d',
  'maxPoolCap',
  'poolAlreadyFilled',
  'collateralLockPeriod',
  'volume_24h',
  'priceCorrelation_30d',
  'dateStarted',
  'dateEnding',
  'daysRemaining',
  'stakingAddress',
  'vaultAddress',
  'tokenC',
  'tokenD',
  'tokenAPrice',
  'tokenBPrice',
  'tokenCPrice',
  'tokenDPrice',
  'tokenCAddress',
  'tokenDAddress',
  'tokenAPicture',
  'tokenBPicture',
  'tokenCPicture',
  'tokenDPicture',
  'nativeToken',
  'nativeTokenPrice',
  'nativeTokenAddress',
  'nativeTokenInvestLink',
  'nativeTokenMarketCap',
  'scam',
  'scamInfo',
  'assetPopupMessage',
  'tvlChange24h',
  'tvlChange24hValue',
  'imageUrl'
]

export const ASSET_MOST_USED_FIELDS = ['tvlStaked', 'aprYearly', 'active']

export const ASSET_GRID_FIELDS = [
  'EDIT_BUTTON',
  'dataQuality',
  'active',
  'farmId',
  'url',
  'blockchain',
  'exchangeName',
  'exchangeUrl',
  'asset',
  'tokenA',
  'tokenB',
  'tvlStaked',
  'tvlExchange',
  'aprYearly',
  // 'aprWeekly',
  // 'aprDaily',
  // 'apyYearly',
  'rewardTokenA',
  'rewardTokenB',
  // 'dateAdded',
  'dateUpdated',
  'source',
  'investmentLink',
  'stakingLink',
  'depositFee',
  'withdrawalFee',
  'harvestLockup',
  'harvestLockupInfo',
  'transferTax',
  'transferTaxInfo',
  'antiWhale',
  'otherPoolEconomicsInfos',
  'yieldType',
  'assetAddress',
  'tokenAAddress',
  'tokenBAddress',
  'auditInfo'
  // 'assetId'
]

export const BRIDGE_COMBINATIONS_FIELDS = [
  'bridgeName',
  'comment',
  'fee',
  'blockchains',
  'url',
  'rank'
]

export const BRIBE_PLATFORM_FIELDS = [
  // Overview
  { label: 'Blockchain', key: 'blockchain' },
  { label: 'TVL', key: 'tvl_staked' },
  { label: 'Yield Type', key: 'yield_type' },
  { label: 'Audit Link', key: 'audit_link' },
  { label: 'Vault APY', key: 'vault_apy' },
  { label: 'Deposit Fee', key: 'deposit_fee' },
  { label: 'Withdrawal Fee', key: 'withdrawal_fee' },
  { label: 'Performance Fee', key: 'performance_fee' },
  // Details
  { label: 'About', key: 'about' },
  { label: 'Strategy', key: 'strategy' },
  { label: 'Risk Descritpion', key: 'risk_description' },
  { label: 'How To Use', key: 'how_to_use' },
  // Links
  { label: 'About Website', key: 'about_website' },
  { label: 'Strategy Website', key: 'strategy_website' },
  { label: 'Strategy Address', key: 'strategy_address' },
  { label: 'Cover Vault Website', key: 'cover_vault_website' },
  { label: 'Insure Vault Website', key: 'insure_vault_website' },
  { label: 'App Url', key: 'app_url' }
]

export const BRIBE_GAUGE_FIELDS = [
  { label: 'Name', key: 'gauge_name', disabled: true },
  { label: 'Protocol', key: 'protocol', disabled: true },
  { label: 'Platform', key: 'platform', disabled: true },
  { label: 'Value per vote', key: 'value_per_vote', disabled: true },
  { label: 'Vote token symbol', key: 'vote_token_symbol', disabled: true },
  { label: 'APR', key: 'average_apr', disabled: true },
  { label: 'Invest link', key: 'invest_link' },
  { label: 'More info link', key: 'more_info_link' }
]

export const BRIBE_GOVERNANCE_FIELDS = [
  { label: 'Vote token', key: 'vote_token', disabled: true },
  { label: 'About', key: 'about' },
  { label: 'Risk Descritpion', key: 'risk_description' },
  { label: 'Investment thesis', key: 'investment_thesis' }
]

export const BRIBE_PLATFORM_IGNORED_FIELDS = ['platform']

export const BRIBE_PLATFORM_ASSET_TOKEN_FIELDS = [
  { label: 'Asset', key: 'asset', required: true },
  { label: 'Description', key: 'description', required: true },
  { label: 'Website Url', key: 'website_url' },
  { label: 'Contract Url', key: 'contract_url' }
]

export const DATA_QUALITY_OPTIONS = [
  createOption('Ok', true),
  createOption('Not Ok', false),
  createOption('Not checked yet', null)
]

export const BOOL_FIELDS = [
  'assetLockup',
  'manuallyCalculatedAPR',
  'harvestLockup',
  'transferTax',
  'poolAlreadyFilled',
  'scam',
  'adSpot',
  'active'
]

export const FLOAT_FIELDS = [
  'assetPrice',
  'aprDaily',
  'aprWeekly',
  'aprYearly',
  'apyYearly',
  'depositFee',
  'withdrawalFee',
  'otherFees',
  'nativeTokenPrice',
  'tvlChange24hValue'
]

export const DATE_ASSETS = [
  'dateAdded',
  'dateUpdated',
  'dateStarted',
  'dateEnding'
]

export const READ_ONLY_FARM = ['tvlStaked']

export const BLOCKCHAINS = [
  'ETH',
  'BSC',
  'HECO',
  'POLYGON',
  'xDAI',
  'AVAX',
  'FANTOM',
  'HARMONY',
  'FUSE',
  'OKEX',
  'KUCOIN',
  'ARBITRUM',
  'CELO',
  'IOTEX',
  'MOONRIVER',
  'SOL',
  'TERRA'
]

export const ADDITIONAL_SERVICES = [
  createOption('Protection', 'protection'),
  createOption('Auto Compound', 'autoCompound'),
  createOption('Rating', 'rating')
]

export const FARM_TYPES = [
  null,
  'BLUE_CHIP',
  'DEGEN',
  'ALGORITHMIC',
  'STABLECOINS',
  'YIELD_AGGREGATOR',
  'LENDING',
  'LP_REWARDS'
]

export const RANK_OPTIONS = [
  createOption('1', '1'),
  createOption('2', '2'),
  createOption('3', '3')
]

export const BLOCKCHAIN_OPTIONS = BLOCKCHAINS.map((key) =>
  createOption(key, key)
)

export const BOOL_OPTIONS = [
  createOption('Yes', true),
  createOption('No', false)
]

export function formatValues(values) {
  const obj = {}

  Object.keys(values).forEach((key) => {
    obj[key] = {
      ...values[key],
      value: FLOAT_FIELDS.includes(key)
        ? Number(values[key].value)
        : values[key].value
    }
  })

  return obj
}
