import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  sectionTitle: {
    fontWeight: 500,
    padding: '20px 0',
    color: '#3f51b5'
  },
  platformContainer: {
    padding: '1rem 0',
    gap: '20px'
  },
  row: {
    flexFlow: 'row'
  }
})

export const BribePlatformDetails = ({ platform }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.platformContainer}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h4" className={classes.sectionTitle}>
        Platform Details
      </Typography>
      <Grid
        container
        className={classes.row}
        direction="row"
        justifyContent="space-between"
      >
        <Grid container direction="column">
          <Typography variant="h6">About:</Typography>
          <Typography>{platform.about || '/'}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Strategy:</Typography>
          <Typography>{platform.strategy || '/'}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.row}
        direction="row"
        justifyContent="space-between"
      >
        <Grid container direction="column">
          <Typography variant="h6">Risk description:</Typography>
          <Typography>{platform.risk_description || '/'}</Typography>
        </Grid>
        <Grid container direction="column" justifyContent="space-between">
          <Typography variant="h6">How To Use:</Typography>
          <Typography>{platform.how_to_use || '/'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
