import useAssets from 'hooks/useAssets'
import TablePageContainer from 'components/TablePageContainer'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AlarmIcon from '@material-ui/icons/Alarm'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { DATE_FORMAT, toDate, format } from 'utils/date'
import { useParams, Link } from 'react-router-dom'
import AddAssetDrawer from 'components/AddAssetDrawer'
import Tooltip from '@material-ui/core/Tooltip'
import { useState } from 'react'
import { AssetsContext } from 'utils/contexts'
import { removeDuplicates } from 'utils/api'
import AssetsFiltersDrawer from 'components/AssetsFiltersDrawer'
import AdditionalServicesDialog from 'components/dialogs/AdditionalServicesDialog'
import Grid from '@material-ui/core/Grid'

const COLS = [
  { name: 'asset', label: 'Asset' },
  { name: 'blockchain', label: 'Blockchain' },
  { name: 'date_added', label: 'Date added' },
  { name: 'farm', label: 'Farm' },
  { name: 'farmId', label: 'Farm ID' },
  { name: 'actions', label: '', align: 'right' }
]

const useStyles = makeStyles({
  button: {
    marginRight: '1rem'
  },
  btnCell: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end'
  }
})

export default function Assets() {
  const classes = useStyles()
  const params = useParams()
  const [addDrawer, setAddDrawer] = useState(false)
  const [filtersDrawer, setFiltersDrawer] = useState(false)
  const [mostUsedFields, setMostUsedFields] = useState(false)
  const [additionalServices, setAdditionalServices] = useState(null)
  const {
    assets,
    max,
    onSearch,
    isLoading,
    onPage,
    page,
    mutate,
    filters,
    onFilters,
    onDuplicate,
    onRemoveAsset
  } = useAssets()

  const contextValue = {
    mutate
  }

  const rows = removeDuplicates(assets, 'assetId')

  return (
    <AssetsContext.Provider value={contextValue}>
      <TablePageContainer
        page={page}
        maxPages={max}
        cols={COLS}
        onSearch={onSearch}
        loading={isLoading}
        onPageChange={onPage}
        onAdd={() => setAddDrawer(true)}
        onFilter={() => setFiltersDrawer(true)}
      >
        {rows.map((row) => (
          <TableRow key={row['assetId']}>
            <TableCell>{row['asset'] || row['assetId'] || 'N/A'}</TableCell>
            <TableCell>{row['blockchain']}</TableCell>
            <TableCell>
              {format(toDate(row['dateAdded']), DATE_FORMAT) || 'N/A'}
            </TableCell>
            <TableCell>{row['farm']}</TableCell>
            <TableCell>{row['farmId']}</TableCell>
            <TableCell align="right">
              <Grid className={classes.btnCell}>
                <Tooltip title="Additional Services">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setAdditionalServices(row['assetId'])}
                  >
                    <AnnouncementIcon />
                  </Button>
                </Tooltip>

                <Tooltip title="Most used fields">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`/assets/${row['assetId']}`}
                    onClick={() => setMostUsedFields(true)}
                  >
                    <AlarmIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Duplicate Asset">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => onDuplicate(row)}
                  >
                    <FileCopyIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Edit Asset">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`/assets/${row['assetId']}`}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete Asset">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onRemoveAsset(row['assetId'])}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TablePageContainer>

      <AddAssetDrawer
        edit={!!params.id}
        open={addDrawer || !!params.id}
        onClose={() => setAddDrawer(false)}
        closeTo="/assets"
        mostUsedFields={mostUsedFields}
        setMostUsedFields={setMostUsedFields}
      />

      <AssetsFiltersDrawer
        open={filtersDrawer}
        onClose={() => setFiltersDrawer(false)}
        filters={filters}
        onFilters={onFilters}
      />

      <AdditionalServicesDialog
        id={additionalServices}
        open={!!additionalServices}
        onClose={() => setAdditionalServices(null)}
      />
    </AssetsContext.Provider>
  )
}
