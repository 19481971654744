/**
 * Formats the number to the string value.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @param includeZero - Should the zero be a valid result?
 * @returns Formatted money string.
 */
export const formatNumber = (number, fixed = 2, includeZero = false) => {
  if (!number || isNaN(Number(number))) {
    return includeZero ? (0).toFixed(fixed) : 'N/A'
  }

  const isNegative = number < 0
  number = Math.abs(number)
  let formattedNumber

  const regex = /\.?0+$/

  if (number >= 1000000000) {
    formattedNumber =
      (number / 1000000000).toFixed(fixed).replace(regex, '') + 'B'
  } else if (number >= 1000000) {
    formattedNumber = (number / 1000000).toFixed(fixed).replace(regex, '') + 'M'
  } else if (number >= 1000) {
    formattedNumber = (number / 1000).toFixed(fixed).replace(regex, '') + 'k'
  } else {
    formattedNumber = number.toFixed(fixed).replace(regex, '')
  }

  if (isNegative) {
    formattedNumber = '-' + formattedNumber
  }

  return formattedNumber
}

/**
 * Formats the number to the string money value.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @param includeZero - Should the zero be a valid result?
 * @returns Formatted money string.
 */
export const formatMoney = (number, fixed = 2, includeZero = false) => {
  if (!number || isNaN(Number(number))) {
    return includeZero ? `$ ${(0).toFixed(fixed)}` : 'N/A'
  }
  return `$ ${formatNumber(number, fixed)}`
}

/**
 * Formats the given number to the percentage string.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @returns Formated percent string.
 */
export function formatPercent(number, fixed = 2) {
  if (!number || isNaN(Number(number))) return 'N/A'
  return number || number == 0 ? `${Number(number).toFixed(fixed)}%` : 'N/A'
}
