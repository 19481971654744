import Api from 'services/Api'

export async function addPdf(data) {
  const response = await Api.post('/add_pdf', data)
  return response.data
}

export async function getPdf(url) {
  const [reqUrl, params] = url.split('?')
  const response = await Api.post(reqUrl, JSON.parse(params))
  return response.data
}

export async function editPdf(data) {
  const response = await Api.formData({
    method: 'POST',
    url: '/edit_pdf',
    data
  })
  return response.data
}

export async function deletePdf(title) {
  const response = await Api.post('/delete_pdf', { title })
  return response.data
}
