import Api from 'services/Api'

export async function getAnnouncement(url) {
  const response = await Api.get(url)
  return response.data
}

export async function updateAnnouncement(announcement) {
  const response = await Api.put('/edit_globals', {
    announcement
  })
  return response.data
}
