import Api from 'services/Api'

export async function getBlogContents(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getSingleBlog(url) {
  const response = await Api.get(url)
  return response.data
}

export async function addBlog(data) {
  const response = await Api.post('/add_blog', data)
  return response.data
}

export async function removeBlog(id) {
  const response = await Api.post(`/delete_blog?blog_id=${id}`)
  return response.data
}
