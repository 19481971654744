import { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

// Components
import NavDrawer from 'components/NavDrawer'

// Services
import { auth } from 'services/auth'

// Assets
import { MultifarmLogo } from 'assets/icons'

// Utils
import { buildNavigationItems } from 'common/utils/app.utils'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,

    '& svg': {
      width: 30,
      height: 30,
      marginRight: '0.5rem'
    }
  },
  color: {
    width: 20,
    height: 20,
    borderRadius: 9999,
    margin: '0 1rem'
  },
  iconBtn: {
    color: '#fff'
  },
  content: {
    padding: '0 1rem'
  },
  menuAction: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    marginRight: '1.25rem'
  }
})

export default function Header() {
  const classes = useStyles()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const navigationItems = useMemo(() => buildNavigationItems(), [])
  const active =
    navigationItems.find((nav) => location.pathname.includes(nav.href))
      ?.title || ''

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.content}
        >
          <Grid className={classes.userContainer}>
            <MultifarmLogo />

            <Typography>User: {auth.user}</Typography>
            <Grid
              className={classes.color}
              style={{ background: auth.color }}
            />
          </Grid>

          <Grid className={classes.menuAction}>
            <Typography className={classes.title}>{active}</Typography>

            <IconButton
              className={classes.iconBtn}
              size="small"
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>

      <NavDrawer open={open} onClose={() => setOpen(false)} />
    </>
  )
}
