import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: (props) => ({
    display: 'flex',
    alignItems: 'center',

    '&:button': {
      marginRight: '1.25rem',

      '&:last-child': {
        marginRight: 0
      }
    },

    ...(props.align === 'right' && {
      justifyContent: 'flex-end'
    })
  })
})

export default function TableActionsCell(props) {
  const classes = useStyles(props)
  return <div className={classes.root}>{props.children}</div>
}
