import MuiCard from '@material-ui/core/Card'
import MuiCardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    width: 300,
    height: 200,
    margin: '1rem'
  }
})

export function Card({ children }) {
  const classes = useStyles()
  return <MuiCard className={classes.root}>{children}</MuiCard>
}

Card.propTypes = {
  children: PropTypes.node
}

const useCardContentStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
    height: '100%',

    '&:last-child': {
      paddingBottom: '1rem'
    }
  }
})

export function CardContent({ children }) {
  const classes = useCardContentStyles()
  return <MuiCardContent className={classes.root}>{children}</MuiCardContent>
}

CardContent.propTypes = {
  children: PropTypes.node
}

const useCardTitleStyles = makeStyles({
  root: {
    fontWeight: 700,
    fontSize: '1.25rem'
  }
})

export function CardTitle({ children }) {
  const classes = useCardTitleStyles()
  return (
    <Typography variant="subtitle1" className={classes.root}>
      {children}
    </Typography>
  )
}

CardTitle.propTypes = {
  children: PropTypes.node
}

const useCardSubtitleStyles = makeStyles({
  root: {
    fontSize: '1rem',
    fontWeight: 400
  }
})

export function CardSubtitle({ children }) {
  const classes = useCardSubtitleStyles()
  return <Typography className={classes.root}>{children}</Typography>
}

CardSubtitle.propTypes = {
  children: PropTypes.node
}

export function CardActions({ children }) {
  return (
    <Grid container justifyContent="flex-end">
      {children}
    </Grid>
  )
}

CardActions.propTypes = {
  children: PropTypes.node
}
