import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import createTheme from '@material-ui/core/styles/createTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SWRConfig } from 'swr'
import DateFnsUtils from '@date-io/date-fns'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const swrConfig = {
  revalidateOnFocus: false
}

const theme = createTheme()

ReactDOM.render(
  // <React.StrictMode>
  <SWRConfig config={swrConfig}>
    <Router>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
          <ToastContainer />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Router>
  </SWRConfig>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
