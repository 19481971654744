import Api from 'services/Api'
import { parseUrl } from 'utils/url'

export async function getAssetsList(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getAssetDetails(url) {
  const { query } = parseUrl(url)

  const response = await Api.post('/edit', {
    method: 'GET',
    ...query
  })

  return response.data
}

export async function addAsset(fields) {
  const response = await Api.post('/add_asset', {
    fields
  })

  return response.data.data
}

export async function updateAssetDetails(assetId, fields) {
  const response = await Api.put('/edit', {
    assetId,
    fields
  })
  return response.data.data
}

export async function removeAsset(assetId) {
  const response = await Api.post('/edit', {
    method: 'DELETE',
    assetIds: [assetId]
  })
  return response.data.data
}

export async function duplicateAsset(asset) {
  const response = await Api.post('/add_asset', {
    fields: asset
  })
  return response.data.data
}
