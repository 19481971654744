import { useState } from 'react'
import useSWR from 'swr'
import { getExchanges } from 'services/api/exchanges'
import Api from 'services/Api'
import { toast } from 'react-toastify'

function getKey(params) {
  return '/get_exchanges?' + JSON.stringify(params)
}

const initialFilters = {
  page: 1,
  per_page: 25,
  asset: '',
  sort: 'asset',
  sort_order: 'asc',
  exchangeName: [],
  blockchain: [],
  liquidityUSD: 0,
  pairPriceStd30d: [0, 0],
  lpPriceStd30d: [0, 0],
  netRoiLast30d: [0, 0],
  lpReturnHODLLast30d: [0, 0],
  netPrincipalRoiLast30d: [0, 0],
  impLossLast30d: [0, 0]
}

export default function useExchanges() {
  const [filters, onFilters] = useState(initialFilters)

  const params = {
    ...filters,
    sort_order: filters.sort_order === 'asc' ? 1 : 0
  }

  const {
    data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), getExchanges)

  const onPage = (page) => {
    onFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const onActive = async (id, active) => {
    try {
      await Api.post('/change_exchange_asset_status', {
        active,
        asset_id: id
      })
      revalidate?.()
      toast.success('Asset updated')
    } catch (e) {
      toast.error('Oops error')
      console.error(e)
    }
  }

  const isLoading = !data && !error
  const exchanges = data?.exchanges || []
  const maxPages = data?.max_pages || 1

  return {
    isLoading,
    exchanges,
    maxPages,
    filters,
    onPage,
    onActive
  }
}
