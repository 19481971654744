import Grid from '@material-ui/core/Grid'
import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    padding: '0.5rem 1rem 1rem 1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)'
  },
  titleComponent: {
    flex: 1
  }
})

export default function DrawerHead({ title, closeTo, component, onClose }) {
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className={classes.titleComponent}>
          <Typography variant="h5">{title}</Typography>
        </Grid>

        <Grid>
          <IconButton
            onClick={onClose}
            {...(closeTo && { component: Link, to: closeTo })}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {component}
    </Grid>
  )
}

DrawerHead.propTypes = {
  title: PropTypes.string,
  closeTo: PropTypes.string,
  component: PropTypes.node,
  onClose: PropTypes.func
}
