import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerActions from 'components/drawer/DrawerActions'
import DrawerContent from 'components/drawer/DrawerContent'
import useAssetDetails from 'hooks/useAssetDetails'
import LoadingOverlay from 'components/LoadingOverlay'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { useContext, useEffect, useState } from 'react'
import {
  ASSET_FIELDS,
  ASSET_MOST_USED_FIELDS,
  BLOCKCHAIN_OPTIONS,
  BOOL_FIELDS,
  DATA_QUALITY_OPTIONS,
  DATE_ASSETS,
  FLOAT_FIELDS,
  formatValues,
  TOOLTIP_LOCK
} from 'utils/forms'
import { useHistory, useParams } from 'react-router-dom'
import useFarmDetails from 'hooks/useFarmDetails'
import useValues from 'hooks/useValues'
import SelectFarms from 'components/form/SelectFarms'
import { createOption } from 'utils/form'
import ConfirmDialog from 'components/dialogs/ConfirmDialog'
import Tooltip from '@material-ui/core/Tooltip'
import useUpdateAsset from 'hooks/useUpdateAsset'
import { AssetsContext, FarmAssetsContext } from 'utils/contexts'
import useUpdateFarmAssets from 'hooks/useUpdateFarmAssets'
import DatePicker from 'components/form/DatePicket'
import Select from 'components/form/Select'
import SelectBoolean from 'components/form/SelectBoolean'
import FloatInput from 'components/form/FloatInput'
import ImagePicker from 'components/form/ImagePicker'

const useStyles = makeStyles(() => ({
  paper: {
    width: 500
  },
  content: {
    flex: 1,
    height: '100%'
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  },
  imageContainer: {
    marginTop: '1rem'
  }
}))

function AddAssetDrawerContent({
  onClose,
  edit,
  editFarm,
  closeTo,
  mostUsedFields,
  setMostUsedFields,
  customComponent
}) {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const [confirm, setConfirm] = useState(false)

  const { mutate: mutateAssets } = useContext(AssetsContext)
  const { mutate: mutateFarmAssets } = useContext(FarmAssetsContext)

  const assetId =
    typeof edit === 'string'
      ? edit
      : 'assetId' in params
      ? params.assetId
      : params.id

  const { asset, isLoading } = useAssetDetails(edit ? assetId : null)
  const { farm, isLoading: isFarmLoading } = useFarmDetails(
    editFarm ? params.id : null
  )

  const { onSaveAsset, isLoading: isUpdateLoading } = useUpdateAsset()
  const { onUpdateFarmAssets, isLoading: isUpdateAssetsLoading } =
    useUpdateFarmAssets()

  const { values, onChange, onChangeDebounce, onLock } = useValues({
    currentDate: !edit && !editFarm
  })

  const [search, setSearch] = useState('')

  useEffect(() => {
    return () => {
      setMostUsedFields?.(false)
    }
  }, [])

  const handleSuccess = () => {
    mutateAssets && mutateAssets()
    mutateFarmAssets && mutateFarmAssets()
    history.push(closeTo)
    onClose?.()
  }

  const handleSubmit = () => {
    const newValues = formatValues(values)
    editFarm
      ? onUpdateFarmAssets(params.id, newValues, handleSuccess)
      : onSaveAsset(assetId, newValues, handleSuccess)
  }

  if ((edit && isLoading) || (editFarm && isFarmLoading)) {
    return <LoadingOverlay />
  }

  const title = edit
    ? 'Asset: ' + (asset['asset']?.value || asset['assetId']?.value || 'N/A')
    : editFarm
    ? `Apply fields for all assets of: ${farm['farmName']?.value || 'N/A'}`
    : 'Asset: ' + (values['asset']?.value || '')

  const filterKeys = (
    mostUsedFields ? ASSET_MOST_USED_FIELDS : ASSET_FIELDS
  ).filter((key) => key.toLowerCase().startsWith(search.toLowerCase()))

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.content}
      >
        <DrawerHead
          title={title}
          closeTo={closeTo}
          onClose={onClose}
          component={
            <TextField
              variant="outlined"
              size="small"
              label="Search field"
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />

        <DrawerContent>
          {filterKeys.map((key) => {
            const lockValue = values[key]
              ? values[key].locked
              : !!asset[key]?.locked
            const Lock = (
              <Tooltip title={TOOLTIP_LOCK}>
                <IconButton onClick={() => onLock(key, !lockValue)}>
                  {lockValue ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
              </Tooltip>
            )

            if (key === 'farmId') {
              return (
                <Grid container alignItems="center" key={key}>
                  <SelectFarms
                    className={classes.input}
                    label={key}
                    variant="outlined"
                    size="small"
                    defaultValue={createOption(
                      asset['farm']?.value,
                      asset['farmId']?.value
                    )}
                    onChange={(o) => onChangeDebounce(key, o)}
                  />
                  {Lock}
                </Grid>
              )
            }
            if (key === 'dataQuality') {
              return (
                <Grid container alignItems="center" key={key}>
                  <Select
                    name={key}
                    options={DATA_QUALITY_OPTIONS}
                    className={classes.input}
                    onChange={(e, o) => onChangeDebounce(key, o)}
                    defaultValue={DATA_QUALITY_OPTIONS.find(
                      (o) => o.value === asset['dataQuality']?.value
                    )}
                  />
                  {Lock}
                </Grid>
              )
            }

            if (BOOL_FIELDS.includes(key)) {
              return (
                <Grid container alignItems="center" key={key}>
                  <SelectBoolean
                    defaultValue={
                      typeof values[key]?.value === 'boolean'
                        ? values[key]?.value
                        : typeof asset[key]?.value === 'boolean'
                        ? asset[key]?.value
                        : null
                    }
                    onChange={(e, o) => onChangeDebounce(key, o.value)}
                    className={classes.input}
                    name={key}
                  />
                  {Lock}
                </Grid>
              )
            }
            if (DATE_ASSETS.includes(key)) {
              return (
                <Grid container alignItems="center" key={key}>
                  <DatePicker
                    defaultValue={
                      values[key]?.value
                        ? values[key]?.value
                        : asset[key]?.value
                        ? new Date(asset[key]?.value)
                        : null
                    }
                    onChange={(date) => onChangeDebounce(key, date)}
                    className={classes.input}
                    label={key}
                  />
                  {Lock}
                </Grid>
              )
            }
            if (key === 'blockchain') {
              return (
                <Grid container alignItems="center" key={key}>
                  <Select
                    name={key}
                    options={BLOCKCHAIN_OPTIONS}
                    className={classes.input}
                    onChange={(e, o) => onChangeDebounce(key, o)}
                    defaultValue={createOption(
                      asset['blockchain']?.value,
                      asset['blockchain']?.value
                    )}
                  />
                  {Lock}
                </Grid>
              )
            }
            if (FLOAT_FIELDS.includes(key)) {
              return (
                <Grid container alignItems="center" key={key}>
                  <FloatInput
                    name={key}
                    value={values[key]?.value || asset[key]?.value || ''}
                    onChange={onChange}
                    variant="outlined"
                    size="small"
                    margin="normal"
                  />
                  {Lock}
                </Grid>
              )
            }

            if (key === 'imageUrl') {
              const imageUrl = (values[key] || asset[key])?.value
              return (
                <Grid
                  className={classes.imageContainer}
                  wrap="nowrap"
                  container
                  alignItems="center"
                  key={key}
                >
                  <ImagePicker
                    imageSrc={imageUrl}
                    imageAlt="Asset icon"
                    onChange={(url) => onChangeDebounce(key, url)}
                  />
                  {Lock}
                </Grid>
              )
            }

            return (
              <Grid container alignItems="center" key={key}>
                <TextField
                  className={classes.input}
                  label={key}
                  type="text"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  defaultValue={asset[key]?.value || ''}
                  onChange={(e) => onChangeDebounce(key, e.target.value)}
                />
                {Lock}
              </Grid>
            )
          })}

          {customComponent}
        </DrawerContent>

        <DrawerActions
          cancelTo={closeTo || '/assets'}
          onClose={onClose}
          onSave={() => (editFarm ? setConfirm(true) : handleSubmit())}
          saveDisabled={isUpdateLoading || isUpdateAssetsLoading}
        />
      </Grid>

      <ConfirmDialog
        title="Warning!"
        message="This will apply changes to all fields of all assets of that farm"
        open={confirm}
        onClose={() => setConfirm(false)}
        onConfirm={handleSubmit}
      />
    </>
  )
}

export default function AddAssetDrawer({ open, ...props }) {
  const classes = useStyles()
  return (
    <Drawer open={open} classes={{ paper: classes.paper }} anchor="right">
      <AddAssetDrawerContent {...props} />
    </Drawer>
  )
}
