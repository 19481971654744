import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  header: {
    padding: '1rem'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2rem 1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',

    '&:first-child': {
      borderTop: '1px solid rgba(0, 0, 0, 0.23)'
    }
  },
  listText: {
    fontWeight: 700
  }
})

export default function ContentSelect() {
  const classes = useStyles()
  return (
    <Grid container direction="column">
      <Grid container className={classes.header}>
        <Typography variant="h5">Landing app content</Typography>
      </Grid>

      <Grid container direction="column">
        <List>
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/landing/blog"
          >
            <Typography className={classes.listText}>Blog</Typography>
            <ArrowForwardIosIcon />
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            to="/landing/careers"
          >
            <Typography className={classes.listText}>Careers</Typography>
            <ArrowForwardIosIcon />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}
