import { useState } from 'react'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { mutate as globalRevalidate } from 'swr'
import { toast } from 'react-toastify'

// Components
import { AssetToken } from './AssetToken'
import { AssetTokenDrawer } from 'components/AssetTokenDrawer'
import { editBribePlatform } from 'services/api/bribe-platforms'

const useStyles = makeStyles({
  white: {
    color: 'white'
  },
  spaceRight: {
    marginRight: '10px'
  },
  assetDetails: {
    margin: '2rem 0'
  },
  assetDetailsGrid: {
    width: '100%',
    padding: '1rem 0'
  },
  assetDetailsTitle: {
    fontWeight: 500,
    padding: '20px 0',
    marginRight: '20px',
    color: '#3f51b5'
  },
  assetDetailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  assetTokens: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1.25rem'
  }
})

export const AssetDetails = ({ platform, updatePlatform }) => {
  const classes = useStyles()

  const [isAddTokenDialogOpen, switchAddTokenDialog] = useState(false)
  const [assetToken, setAssetToken] = useState(undefined)

  /**
   * Handles the asset token save.
   * @param {Record<string, string>} newToken - New token values.
   */
  const handleAssetToken = async (newToken) => {
    if (hasAssetConflict(newToken.asset)) {
      toast.error('Asset name already taken.')
      return
    }
    const assetTokens = prepareNewAssetTokens(newToken)
    const updateData = {
      ...platform,
      asset_tokens: assetTokens
    }
    const response = await editBribePlatform(platform.platform, updateData)
    if (response.error) {
      toast.error('Editting bribe platform failed.')
      return
    }
    globalRevalidate()
    updatePlatform(updateData)
    toast.success('Asset token saved.')
    switchAddTokenDialog(false)
    setAssetToken(undefined)
  }

  /**
   * Removes the asset token from the platform.
   * @param {string} asset - Asset's name.
   */
  const removeAssetToken = async (asset) => {
    let assetTokens = platform.asset_tokens || []
    assetTokens = assetTokens.filter((assetToken) => assetToken.asset !== asset)
    const updateData = {
      ...platform,
      asset_tokens: assetTokens
    }
    const response = await editBribePlatform(platform.platform, updateData)
    if (response.error) {
      toast.error('Editting bribe platform failed.')
      return
    }
    globalRevalidate()
    updatePlatform(updateData)
    toast.success('Asset token removed.')
  }

  /**
   * Check's if platform already has an asset token with the same asset value.
   * @param {string} asset - Asset's name.
   * @returns {boolean}
   */
  const hasAssetConflict = (asset) => {
    return (
      assetToken?.asset !== asset &&
      platform.asset_tokens?.find(
        (token) => token.asset !== assetToken?.asset && token.asset === asset
      )
    )
  }

  /**
   * Prepares a new array of asset tokens based on the newly saved token.
   * @param {Record<string, string>} newToken - New token values.
   * @returns The updated asset tokens array of the platform.
   */
  const prepareNewAssetTokens = (newToken) => {
    let assetTokens = platform.asset_tokens || []
    if (assetToken) {
      return assetTokens.map((existingToken) => {
        if (existingToken.asset !== assetToken.asset) {
          return existingToken
        }
        return newToken
      })
    }
    return [...assetTokens, newToken]
  }

  return (
    <Grid className={classes.assetDetailsGrid}>
      <Grid className={classes.assetDetailsTitleContainer}>
        <Typography variant="h4" className={classes.assetDetailsTitle}>
          Asset Details
        </Typography>
        <IconButton
          size="small"
          onClick={() => switchAddTokenDialog(!isAddTokenDialogOpen)}
        >
          <AddIcon />
        </IconButton>
      </Grid>

      <Grid className={classes.assetTokens}>
        {platform.asset_tokens?.map((assetToken, index) => (
          <AssetToken
            key={`assetToken-${index}`}
            assetToken={assetToken}
            onEdit={setAssetToken}
            onDelete={removeAssetToken}
          />
        ))}
      </Grid>
      <AssetTokenDrawer
        open={isAddTokenDialogOpen || !!assetToken}
        assetToken={assetToken}
        onClose={() => {
          switchAddTokenDialog(false)
          setAssetToken(undefined)
        }}
        onSave={handleAssetToken}
      />
    </Grid>
  )
}
