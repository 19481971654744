import useRoles from 'hooks/useRoles'
import TablePageContainer from 'components/TablePageContainer'
import { useMemo, useState } from 'react'
import TableActionsCell from 'components/table/TableActionsCell'
import Button from 'components/buttons/Buttons'
import EditIcon from '@material-ui/icons/Edit'
import AddRoleDrawer from 'components/AddRoleDrawer'
import TableRow from '@material-ui/core/TableRow'
import { take } from 'utils/obj'
import TableCell from 'components/table/TableCell'
import { renderPages } from 'utils/roles'

const COLS = [
  { name: 'role', label: 'Role' },
  { name: 'pages', label: 'Pages' },
  { name: 'actions', label: '', align: 'right', width: 96 }
]

export default function Roles() {
  const roles = useRoles()
  const [editId, onEditId] = useState('')
  const [drawer, onDrawer] = useState(false)

  const Renderer = useMemo(
    () => ({
      role: (row) => row['role'],
      pages: (row) => renderPages(row['pages']),
      actions: (row) => (
        <TableActionsCell align="right">
          <Button tooltip="Edit Role" onClick={() => onEditId(row['role'])}>
            <EditIcon />
          </Button>
        </TableActionsCell>
      )
    }),
    []
  )

  return (
    <>
      <TablePageContainer cols={COLS} onAdd={() => onDrawer(true)}>
        {roles.data.map((row) => (
          <TableRow key={row.role}>
            {COLS.map((col) => (
              <TableCell key={col.name} {...take(col, ['align', 'width'])}>
                {Renderer[col.name](row)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TablePageContainer>

      <AddRoleDrawer
        editId={editId}
        open={!!editId || drawer}
        onClose={() => {
          onDrawer(false)
          onEditId('')
        }}
        revalidate={roles.revalidate}
      />
    </>
  )
}
