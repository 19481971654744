import MuiDrawer from '@material-ui/core/Drawer'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  paper: {
    width: (props) => props.size || 500,
    justifyContent: 'space-between'
  }
})

export default function Drawer({ children, open, size, onClose }) {
  const classes = useStyles({ size })
  return (
    <MuiDrawer
      open={open}
      onClose={onClose}
      anchor="right"
      classes={{ paper: classes.paper }}
    >
      {children}
    </MuiDrawer>
  )
}

Drawer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  size: PropTypes.number,
  onClose: PropTypes.func
}
