import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Tooltip } from '@material-ui/core'
import { useContext, useState } from 'react'
import { FarmAssetsContext } from 'utils/contexts'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isFloat } from 'utils/form'
import FileCopyIcon from '@material-ui/icons/FileCopy'

export function EditButtonCell(props) {
  const { onDuplicate } = useContext(FarmAssetsContext)
  return (
    <>
      <IconButton
        size="small"
        component={Link}
        to={`/farms/${encodeURIComponent(
          props.row['farmId']
        )}/grid/${encodeURIComponent(props.row['assetId'])}`}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={() => onDuplicate(props.row)}>
        <FileCopyIcon fontSize="small" />
      </IconButton>
    </>
  )
}

EditButtonCell.propTypes = {
  row: PropTypes.object
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '100%'
  },
  titleContainer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  title: {}
})

export function HeaderCell(props) {
  const classes = useStyles()
  const { field } = props
  const { openEditDialog } = useContext(FarmAssetsContext)
  return (
    <Grid
      container
      justifyContent="space-between"
      wrap="nowrap"
      className={classes.root}
    >
      <Grid item xs={8} className={classes.titleContainer}>
        <Tooltip title={field}>
          <span className={classes.title}>{field}</span>
        </Tooltip>
      </Grid>

      <Grid item xs={4}>
        <Tooltip title={`Apply "${field}" change for entire column`}>
          <IconButton onClick={() => openEditDialog(field)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

HeaderCell.propTypes = {
  field: PropTypes.string
}

export function BoolCell(props) {
  return (
    <div>
      <span>
        {typeof props.formattedValue === 'boolean'
          ? props.formattedValue
            ? 'Yes'
            : 'No'
          : ''}
      </span>
    </div>
  )
}

BoolCell.propTypes = {
  formattedValue: PropTypes.bool
}

export function DataQualityCell(props) {
  return (
    <div>
      <span>
        {typeof props.formattedValue === 'boolean'
          ? props.formattedValue
            ? 'Ok'
            : 'Not ok'
          : props.formattedValue === null
          ? 'Not checked yet'
          : ''}
      </span>
    </div>
  )
}

DataQualityCell.propTypes = {
  formattedValue: PropTypes.any
}

const useFloatEditCellStyles = makeStyles({
  input: {
    width: '100%',
    height: '100%',
    padding: '0 16px',
    font: 'inherit',
    color: 'currentColor',
    border: 0,
    display: 'block'
  }
})

export function FloatEditCell(props) {
  const [value, setValue] = useState(props.value)
  const classes = useFloatEditCellStyles()
  return (
    <div>
      <input
        data-type="float"
        pattern="[+-]?\d+(?:[.,]\d+)?"
        value={value}
        onChange={(e) => {
          if (isFloat(e.target.value)) {
            setValue(e.target.value)
          }
        }}
        className={classes.input}
      />
    </div>
  )
}

FloatEditCell.propTypes = {
  value: PropTypes.any
}
