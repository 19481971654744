import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Typography, Grid, TextField } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

// Components
import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerContent from 'components/drawer/DrawerContent'
import Button from './buttons/Buttons'
import { AddFieldDialog } from './dialogs/AddFieldDialog'

// Hooks
import useValues from 'hooks/useValues'

// Services
import { editBribePlatform, formatFormData } from 'services/api/index'

// Utils
import {
  BRIBE_PLATFORM_FIELDS,
  BRIBE_PLATFORM_IGNORED_FIELDS
} from 'utils/forms'
import { isPrimitive } from 'utils/global'

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    height: '100%'
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  },
  actions: {
    padding: '1rem',
    '& button:first-child': {
      marginRight: '1rem'
    }
  },
  button: {
    marginRight: '1rem'
  },
  sectionTitle: {
    paddingTop: 10
  }
}))

const Content = ({ onClose, platform = {}, onSuccess }) => {
  const [fieldDialogOpen, setFieldDialogOpen] = useState(false)
  // Newly added fields by the user
  const [addedFields, setAddedFields] = useState([])
  // Other fields that are on the platform but are not explicitly handled.
  const [otherFields, setOtherFields] = useState([])

  const classes = useStyles()
  // Form
  const { values, onChange } = useValues(platform)

  useEffect(() => {
    const handledKeys = []
    // Initialising values for the handled fields
    for (const field of BRIBE_PLATFORM_FIELDS) {
      onChange(field.key, platform[field.key])
      handledKeys.push(field.key)
    }

    // Presenting all other primitive fields of the bribe platform
    const otherPlatformFields = []
    for (const key of Object.keys(platform)) {
      if (
        !isPrimitive(platform[key]) ||
        handledKeys.includes(key) ||
        BRIBE_PLATFORM_IGNORED_FIELDS.includes(key)
      ) {
        continue
      }
      onChange(key, platform[key])
      otherPlatformFields.push({ key, label: key.split('_').join(' ') })
    }
    setOtherFields(otherPlatformFields)
  }, [])

  const handleSave = async () => {
    const data = formatFormData(values)
    const response = await editBribePlatform(platform.platform, data)
    if (response.error) {
      toast.error('Editting bribe platform failed.')
      return
    }
    toast.success('Editting bribe platform was successful.')
    if (onSuccess) {
      onSuccess(data)
    }
    onClose()
  }

  /**
   * Handle the addition of new field to the bribe platform.
   * @param {string} field - Field name
   */
  const handleAddField = (field) => {
    const formattedField = {
      label: field,
      key: field.split(' ').join('_').toLowerCase()
    }
    setAddedFields([...addedFields, formattedField])
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.content}
    >
      <DrawerHead
        title={`Edit Bribe Platform ${platform.platform}`}
        onClose={onClose}
      />

      <DrawerContent>
        <Grid container alignItems="center">
          <TextField
            className={classes.input}
            label={'Platform'}
            variant="outlined"
            value={platform.platform || ''}
            size="small"
            margin="normal"
            disabled
          />
        </Grid>
        {BRIBE_PLATFORM_FIELDS.map((field) => {
          return (
            <Grid container alignItems="center" key={field.key}>
              <TextField
                className={classes.input}
                label={field.label}
                variant="outlined"
                value={values[field.key]?.value || ''}
                size="small"
                margin="normal"
                onChange={(e) => onChange(field.key, e.target.value)}
              />
            </Grid>
          )
        })}
        {!!otherFields.length && (
          <>
            <Typography className={classes.sectionTitle}>
              Other fields (Not explicitly handled by the cms)
            </Typography>
            {otherFields.map((field) => {
              return (
                <Grid container alignItems="center" key={field.key}>
                  <TextField
                    className={classes.input}
                    label={field.label}
                    variant="outlined"
                    value={values[field.key]?.value || ''}
                    size="small"
                    margin="normal"
                    onChange={(e) => onChange(field.key, e.target.value)}
                  />
                </Grid>
              )
            })}
          </>
        )}
        {!!addedFields.length && (
          <>
            <Typography className={classes.sectionTitle}>
              Added fields
            </Typography>
            {addedFields.map((field) => {
              return (
                <Grid container alignItems="center" key={field.key}>
                  <TextField
                    className={classes.input}
                    label={field.label}
                    variant="outlined"
                    value={values[field.key]?.value || ''}
                    size="small"
                    margin="normal"
                    onChange={(e) => onChange(field.key, e.target.value)}
                  />
                </Grid>
              )
            })}
          </>
        )}
      </DrawerContent>

      <AddFieldDialog
        open={fieldDialogOpen}
        onClose={() => setFieldDialogOpen(false)}
        onApply={handleAddField}
      />

      <Grid container justifyContent="flex-end" className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setFieldDialogOpen(true)}
        >
          Add Field
        </Button>
        <Button
          component={Link}
          to={'/bribe-platforms'}
          variant="contained"
          color="default"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export const EditPlatformDrawer = ({ open, onClose, platform, onSuccess }) => {
  return (
    <Drawer open={open} anchor="right" size={600}>
      <Content onClose={onClose} platform={platform} onSuccess={onSuccess} />
    </Drawer>
  )
}
