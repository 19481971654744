import { useMemo } from 'react'
import { Button } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import DetailsIcon from '@material-ui/icons/RemoveRedEye'

// Components
import TablePageContainer from 'components/TablePageContainer'
import TableCell from 'components/table/TableCell'

// Hooks - API
import { useBribePlatforms } from 'hooks/useBribePlatforms'

export const BribePlatformsTable = ({ setActivePlatform }) => {
  const { platforms, maxPages, filters, onPage } = useBribePlatforms()

  const COLS = useMemo(
    () => [
      {
        name: 'plaftorm',
        label: 'Platform',
        render: (platform) => platform.platform || '-'
      },
      {
        name: 'markets_count',
        label: 'Markets count',
        render: (platform) => platform.markets_count || '-'
      },
      {
        name: 'gauges_count',
        label: 'Gauges count',
        render: (platform) => platform.gauges_count || '-'
      },
      {
        name: 'platform_details',
        label: 'Platform Details',
        render: (platform) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActivePlatform(platform)
              }}
            >
              <DetailsIcon />
            </Button>
          )
        }
      }
    ],
    [setActivePlatform]
  )

  return (
    <>
      <TablePageContainer
        cols={COLS}
        maxPages={maxPages}
        page={filters.page}
        onPageChange={onPage}
      >
        {platforms.map((platform, index) => (
          <TableRow key={`${platform.id}-${index}`}>
            {COLS.map((col) => (
              <TableCell key={col.name}>{col.render(platform)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TablePageContainer>
    </>
  )
}
