import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerContent from 'components/drawer/DrawerContent'
import DrawerActions from 'components/drawer/DrawerActions'
import Button from 'components/buttons/Buttons'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { PREMIUM_PAGES_OPTIONS } from 'utils/filters'
import { useForm, Controller } from 'react-hook-form'
import useRole from 'hooks/useRole'
import { useEffect } from 'react'
import { formatApiForm } from 'utils/roles'

function Content(props) {
  const role = useRole({
    name: props.editId
  })

  const form = useForm({
    defaultValues: {
      name: '',
      pages: []
    }
  })

  useEffect(() => {
    if (role.data.role) {
      form.reset(formatApiForm(role.data))
    }
  }, [JSON.stringify(role.data)])

  const handleSubmit = (values) => {
    role.onAdd(values, () => {
      props.revalidate?.()
      props.onClose()
    })
  }

  return (
    <>
      <DrawerHead
        title={props.editId ? 'Edit Role' : 'Add Role'}
        onClose={props.onClose}
        closeTo={props.closeTo}
      />

      <DrawerContent>
        <Controller
          control={form.control}
          render={(e) => (
            <Input
              label="Role Name"
              value={e.field.value}
              onChange={(_e) => form.setValue(e.field.name, _e.target.value)}
            />
          )}
          name="name"
        />
        <Controller
          control={form.control}
          render={(e) => (
            <Select
              multiple
              name="Accessible Pages"
              options={PREMIUM_PAGES_OPTIONS}
              value={e.field.value}
              onChange={(_e, __e) => form.setValue(e.field.name, __e)}
            />
          )}
          name="pages"
        />
      </DrawerContent>

      <DrawerActions>
        <Button color="secondary">Reset</Button>
        <Button onClick={() => form.handleSubmit(handleSubmit)()}>Save</Button>
      </DrawerActions>
    </>
  )
}

export default function AddRoleDrawer(props) {
  return (
    <Drawer open={props.open}>
      <Content {...props} />
    </Drawer>
  )
}
