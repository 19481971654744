import { omit, omitEmpty } from 'utils/obj'
import { formatQueryFilters } from 'utils/filters'

const innerFiltersKeys = [
  'pairPriceStd30d',
  'lpPriceStd30d',
  'netRoiLast30d',
  'lpReturnHODLLast30d',
  'netPrincipalRoiLast30d',
  'impLossLast30d',
  'blockchain',
  'exchangeName',
  'liquidityUSD'
]

export function formatQueryParams(url) {
  try {
    const split = url.split('?')
    const base = split[0]
    const query = omitEmpty(JSON.parse(split[1]), ['sort_order'])

    const otherQuery = omit(query, innerFiltersKeys)

    const queryRequiredForApiStructure = {
      ...otherQuery
    }

    queryRequiredForApiStructure.filters = formatQueryFilters(
      query,
      innerFiltersKeys
    )

    if (!Object.keys(queryRequiredForApiStructure.filters).length) {
      delete queryRequiredForApiStructure.filters
    }

    const parseQuery = Object.entries(queryRequiredForApiStructure)
      .map((e) =>
        e[0] === 'filters' ? `${e[0]}=${JSON.stringify(e[1])}` : e.join('=')
      )
      .join('&')

    return `${base}?${parseQuery}`
  } catch (e) {
    console.error(e)
    return ''
  }
}
