import { useState } from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ArrowBack from '@material-ui/icons/ArrowBack'

// Components
import { BribePlatformDetails } from './presentation/BribePlatformDetails'
import { BribePlatformLinks } from './presentation/BribePlatformLinks'
import { BribePlatformOverview } from './presentation/BribePlatformOverview'
import { AssetDetails } from './AssetDetails'
import { EditPlatformDrawer } from 'components/EditPlatformDrawer'
import Button from 'components/buttons/Buttons'

const useStyles = makeStyles({
  root: {
    flex: 1,
    padding: '1rem'
  },
  sectionTitle: {
    fontWeight: 500,
    padding: '20px 0',
    color: '#3f51b5'
  },
  headerContainer: {
    padding: '1rem 0',
    flexFlow: 'row',
    gap: '10px'
  },
  platformContainer: {
    padding: '1rem 0',
    gap: '20px'
  },
  linksContainer: {
    flexFlow: 'row',
    gap: '20px'
  },
  row: {
    flexFlow: 'row'
  },
  wide: {
    width: '100%'
  }
})

export const BribePlatform = ({ platform, setActivePlatform }) => {
  const classes = useStyles()
  const [editPlatformForm, setEditPlatformForm] = useState(false)

  const updatePlatform = (data) => {
    setActivePlatform({ ...platform, ...data })
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column">
        <Grid
          container
          className={classes.headerContainer}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            container
            className={classes.headerContainer}
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActivePlatform(undefined)
              }}
            >
              <ArrowBack />
            </Button>
            <Typography variant="h4">
              Bribe platform: {platform.platform}
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditPlatformForm(true)
            }}
          >
            <EditIcon />
          </Button>
        </Grid>
        <Divider />
        <Grid
          container
          className={classes.platformContainer}
          alignItems="center"
          justifyContent="space-between"
        >
          <BribePlatformOverview platform={platform} />
          <Divider className={classes.wide} />
          <BribePlatformDetails platform={platform} />
          <Divider className={classes.wide} />
          <BribePlatformLinks platform={platform} />
          <Divider className={classes.wide} />
        </Grid>
      </Grid>

      <AssetDetails platform={platform} updatePlatform={updatePlatform} />

      <EditPlatformDrawer
        open={editPlatformForm}
        onClose={() => setEditPlatformForm(false)}
        platform={platform}
        onSuccess={updatePlatform}
      />
    </Grid>
  )
}
