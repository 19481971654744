import TablePageContainer from 'components/TablePageContainer'
import { Link, useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useCareers from 'hooks/useCareers'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import format from 'date-fns/format'
import { DATE_FORMAT } from 'utils/date'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const COLS = [
  { name: 'name', label: 'Name' },
  { name: 'dateAdded', label: 'Date Added' },
  { name: 'actions', label: '', align: 'right' }
]

const useStyles = makeStyles({
  button: {
    marginRight: '1rem'
  }
})

export default function Careers() {
  const classes = useStyles()
  const { careers, isLoading, onRemove } = useCareers()
  const history = useHistory()

  return (
    <TablePageContainer
      cols={COLS}
      onAdd={() => history.push('/landing/careers/new')}
      onSearch={() => {}}
      loading={isLoading}
    >
      {careers.map((row) => (
        <TableRow key={row['career_id']}>
          <TableCell>{row['title'] || '-'}</TableCell>
          <TableCell>
            {row['date_added']
              ? format(new Date(row['date_added']), DATE_FORMAT)
              : '-'}
          </TableCell>

          <TableCell align="right">
            <Tooltip title="Edit Career">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={Link}
                to={`/landing/careers/${row['career_id']}`}
              >
                <EditIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Delete Career">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onRemove(row['career_id'])}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </TablePageContainer>
  )
}
