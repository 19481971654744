import { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'

// Components
import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerContent from 'components/drawer/DrawerContent'
import DrawerActions from 'components/drawer/DrawerActions'

// Hooks
import useValues from 'hooks/useValues'

// Services
import { formatFormData } from 'services/api/index'

// Utils
import { BRIBE_PLATFORM_ASSET_TOKEN_FIELDS } from 'utils/forms'
import { toast } from 'react-toastify'

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    height: '100%'
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  }
}))

const Content = ({ onClose, assetToken, onSave }) => {
  const classes = useStyles()
  const { values, onChange } = useValues()

  useEffect(() => {
    if (!assetToken) return
    for (const field of BRIBE_PLATFORM_ASSET_TOKEN_FIELDS) {
      onChange(field.key, assetToken[field.key])
    }
  }, [])

  const handleSave = async () => {
    const data = formatFormData(values)
    for (const field of BRIBE_PLATFORM_ASSET_TOKEN_FIELDS) {
      if (field.required && !data[field.key]) {
        toast.error('All fields are required.')
        return
      }
    }
    onSave(data)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.content}
    >
      <DrawerHead
        title={`Asset Token ${assetToken?.asset || ''}`}
        onClose={onClose}
      />

      <DrawerContent>
        {BRIBE_PLATFORM_ASSET_TOKEN_FIELDS.map((field) => {
          return (
            <Grid container alignItems="center" key={field.key}>
              <TextField
                className={classes.input}
                label={field.label}
                variant="outlined"
                value={values[field.key]?.value || ''}
                size="small"
                margin="normal"
                onChange={(e) => onChange(field.key, e.target.value)}
              />
            </Grid>
          )
        })}
      </DrawerContent>

      <DrawerActions
        cancelTo="/bribe-platforms"
        onClose={onClose}
        onSave={handleSave}
      />
    </Grid>
  )
}

export const AssetTokenDrawer = ({ open, onClose, assetToken, onSave }) => {
  return (
    <Drawer open={open} anchor="right">
      <Content onClose={onClose} assetToken={assetToken} onSave={onSave} />
    </Drawer>
  )
}
