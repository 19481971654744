import { PREMIUM_PAGES_OPTIONS } from 'utils/filters'

export function formatForm(form) {
  return Object.keys(form).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: form[cur],
      ...(cur === 'pages' && {
        [cur]: form[cur].map((e) => e.value)
      })
    }),
    {}
  )
}

export function formatApiForm(form) {
  return {
    name: form.role,
    pages: form.pages.map((e) =>
      PREMIUM_PAGES_OPTIONS.find((_e) => _e.value === e)
    )
  }
}

export function renderPages(pages) {
  return pages
    .map((e) => PREMIUM_PAGES_OPTIONS.find((_e) => _e.value === e)?.label)
    .filter(Boolean)
    .join(', ')
}

export function rolesToOptions(roles) {
  return roles.map((row) => ({
    label: row.role,
    value: row.role
  }))
}
