import { useState } from 'react'
import { updateFarmAssets } from 'services/api/farms'
import { formatAssetData } from 'utils/api'
import { toast } from 'react-toastify'

export default function useUpdateFarmAssets() {
  const [isLoading, setLoading] = useState(false)

  const onUpdateFarmAssets = async (id, fields, onSuccess) => {
    try {
      setLoading(true)
      await updateFarmAssets(id, formatAssetData(fields))
      toast.success('Farm assets updated successfully')
      setLoading(false)
      onSuccess?.()
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  return {
    isLoading,
    onUpdateFarmAssets
  }
}
