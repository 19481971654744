import TableRow from '@material-ui/core/TableRow'
import TablePageContainer from 'components/TablePageContainer'
import TableCell from '@material-ui/core/TableCell'
import useWaitlist from 'hooks/useWaitlist'
import format from 'date-fns/format'
import { DATE_FORMAT } from 'utils/date'

const COLS = [
  { name: 'date', label: 'Date' },
  { name: 'email', label: 'Email' },
  { name: 'message', label: 'Message' }
]

export default function Waitlist() {
  const waitlist = useWaitlist()
  return (
    <TablePageContainer
      page={waitlist.filters.page}
      maxPages={waitlist.maxPages}
      cols={COLS}
      loading={waitlist.isLoading}
      onPageChange={waitlist.onPage}
    >
      {waitlist.emails.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            {row.date ? format(new Date(row.date), DATE_FORMAT) : '-'}
          </TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.message || '-'}</TableCell>
        </TableRow>
      ))}
    </TablePageContainer>
  )
}
