// Services
import { auth } from 'services/auth'

// Static
import {
  ALL_PAGES,
  APP_USERS,
  PALADIN_PAGES,
  ALL_NAVIGATION_ITEMS,
  PALADIN_NAVIGATION_ITEMS
} from 'common/static'

/**
 * @returns The pages accessible by the currently authenticated user.
 */
export const buildPages = () => {
  if (!auth.user) {
    return []
  }
  if (auth.user === APP_USERS.PALADIN) {
    return PALADIN_PAGES
  }
  return ALL_PAGES
}

/**
 * @returns The home route for the currently authenticated user.
 */
export const buildHomeRoute = () => {
  if (!auth.user) {
    return ''
  }
  if (auth.user === APP_USERS.PALADIN) {
    return '/bribe-platforms'
  }
  return '/assets'
}

/**
 * @returns The navigation items accessible by the currently authenticated user.
 */
export const buildNavigationItems = () => {
  if (!auth.user) {
    return []
  }
  if (auth.user === APP_USERS.PALADIN) {
    return PALADIN_NAVIGATION_ITEMS
  }
  return ALL_NAVIGATION_ITEMS
}
