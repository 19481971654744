// TODO: Remove
/* eslint-disable no-unused-vars */

// API Service
import Api from 'services/Api'

/**
 * Edits the governance by the given vote token.
 * @param {string} voteToken - Vote token name.
 * @param {Record<string, any>} data - Update data.
 * @returns Response.
 */
export const editBribeGovernance = async (voteToken, data) => {
  try {
    // TODO: Uncomment
    // const response = await Api.post(`/edit_bribe_governance/${voteToken}`, data)
    return {}
  } catch (error) {
    return { error }
  }
}
