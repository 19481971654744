import { useState } from 'react'
import debounce from 'lodash.debounce'

export default function useSearch(cb) {
  const [search, setSearch] = useState('')

  const onSearch = debounce((e) => {
    setSearch(e)
    cb && cb()
  }, 400)

  return {
    search,
    onSearch
  }
}
