import axios from 'axios'
import { API_URL } from 'config'
import handleError from 'services/handleError'
import { auth } from 'services/auth'

class Api {
  constructor() {
    this.instance = axios.create({
      baseURL: API_URL
    })

    this.instance.interceptors.request.use((req) => {
      if (auth.token) {
        req.headers.Authorization = auth.token
      }
      return req
    })
  }

  get(endpoint, params = {}, config = {}) {
    return this.instance
      .get(endpoint, {
        ...config,
        params
      })
      .catch((error) => handleError(error, config))
  }

  post(endpoint, data = {}, config) {
    return this.instance
      .post(endpoint, data, config)
      .catch((error) => handleError(error, config))
  }

  put(endpoint, data = {}, config) {
    return this.instance
      .put(endpoint, data, config)
      .catch((error) => handleError(error, config))
  }

  delete(endpoint, config) {
    return this.instance
      .delete(endpoint, config)
      .catch((error) => handleError(error, config))
  }

  formData(config) {
    return this.instance({
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default new Api()
