import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import useLandingContentUi from 'hooks/useLandingContentUi'
import useSingleCareer from 'hooks/useSingleCareer'
import Select from 'components/form/Select'
import { CAREER_LOCATION_OPTIONS, CAREER_TYPE_OPTIONS } from 'utils/careers'
import { createOption } from 'utils/form'

const InputLabelProps = {
  disableAnimation: true,
  shrink: true
}

const useStyles = makeStyles({
  header: {
    padding: '1rem'
  },
  body: {
    padding: '1rem'
  },
  title: {
    margin: '2rem 0'
  },
  submitContainer: {
    margin: '2rem 0',
    padding: '1rem 0',
    borderTop: '1px solid  rgba(0, 0, 0, 0.25)',

    '& > button': {
      marginRight: '1rem'
    }
  },
  empty: {
    marginBottom: '2rem'
  }
})

export default function AddCareer() {
  const classes = useStyles()
  const history = useHistory()
  const { control, setValue, watch, handleSubmit, register } = useForm({
    defaultValues: {
      type: 'positions'
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content'
  })

  const { editId, renderContentRow } = useLandingContentUi({
    setValue,
    remove,
    classes
  })

  const { onAdd, singleCareer } = useSingleCareer({ id: editId })

  useEffect(() => {
    if (singleCareer['career_id']) {
      setValue('career_id', singleCareer['career_id'])
      setValue('date_added', singleCareer['date_added'])
      setValue('title', singleCareer['title'])
      setValue('type', 'positions')
      setValue('location', singleCareer['location'])
      setValue('content', singleCareer['content'])
    }
  }, [singleCareer['career_id']])

  const watchFields = watch('content') || []

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFields[index]
  }))

  const handleSave = (values) => {
    onAdd(values)
  }

  return (
    <Grid container direction="column">
      <Grid container className={classes.header}>
        <Typography variant="h5">
          {editId ? 'Edit career' : 'Add career'}
        </Typography>
      </Grid>

      <Grid container direction="column" className={classes.body}>
        <TextField
          label="Title"
          placeholder="Title"
          variant="outlined"
          margin="normal"
          InputLabelProps={InputLabelProps}
          size="small"
          {...register('title')}
        />

        <Controller
          name="type"
          control={control}
          render={({ field: { name } }) => (
            <Select
              name="Type"
              margin="normal"
              size="small"
              filterOptions={(options) => options}
              InputLabelProps={InputLabelProps}
              options={CAREER_TYPE_OPTIONS}
              value={createOption('Positions', 'positions')}
              onChange={(e, o) => setValue(name, o?.value)}
              disabled
            />
          )}
        />

        <Controller
          name="location"
          control={control}
          render={({ field: { value, name } }) => (
            <Select
              name="Location"
              filterOptions={(options) => options}
              InputLabelProps={InputLabelProps}
              options={CAREER_LOCATION_OPTIONS}
              value={
                CAREER_LOCATION_OPTIONS.find((o) => o.value === value) || ''
              }
              onChange={(e, o) => setValue(name, o?.value)}
            />
          )}
        />

        <Typography variant="h6" className={classes.title}>
          Content
        </Typography>

        <Grid container direction="column">
          {controlledFields.length ? (
            controlledFields.map(renderContentRow)
          ) : (
            <Typography className={classes.empty}>
              No Content blocks yet...
            </Typography>
          )}

          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => append({ type: '', content: '' })}
            >
              Add Content Block
            </Button>
          </div>

          <div className={classes.submitContainer}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSubmit(handleSave)()}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => history.push('/landing/careers')}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
