import { Grid, makeStyles, Typography } from '@material-ui/core'

// Utils
import { formatMoney, formatPercent } from 'utils/format'

const useStyles = makeStyles({
  sectionTitle: {
    fontWeight: 500,
    padding: '20px 0',
    color: '#3f51b5'
  },
  platformContainer: {
    padding: '1rem 0',
    gap: '20px'
  },
  row: {
    flexFlow: 'row'
  }
})

export const BribePlatformOverview = ({ platform }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.platformContainer}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h4" className={classes.sectionTitle}>
        Platform Overview
      </Typography>
      <Grid
        container
        className={classes.row}
        direction="row"
        justifyContent="space-between"
      >
        <Grid container direction="column">
          <Typography variant="h6">Blockchain:</Typography>
          <Typography>{platform.blockchain || '/'}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">TVL:</Typography>
          <Typography>{formatMoney(platform.tvl_staked)}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Yield Type:</Typography>
          <Typography>{platform.yield_type || '/'}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Audit Link:</Typography>
          <Typography>{platform.yield_type || '/'}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.row}
        direction="row"
        justifyContent="space-between"
      >
        <Grid container direction="column">
          <Typography variant="h6">Vault APY:</Typography>
          <Typography>{formatPercent(platform.vault_apy)}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Deposit Fee:</Typography>
          <Typography>{formatPercent(platform.deposit_fee)}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Withdrawal Fee:</Typography>
          <Typography>{formatPercent(platform.withdrawal_fee)}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Performance Fee:</Typography>
          <Typography>{formatPercent(platform.performance_fee)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
