import { addAsset, updateAssetDetails } from 'services/api/assets'
import { formatAssetData } from 'utils/api'
import { useState } from 'react'
import { mutate } from 'swr'
import { getKey } from 'hooks/useAssetDetails'
import { toast } from 'react-toastify'

export default function useUpdateAsset() {
  const [isLoading, setLoading] = useState(false)

  const onSaveAsset = async (id, fields, onSuccess) => {
    try {
      setLoading(true)
      id
        ? await updateAssetDetails(id, formatAssetData(fields))
        : await addAsset(formatAssetData(fields))

      id && mutate(getKey({ assetId: id }))

      toast.success(
        id ? 'Asset updated successfully' : 'Asset added successfully'
      )

      setLoading(false)
      onSuccess?.(id)
    } catch (e) {
      setLoading(false)

      e.message && toast.error(e.message)

      console.error(e)
    }
  }

  return {
    isLoading,
    onSaveAsset
  }
}
