import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import { useState } from 'react'
import { login } from 'services/api/auth'
import { useHistory } from 'react-router-dom'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  form: {
    padding: '1rem 0'
  },
  input: {
    marginBottom: '1rem'
  },
  card: {
    width: '100%',
    maxWidth: 500,
    padding: '2rem',
    boxShadow: theme.shadows[2]
  },
  title: {
    fontWeight: 700
  }
}))

export default function Login() {
  const classes = useStyles()
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const history = useHistory()
  const [showPass, setShowPass] = useState(false)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      await login(user, pass)
      history.push('/assets')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Card className={classes.card}>
        <Typography variant="h4" align="center">
          CMS
        </Typography>

        <Grid
          container
          direction="column"
          className={classes.form}
          component="form"
          onSubmit={handleSubmit}
        >
          <TextField
            variant="outlined"
            label="User"
            className={classes.input}
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Password"
            className={classes.input}
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            type={showPass ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass((prevState) => !prevState)}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
          >
            Login
          </Button>
        </Grid>
      </Card>
    </Grid>
  )
}
