import { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

// Components
import Header from 'components/Header'

// Services
import { auth } from 'services/auth'

// Utils
import { buildHomeRoute, buildPages } from 'common/utils/app.utils'

const useStyles = makeStyles({
  root: {
    height: '100vh'
  },
  content: {
    paddingTop: 50,
    minHeight: '100%',
    flex: 1
  }
})

export default function AuthorizedApp() {
  const classes = useStyles()

  if (!auth.token) {
    return <Redirect to="/login" />
  }

  const pages = useMemo(() => buildPages(), [])
  const homeRoute = useMemo(() => buildHomeRoute(), [])

  return (
    <Grid container direction="column" className={classes.root}>
      <Header />

      <Grid container direction="column" className={classes.content}>
        <Switch>
          {pages.map((page, index) => (
            <Route key={index} path={page.path} exact={page.exact}>
              <page.Component />
            </Route>
          ))}

          <Route path="/">
            <Redirect to={homeRoute} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  )
}
