import { createContext, useContext } from 'react'

const LeaderboardsContext = createContext({
  mutate: () => {}
})

export default function LeaderboardsProvider({ children, ...props }) {
  return (
    <LeaderboardsContext.Provider value={props}>
      {children}
    </LeaderboardsContext.Provider>
  )
}

export function useLeaderboardsContext() {
  return useContext(LeaderboardsContext)
}
