import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  selectPaper: {
    boxShadow: theme.shadows[5]
  }
}))

export default function Select({
  name,
  InputLabelProps,
  size,
  fullWidth = true,
  margin = 'normal',
  label,
  ...props
}) {
  const classes = useStyles()
  return (
    <Autocomplete
      fullWidth={fullWidth}
      getOptionLabel={(o) => o.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || name}
          variant="outlined"
          size={size || 'small'}
          margin={margin === false ? undefined : margin}
          InputLabelProps={InputLabelProps}
          fullWidth={fullWidth}
        />
      )}
      classes={{ paper: classes.selectPaper }}
      {...props}
    />
  )
}
