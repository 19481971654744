import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import useSingleBlog from 'hooks/useSingleBlog'
import Dropzone from 'pages/landing/addBlog/Dropzone'
import { useEffect } from 'react'
import useLandingContentUi from 'hooks/useLandingContentUi'

const InputLabelProps = {
  disableAnimation: true,
  shrink: true
}

const useStyles = makeStyles({
  header: {
    padding: '1rem'
  },
  body: {
    padding: '1rem'
  },
  title: {
    margin: '2rem 0'
  },
  submitContainer: {
    margin: '2rem 0',
    padding: '1rem 0',
    borderTop: '1px solid  rgba(0, 0, 0, 0.25)',

    '& > button': {
      marginRight: '1rem'
    }
  },
  empty: {
    marginBottom: '2rem'
  }
})

export default function AddBlog() {
  const classes = useStyles()
  const history = useHistory()
  const { control, setValue, watch, handleSubmit, register } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'content'
  })

  const { editId, renderContentRow } = useLandingContentUi({
    setValue,
    remove,
    classes
  })

  const { onAdd, singleBlog } = useSingleBlog({ id: editId })

  useEffect(() => {
    if (singleBlog['blog_id']) {
      setValue('blog_id', singleBlog['blog_id'])
      setValue('date_added', singleBlog['date_added'])
      setValue('preview_image', singleBlog['preview_image'])
      setValue('title', singleBlog['title'])
      setValue('preview_description', singleBlog['preview_description'])
      setValue('content', singleBlog['content'])
    }
  }, [singleBlog['blog_id']])

  const watchFields = watch('content') || []

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFields[index]
  }))

  const handleSave = (values) => {
    onAdd(values)
  }

  return (
    <Grid container direction="column">
      <Grid container className={classes.header}>
        <Typography variant="h5">
          {editId ? 'Edit Blog' : 'Add blog'}
        </Typography>
      </Grid>

      <Grid container direction="column" className={classes.body}>
        <TextField
          label="Title"
          placeholder="Title"
          variant="outlined"
          margin="normal"
          size="small"
          InputLabelProps={InputLabelProps}
          {...register('title')}
        />

        <TextField
          multiline
          label="Preview Description"
          placeholder="Description"
          variant="outlined"
          size="small"
          margin="normal"
          InputLabelProps={InputLabelProps}
          {...register('preview_description')}
        />

        <Controller
          name="preview_image"
          control={control}
          render={({ field: { value } }) => (
            <Dropzone
              title="Select preview picture"
              value={value}
              onChange={(file, name) =>
                setValue('preview_image', { file, name })
              }
            />
          )}
        />

        <Typography variant="h6" className={classes.title}>
          Content
        </Typography>

        <Grid container direction="column">
          {controlledFields.length ? (
            controlledFields.map(renderContentRow)
          ) : (
            <Typography className={classes.empty}>
              No Content blocks yet...
            </Typography>
          )}

          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => append({ type: '', content: '' })}
            >
              Add Content Block
            </Button>
          </div>

          <div className={classes.submitContainer}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSubmit(handleSave)()}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => history.push('/landing/blog')}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
