import useSWR from 'swr'
import { addAsset, getAssetsList, removeAsset } from 'services/api/assets'
import { stringifyURL } from 'utils/url'
import useSearch from 'hooks/useSearch'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { omitEmpty } from 'utils/obj'
import { INITIAL_ASSETS_FILTERS } from 'utils/filters'
import { formatDuplicateAsset } from 'utils/api'

function getKey(params) {
  return stringifyURL('/get_assets', params)
}

export default function useAssets(config = {}) {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState(INITIAL_ASSETS_FILTERS)

  const searchCallback = () => setPage(1)

  const { search, onSearch } = useSearch(searchCallback)

  const params = {
    pg: page,
    search,
    sort_for_cms: true,
    ...omitEmpty(filters),
    ...(config.farmIds && {
      farm_ids: config.farmIds
    })
  }

  const { data, error, mutate } = useSWR(() => getKey(params), getAssetsList)

  const onRemoveAsset = async (id) => {
    try {
      await removeAsset(id)
      mutate()
      toast.success('Asset removed successfully')
    } catch (e) {
      console.error(e)
    }
  }

  const onDuplicate = async (asset) => {
    try {
      await addAsset(formatDuplicateAsset(asset))
      mutate()
      toast.success('Asset duplicated successfully')
    } catch (e) {
      console.error(e)
    }
  }

  const onFilters = (filters) => {
    setFilters(filters)
    setPage(1)
  }

  const isLoading = !data && !error
  const assets = data?.data || []
  const max = data?.['max_pages'] || 0
  return {
    isLoading,
    assets,
    max,
    onSearch,
    page,
    onPage: setPage,
    mutate,
    onDuplicate,
    onRemoveAsset,
    filters,
    onFilters
  }
}
