import useSWR from 'swr'
import { getAnnouncement, updateAnnouncement } from 'services/api/settings'
import { toast } from 'react-toastify'
import { useState } from 'react'

export default function useAnnouncement() {
  const { data, error, mutate } = useSWR('/edit_globals', getAnnouncement)
  const [isUpdateLoading, setUpdateLoading] = useState(false)

  const onUpdateAnnouncement = async (value) => {
    try {
      setUpdateLoading(true)
      await updateAnnouncement(value)
      toast.success('Announcement updated successfully')
      mutate()
      setUpdateLoading(false)
    } catch (e) {
      setUpdateLoading(false)
      console.error(e)
    }
  }

  const isLoading = !data && !error
  const announcement = data || {}
  return {
    isLoading,
    announcement,
    isUpdateLoading,
    onUpdateAnnouncement
  }
}
