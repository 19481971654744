import Api from 'services/Api'

export async function getCareersContents(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getSingleCareer(url) {
  const response = await Api.get(url)
  return response.data
}

export async function addCareer(data) {
  const response = await Api.post('/add_career', data)
  return response.data
}

export async function removeCareer(id) {
  const response = await Api.post(`/delete_career?career_id=${id}`)
  return response.data
}
