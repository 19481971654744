import useSWR from 'swr'
import { getLeaderboards } from 'services/api/leaderboards'
import useLeaderboard from 'hooks/useLeaderboard'
import { stringifyURL } from 'utils/url'

function getKey(params) {
  return stringifyURL('/get_leaderboards_cms', params)
}

export default function useLeaderboards() {
  const params = {
    page: 1,
    per_page: 25,
    active: true
  }

  const { data, error, mutate } = useSWR(() => getKey(params), getLeaderboards)

  const leaderboard = useLeaderboard({ mutate })

  const isLoading = !data && !error
  const leaderboards = data?.leaderboards || []
  return {
    mutate,
    isLoading,
    leaderboards,
    ...leaderboard
  }
}
