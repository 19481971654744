import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import GroupCard from 'components/cards/GroupCard'
import AddGroupDialog from 'components/dialogs/AddGroupDialog'
import { Link, useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import useGroups from 'hooks/useGroups'
import ConfirmDialog from 'components/dialogs/ConfirmDialog'
import { useState } from 'react'

const useStyles = makeStyles({
  root: {
    padding: '2rem'
  },
  titleContainer: {
    marginBottom: '2rem'
  },
  cardsContainer: {
    width: 'auto',
    margin: '-1rem'
  }
})

export default function Groups() {
  const classes = useStyles()
  const params = useParams()
  const { groups, onUpdate } = useGroups()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const handleDeleteConfirm = () => {
    const copy = { ...groups }
    delete copy[deleteId]
    onUpdate(copy)
  }

  const handleDelete = (id) => {
    setDeleteId(id)
    setConfirmDelete(true)
  }

  return (
    <>
      <Grid container direction="column" className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid>
            <Typography variant="h5">Coin Groups</Typography>
          </Grid>

          <Grid>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/groups/add"
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid container wrap="wrap" className={classes.cardsContainer}>
          {Object.keys(groups).map((group) => (
            <GroupCard
              key={group}
              editTo={`/groups/edit/${group}`}
              group={groups[group]}
              onDelete={() => handleDelete(group)}
            />
          ))}
        </Grid>
      </Grid>
      <AddGroupDialog
        open={!!params.action}
        editId={params.id}
        closeTo="/groups"
      />
      <ConfirmDialog
        title="Delete group"
        message="Are you sure you want to delete group?"
        onConfirm={handleDeleteConfirm}
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      />
    </>
  )
}
