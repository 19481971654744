import { useState, useMemo, useEffect } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

// Components
import TablePageContainer from 'components/TablePageContainer'
import Button from 'components/buttons/Buttons'
import { EditBribeGaugeDrawer } from 'components/EditBribeGaugeDrawer'
import Select from 'components/form/Select'

// Hooks - API
import { useBribeGauges } from 'hooks/useBribeGauges'
import { useVoteTokens } from 'hooks/useVoteTokens'

// Utils
import { formatPercent } from 'utils/format'

export const BribeGauges = () => {
  const [voteToken, setVoteToken] = useState(undefined)
  const [activeGauge, setActiveGauge] = useState(undefined)

  const { voteTokens } = useVoteTokens()
  const { gauges, maxPages, filters, onPage, mutate } =
    useBribeGauges(voteToken)

  /**
   * A hook to initialize the first vote token.
   */
  useEffect(() => {
    if (voteToken || !voteTokens || voteTokens.length === 0) {
      return
    }
    setVoteToken(voteTokens[0])
  }, [voteTokens])

  /**
   * Memorising the vote token options.
   */
  const voteTokenOptions = useMemo(() => {
    return voteTokens.map((voteToken) => ({
      label: voteToken,
      value: voteToken
    }))
  }, [voteTokens])

  /**
   * Memorising table columns.
   */
  const COLS = useMemo(
    () => [
      {
        name: 'gauge_name',
        label: 'Name',
        render: (row) => row.gauge_name || '-'
      },
      {
        name: 'protocol',
        label: 'Protocol',
        render: (row) => row.protocol || '-'
      },
      {
        name: 'platform',
        label: 'Platform',
        render: (row) => row.platform || '-'
      },
      {
        name: 'value_per_vote',
        label: 'Value per vote',
        render: (row) =>
          `$${row.value_per_vote}/${row.vote_token_symbol}` || '-'
      },
      {
        name: 'average_apr',
        label: 'APR',
        render: (row) => formatPercent(row.average_apr)
      },
      {
        name: 'edit_gauge',
        label: (
          <Select
            name="Vote token"
            options={voteTokenOptions}
            value={
              voteTokenOptions.find((option) => option.value === voteToken) ||
              ''
            }
            onChange={(e, value) => setVoteToken(value.value)}
          />
        ),
        render: (row) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveGauge(row)
              }}
            >
              <EditIcon />
            </Button>
          )
        }
      }
    ],
    [setActiveGauge, voteTokenOptions, setVoteToken, voteToken]
  )

  return (
    <>
      <TablePageContainer
        cols={COLS}
        maxPages={maxPages}
        page={filters.page}
        onPageChange={onPage}
      >
        {gauges.map((gauge, index) => (
          <TableRow key={`${gauge.id}-${index}`}>
            {COLS.map((col) => (
              <TableCell key={col.name}>{col.render(gauge)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TablePageContainer>
      <EditBribeGaugeDrawer
        open={!!activeGauge}
        onClose={() => setActiveGauge(undefined)}
        gauge={activeGauge}
        onSuccess={() => {
          mutate()
        }}
      />
    </>
  )
}
