import MuiTableCell from '@material-ui/core/TableCell'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: (props) => ({
    width: props.width
  })
})

export default function TableCell(props) {
  const classes = useStyles(props)
  return <MuiTableCell className={classes.root} {...props} />
}
