import { useState } from 'react'
import debounce from 'lodash.debounce'

export default function useValues(config = {}) {
  const [values, setValues] = useState({
    ...(config.currentDate && {
      dateAdded: {
        value: new Date()
      },
      dateUpdated: {
        value: new Date()
      }
    })
  })

  const onLock = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        locked: value
      }
    }))
  }

  const onChangeDebounce = debounce((key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: prevState[key]
        ? {
            ...prevState[key],
            value
          }
        : {
            value,
            locked: true
          }
    }))
  }, 400)

  const onChange = (key, value) => {
    setValues((prevState) => ({
      ...prevState,
      [key]: prevState[key]
        ? { ...prevState[key], value }
        : { value, locked: true }
    }))
  }

  return {
    values,
    onChange,
    onLock,
    onChangeDebounce
  }
}
