import useSWR from 'swr'
import { stringify } from 'query-string'
import { useState } from 'react'
import useSearch from 'hooks/useSearch'
import { duplicateFarm, getFarms, removeFarm } from 'services/api/farms'
import { toast } from 'react-toastify'
import { INITIAL_FARMS_FILTERS } from 'utils/filters'
import { formatDuplicateFarm } from 'utils/api'
import { omitEmpty } from 'utils/obj'

function getKey(params) {
  return '/get_farms' + '?' + stringify(omitEmpty(params))
}

export default function useFarms() {
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState(INITIAL_FARMS_FILTERS)

  const searchCallback = () => setPage(1)

  const { search, onSearch } = useSearch(searchCallback)

  const params = {
    page,
    search,
    sort_for_cms: true,
    ...filters
  }

  const { data, error, mutate } = useSWR(() => getKey(params), getFarms)

  const onRemoveFarm = async (id) => {
    try {
      await removeFarm(id)
      mutate()
      toast.success('Farm removed successfully')
    } catch (e) {
      console.error(e)
    }
  }

  const onDuplicate = async (farm) => {
    try {
      await duplicateFarm(formatDuplicateFarm(farm))
      mutate()
      toast.success('Farm duplicated successfully')
    } catch (e) {
      console.error(e)
    }
  }

  const onFilters = (filters) => {
    setFilters(filters)
    setPage(1)
  }

  const isLoading = !data && !error
  const farms = data?.data || []
  const max = data?.['max_pages'] || 0
  return {
    isLoading,
    farms,
    max,
    onSearch,
    onPage: setPage,
    page,
    mutate,
    onRemoveFarm,
    onFilters,
    filters,
    onDuplicate
  }
}
