import { useState } from 'react'

// Hooks - API
import { BribePlatformsTable } from '../components/bribe-platforms/BribePlatformsTable'
import { BribePlatform } from '../components/bribe-platforms/BribePlatform'

export const BribePlatforms = () => {
  const [activePlatform, setActivePlatform] = useState(undefined)

  return !activePlatform ? (
    <BribePlatformsTable setActivePlatform={setActivePlatform} />
  ) : (
    <BribePlatform
      platform={activePlatform}
      setActivePlatform={setActivePlatform}
    />
  )
}
