import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  BLOCKCHAIN_OPTIONS,
  BOOL_FIELDS,
  DATA_QUALITY_OPTIONS,
  TOOLTIP_LOCK
} from 'utils/forms'
import IconButton from '@material-ui/core/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Tooltip from '@material-ui/core/Tooltip'
import useValues from 'hooks/useValues'
import SelectBoolean from 'components/form/SelectBoolean'
import Select from 'components/form/Select'
import useUpdateFarmAssets from 'hooks/useUpdateFarmAssets'
import { useParams } from 'react-router-dom'
import { SCRAPE_SOURCE_STATUSES } from 'utils/filters'

const useStyles = makeStyles({
  root: {
    minWidth: 350
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  }
})

export default function EditColDialog({ open, onClose, field, mutate }) {
  const classes = useStyles()
  const { values, onLock, onChange } = useValues()
  const { isLoading, onUpdateFarmAssets } = useUpdateFarmAssets()
  const params = useParams()

  const onSuccess = () => {
    mutate()
    onClose()
  }

  const handleSubmit = () => {
    onUpdateFarmAssets(params.id, values, onSuccess)
  }

  const lockValue = !!values[field]?.locked
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Apply "${field}" for all assets`}</DialogTitle>

      <DialogContent>
        <Grid container alignItems="center" className={classes.root}>
          {BOOL_FIELDS.includes(field) ? (
            <SelectBoolean
              defaultValue={null}
              onChange={(e, o) => onChange(field, o.value)}
              className={classes.input}
              name={field}
            />
          ) : field === 'blockchain' ? (
            <Select
              name={field}
              options={BLOCKCHAIN_OPTIONS}
              className={classes.input}
              onChange={(e, o) => onChange(field, o)}
              defaultValue={null}
            />
          ) : field === 'source' ? (
            <Select
              name={field}
              options={SCRAPE_SOURCE_STATUSES}
              className={classes.input}
              onChange={(e, o) => onChange(field, o)}
              defaultValue={null}
            />
          ) : field === 'dataQuality' ? (
            <Select
              name={field}
              options={DATA_QUALITY_OPTIONS}
              className={classes.input}
              onChange={(e, o) => onChange(field, o)}
              defaultValue={null}
            />
          ) : (
            <TextField
              className={classes.input}
              label={field}
              variant="outlined"
              size="small"
              defaultValue=""
              onChange={(e) => onChange(field, e.target.value)}
            />
          )}

          <Tooltip title={TOOLTIP_LOCK}>
            <IconButton onClick={() => onLock(field, !lockValue)}>
              {lockValue ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="default">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditColDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  field: PropTypes.string,
  onConfirm: PropTypes.func,
  mutate: PropTypes.func
}
