export const CONTENT_TYPES = [
  { value: 'h2', label: 'Heading H2' },
  { value: 'h3', label: 'Heading H3' },
  { value: 'text', label: 'Text' },
  { value: 'list', label: 'List' },
  { value: 'image', label: 'Image' }
]

export function convertTitleToId(title = '') {
  return Date.now() + '-' + title.toLowerCase().replace(/[^a-zA-Z1-9]/g, '-')
}
