import Select from 'components/form/Select'
import PropTypes from 'prop-types'
import { BOOL_OPTIONS } from 'utils/forms'

export default function SelectBoolean(props) {
  const { defaultValue } = props
  const selectedValue = BOOL_OPTIONS.find((o) => o.value === defaultValue)
  return (
    <Select
      {...props}
      defaultValue={defaultValue ? selectedValue : undefined}
      value={defaultValue ? undefined : selectedValue}
      options={BOOL_OPTIONS}
    />
  )
}

SelectBoolean.propTypes = {
  defaultValue: PropTypes.bool
}
