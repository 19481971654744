import _format from 'date-fns/format'

export const DATE_FORMAT = 'dd/MM/yyyy'

export function isValidDate(date) {
  return date instanceof Date && !isNaN(date)
}

export function toDate(date) {
  if (isValidDate(new Date(date))) {
    return new Date(date)
  }
  return ''
}

export function format(date, format) {
  try {
    if (!date) {
      return ''
    }
    return _format(date, format)
  } catch (e) {
    return ''
  }
}
