import TablePageContainer from 'components/TablePageContainer'
import useReportedErrors from 'hooks/useReportedErrors'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import { DATE_FORMAT, format, toDate } from 'utils/date'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import AddAssetDrawer from 'components/AddAssetDrawer'
import { useState } from 'react'
import AddFarmDrawer from 'components/AddFarmDrawer'
import AlarmIcon from '@material-ui/icons/Alarm'
import useReportedError from 'hooks/useReportedError'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles({
  checkboxCell: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginRight: '1.25rem',

    '&:last-child': {
      marginRight: 0
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end'
  }
})

const COLS = [
  { name: 'fixed', label: 'Fixed' },
  { name: 'item_type', label: 'Type' },
  { name: 'item_id', label: 'ID' },
  { name: 'date_added', label: 'Date Added' },
  { name: 'message', label: 'Message' },
  { name: 'user', label: 'User' },
  { name: '', label: '', align: 'right' }
]

export default function Errors() {
  const classes = useStyles()
  const { errors, isLoading, revalidate, maxPages, filters, onPage } =
    useReportedErrors()
  const [editAsset, setEditAsset] = useState(null)
  const [editAssetsMost, setEditAssetsMost] = useState(null)
  const [editFarm, setEditFarm] = useState(null)
  const [selectedError, setSelectedError] = useState('')

  const reportedError = useReportedError({ revalidate })

  return (
    <>
      <TablePageContainer
        page={filters.page}
        maxPages={maxPages}
        cols={COLS}
        loading={isLoading}
        onPageChange={onPage}
      >
        {errors.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Grid className={classes.checkboxCell}>
                <Checkbox
                  checked={row['fixed']}
                  onChange={() =>
                    reportedError.onEdit({ ...row, fixed: !row['fixed'] })
                  }
                />
                {row['fixed'] ? 'Yes' : 'No'}
              </Grid>
            </TableCell>
            <TableCell>{row['item_type']}</TableCell>
            <TableCell>{row['item_id']}</TableCell>
            <TableCell>
              {format(toDate(row['date_added']), DATE_FORMAT) || 'N/A'}
            </TableCell>
            <TableCell>
              <Typography style={{ wordBreak: 'break-all' }}>
                {row['message']}
              </Typography>
            </TableCell>
            <TableCell>{row['user']}</TableCell>

            <TableCell align="right">
              <Grid className={classes.actions}>
                {row['item_type'] === 'asset' && (
                  <Tooltip title="Most used fields">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEditAssetsMost(row['item_id'])
                        setSelectedError(row['message'])
                      }}
                      className={classes.button}
                    >
                      <AlarmIcon />
                    </Button>
                  </Tooltip>
                )}

                <Tooltip
                  title={
                    row['item_type'] === 'asset' ? 'Edit Asset' : 'Edit Farm'
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      row['item_type'] === 'asset' &&
                        setEditAsset(row['item_id'])
                      row['item_type'] === 'farm' && setEditFarm(row['item_id'])
                    }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TablePageContainer>

      <AddAssetDrawer
        edit={editAssetsMost || editAsset}
        open={!!(editAsset || editAssetsMost)}
        mostUsedFields={editAssetsMost}
        onClose={() => {
          setEditAsset(null)
          setEditAssetsMost(null)
          setSelectedError('')
        }}
        customComponent={
          editAssetsMost && (
            <Grid container>
              <TextField
                multiline
                fullWidth
                margin="normal"
                label="Message"
                variant="outlined"
                rows={10}
                InputLabelProps={{
                  disableAnimation: true,
                  shrink: true
                }}
                placeholder="Content"
                value={selectedError || ''}
                onChange={() => {}}
              />
            </Grid>
          )
        }
      />

      <AddFarmDrawer
        edit={editFarm}
        open={!!editFarm}
        onClose={() => setEditFarm(null)}
      />
    </>
  )
}
