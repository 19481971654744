export const auth = {
  token: process.env.REACT_APP_AUTH || null,
  user: '',
  color: ''
}

export function setToken(token, user, color) {
  auth.token = token
  auth.user = user
  auth.color = color
}

export function getToken(username, password) {
  return `Bearer ${window.btoa(`${username}:${password}`)}`
}
