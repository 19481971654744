const ASSETS_NAVIGATION_ITEM = {
  title: 'Assets',
  href: '/assets',
  deps: ['assets']
}

const FARMS_NAVIGATION_ITEM = {
  title: 'Farms',
  href: '/farms',
  deps: ['farms']
}
const GROUPS_NAVIGATION_ITEM = {
  title: 'Groups',
  href: '/groups',
  deps: ['groups']
}

const BRIDGES_NAVIGATION_ITEM = {
  title: 'Bridges',
  href: '/bridges',
  deps: ['bridges']
}

const LANDING_NAVIGATION_ITEM = {
  title: 'Landing',
  href: '/landing',
  deps: ['landing']
}

const SETTINGS_NAVIGATION_ITEM = {
  title: 'Settings',
  href: '/settings',
  deps: ['settings']
}

const LEADERBOARDS_NAVIGATION_ITEM = {
  title: 'Leaderboards',
  href: '/leaderboards',
  deps: ['leaderboards']
}

const ERRORS_NAVIGATION_ITEM = {
  title: 'Reported Errors',
  href: '/errors',
  deps: ['errors']
}

const PDFS_NAVIGATION_ITEM = { title: 'PDFs', href: '/pdfs', deps: ['pdfs'] }

const WAITLIST_NAVIGATION_ITEM = {
  title: 'Waitlist',
  href: '/waitlist',
  deps: ['waitlist']
}

const USERS_NAVIGATION_ITEM = {
  title: 'Users',
  href: '/users',
  deps: ['users']
}

const ROLES_NAVIGATION_ITEM = {
  title: 'Roles',
  href: '/roles',
  deps: ['roles']
}

const EXCHANGES_NAVIGATION_ITEM = {
  title: 'Exchanges',
  href: '/exchanges',
  deps: ['exchanges']
}

const BRIBE_PLATROFMS_NAVIGATION_ITEM = {
  title: 'Bribe Platforms',
  href: '/bribe-platforms',
  deps: ['bribe-platforms']
}

const BRIBE_GAUGES_NAVIGATION_ITEM = {
  title: 'Bribe Gauges',
  href: '/bribe-gauges',
  deps: ['bribe-gauges']
}

const BRIBE_GOVERNANCE_NAVIGATION_ITEM = {
  title: 'Bribe Governance',
  href: '/bribe-governance',
  deps: ['bribe-governance']
}

export const ALL_NAVIGATION_ITEMS = [
  ASSETS_NAVIGATION_ITEM,
  FARMS_NAVIGATION_ITEM,
  GROUPS_NAVIGATION_ITEM,
  BRIDGES_NAVIGATION_ITEM,
  LANDING_NAVIGATION_ITEM,
  SETTINGS_NAVIGATION_ITEM,
  LEADERBOARDS_NAVIGATION_ITEM,
  ERRORS_NAVIGATION_ITEM,
  PDFS_NAVIGATION_ITEM,
  WAITLIST_NAVIGATION_ITEM,
  USERS_NAVIGATION_ITEM,
  ROLES_NAVIGATION_ITEM,
  EXCHANGES_NAVIGATION_ITEM,
  BRIBE_PLATROFMS_NAVIGATION_ITEM,
  BRIBE_GAUGES_NAVIGATION_ITEM,
  BRIBE_GOVERNANCE_NAVIGATION_ITEM
]

export const PALADIN_NAVIGATION_ITEMS = [
  BRIBE_PLATROFMS_NAVIGATION_ITEM,
  BRIBE_GAUGES_NAVIGATION_ITEM,
  BRIBE_GOVERNANCE_NAVIGATION_ITEM
]
