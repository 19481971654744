import { editError } from 'services/api/errors'

export default function useReportedError(config = {}) {
  const onEdit = async (data) => {
    try {
      await editError(data)
      console.error('Successfully updated')
      config.revalidate?.()
    } catch (e) {
      console.error('Oops error!')
    }
  }

  return {
    onEdit
  }
}
