import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { fetcher } from 'services/api/common'

function getKey() {
  return stringifyURL('/get_roles', {})
}

export default function useRoles() {
  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(), fetcher)

  const isLoading = !_data && !error
  const data = _data || []

  return {
    isLoading,
    data,
    revalidate
  }
}
