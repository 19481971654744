import { useMemo } from 'react'
import TableCell from '@material-ui/core/TableCell'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: (props) => ({
    width: props.width,
    fontSize: '1.25rem'
  })
})

const BLACKLISTED_PROPS = ['render']

export default function TableHeadCell(props) {
  const cellProps = useMemo(() => {
    const filteredProps = {}
    for (const key of Object.keys(props)) {
      if (BLACKLISTED_PROPS.includes(key)) {
        continue
      }
      filteredProps[key] = props[key]
    }
    return filteredProps
  }, [props])
  const classes = useStyles(props)
  return (
    <TableCell className={clsx(classes.root, props.className)} {...cellProps} />
  )
}
