import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { fetcher } from 'services/api/common'
import { useState } from 'react'

function getKey(params) {
  return stringifyURL('get_premium_users', params)
}

export default function useUsers() {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20
  })

  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(filters), fetcher)

  const onPage = (page) => {
    setFilters((filters) => ({
      ...filters,
      page
    }))
  }

  const isLoading = !_data && !error
  const data = _data?.users || []
  const maxPages = _data?.max_pages || 1

  return {
    isLoading,
    data,
    maxPages,
    revalidate,
    onPage
  }
}
