import Select from 'components/form/Select'
import useRoles from 'hooks/useRoles'
import { rolesToOptions } from 'utils/roles'

export function SelectRoles(props) {
  const roles = useRoles()
  const options = rolesToOptions(roles.data)
  return (
    <Select
      {...props}
      options={options}
      value={options.find((e) => e.value === props.value) || ''}
    />
  )
}
