import Api from 'services/Api'

export async function getBridges(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getBridgesCombinations(url) {
  const response = await Api.get(url)
  return response.data
}

export async function getAllBridgesCombinations(url) {
  const response = await Api.get(url)
  return response.data
}

export async function addBridgeCombination(data) {
  const response = await Api.post('/add_bridge_combination', data)
  return response.data
}

export async function deleteBridgeCombination(name) {
  const response = await Api.post('/delete_bridge_combination', {
    bridgeName: name
  })
  return response.data
}
