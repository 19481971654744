import { toast } from 'react-toastify'
import { addRole } from 'services/api/roles'
import { formatForm } from 'utils/roles'
import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { fetcher } from 'services/api/common'

function getKey(params) {
  return params.name ? stringifyURL('get_role', params) : null
}

export default function useRole(config = {}) {
  const params = {
    name: config.name
  }

  const {
    data: _data,
    error,
    mutate: revalidate
  } = useSWR(() => getKey(params), fetcher)

  const onAdd = async (form, onSuccess) => {
    try {
      await addRole(formatForm(form))
      toast.success(config.name ? 'Role updated' : 'Role added')
      revalidate?.()
      onSuccess?.()
    } catch (e) {
      toast.error('Oops error!')
      console.error(e)
    }
  }

  const isLoading = config.name ? !_data && !error : false
  const data = _data || {}

  return {
    onAdd,
    isLoading,
    data,
    revalidate
  }
}
