import { createOption } from 'utils/form'
import { LEADERBOARD_FIELDS } from 'utils/forms'

export function formatAddData(values) {
  const data = {}

  if (!values.createdDate) {
    data.createdDate = new Date()
  }

  Object.keys(values).forEach((key) => {
    if (['blockchain', 'hot'].includes(key)) {
      data[key] = values[key]?.value
    } else {
      data[key] = values[key]
    }
  })

  return data
}

export function dataToFormValues(data) {
  const formValues = {}

  Object.keys(data)
    .filter((key) => LEADERBOARD_FIELDS.includes(key))
    .forEach((key) => {
      if (['blockchain', 'hot'].includes(key)) {
        formValues[key] =
          data[key] !== null && data[key] !== undefined
            ? createOption(
                key === 'hot'
                  ? data[key] === false
                    ? 'No'
                    : 'Yes'
                  : data[key],
                data[key]
              )
            : null
      } else {
        formValues[key] = data[key]
      }
    })

  return formValues
}
