export function omitEmpty(obj) {
  const copy = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      if (obj[key]) {
        copy[key] = obj[key]
      }
    } else {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== 0) {
        copy[key] = obj[key]
      }
    }
  })

  return copy
}

export function take(obj, keys) {
  return Object.keys(obj).reduce(
    (acc, cur) => ({
      ...(keys.includes(cur) && {
        [cur]: obj[cur]
      })
    }),
    {}
  )
}

export function copy(obj) {
  return { ...obj }
}

export function omit(obj, keys) {
  const copy = { ...obj }

  if (typeof keys === 'string') {
    delete copy[keys]
  } else {
    keys.forEach((key) => {
      delete copy[key]
    })
  }

  return copy
}
