import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    flex: 1,
    position: 'relative'
  },
  absoluteContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  container: {
    overflowY: 'auto',
    padding: '1rem',
    maxHeight: '100%'
  }
})

export default function DrawerContent({ children }) {
  const classes = useStyles()
  return (
    <Grid container direction="column" className={classes.root}>
      <div className={classes.absoluteContainer}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.container}
        >
          {children}
        </Grid>
      </div>
    </Grid>
  )
}

DrawerContent.propTypes = {
  children: PropTypes.node
}
