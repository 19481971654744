import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import useGroups from 'hooks/useGroups'
import { FixedSizeList as List } from 'react-window'
import { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    borderRadius: 0
  },
  head: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '0.25rem 1rem'
  },
  closeBtn: {
    color: '#fff'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500
  },
  footer: {
    backgroundColor: '#fff',
    padding: '1rem 1rem',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  body: {
    height: 500
  },
  btnCancel: {
    marginRight: '1rem'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',

    '&:first-child': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  scrollContainer: {
    position: 'relative',
    flex: 1
  },
  scroll: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  },
  scrollContent: {
    padding: '1rem'
  },
  contentTitle: {
    fontSize: '1rem',
    marginBottom: '1rem',
    fontWeight: 700
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  },
  label: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 1rem'
  },
  autoSize: {
    width: '100%',
    maxWidth: '100%'
  },
  inputContainer: {
    padding: '1rem'
  }
}))

function AddGroupDialogContent({ editId, closeTo }) {
  const classes = useStyles()
  const history = useHistory()
  const { groups, coins, onUpdate } = useGroups()
  const editGroup = groups[editId]

  const [search, setSearch] = useState('')
  const [name, setName] = useState('')
  const [selectedCoins, setSelectedCoins] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])

  useEffect(() => {
    if (editGroup) {
      setSelectedCoins(editGroup?.['includes_coins'])
      setSelectedGroups(editGroup?.['includes_groups'])
      setName(editGroup?.['display_name'])
    }
  }, [editGroup?.['includes_coins'], editGroup?.['includes_groups']])

  const handleSave = async () => {
    const groupId = editId || name.replace(/\s/g, '_') + '_GROUP'

    let error = ''
    Object.keys(groups).forEach((key) => {
      if (key !== groupId && groups[key]['display_name'] === name) {
        error = 'Group name already exists'
      }
    })

    if (error) {
      toast.error(error)
      return
    }

    const newGroup = {
      display_name: name.toUpperCase(),
      includes_groups: selectedGroups,
      includes_coins: selectedCoins
    }

    await onUpdate({
      ...groups,
      [groupId]: newGroup
    })
    history.push(closeTo)
  }

  if (!editGroup && editId) {
    return null
  }

  const onSearch = debounce((e) => {
    setSearch(e.target.value)
  }, 400)

  const handleName = debounce((e) => {
    setName(e.target.value)
  }, 400)

  const coinsArr = Object.keys(coins)
  const filteredCoins = coinsArr.filter((coin) =>
    coin.toLowerCase().startsWith(search.toLowerCase())
  )

  return (
    <>
      <Grid
        container
        className={classes.head}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>
          {editId ? `Edit ${editGroup?.['display_name']}` : 'Add new group'}
        </Typography>

        <span>
          <IconButton
            className={classes.closeBtn}
            component={Link}
            to={closeTo}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </Grid>

      <Grid container className={classes.body}>
        <Grid item xs={7} className={classes.section}>
          <Grid className={classes.scrollContainer}>
            <Grid container className={classes.inputContainer}>
              <TextField
                placeholder="Enter coin name"
                variant="outlined"
                size="small"
                className={classes.input}
                onChange={onSearch}
              />
              <Button variant="contained" size="small" color="primary">
                Add
              </Button>
            </Grid>

            <List
              width={559}
              height={428}
              itemCount={filteredCoins.length}
              itemSize={42}
            >
              {({ index, style }) => (
                <div style={style}>
                  <FormControlLabel
                    key={filteredCoins[index]}
                    className={classes.label}
                    control={
                      <Checkbox
                        name={filteredCoins[index]}
                        checked={selectedCoins.includes(filteredCoins[index])}
                        onChange={(e) =>
                          setSelectedCoins((prevState) =>
                            e.target.checked
                              ? [...prevState, filteredCoins[index]]
                              : prevState.filter(
                                  (c) => c !== filteredCoins[index]
                                )
                          )
                        }
                      />
                    }
                    label={coins[filteredCoins[index]]['names']?.[0] || '-'}
                  />
                </div>
              )}
            </List>
          </Grid>
        </Grid>
        <Grid item xs={5} className={classes.section}>
          <Grid container className={classes.inputContainer}>
            <TextField
              placeholder="Group Name"
              fullWidth
              variant="outlined"
              size="small"
              defaultValue={editGroup?.['display_name'] || ''}
              onChange={handleName}
              disabled={!!editId}
            />
          </Grid>

          <Grid className={classes.scrollContainer}>
            <div className={classes.scroll}>
              <Grid
                container
                direction="column"
                className={classes.scrollContent}
              >
                <Typography className={classes.contentTitle}>
                  INCLUDED GROUPS
                </Typography>

                <Grid container direction="column">
                  {Object.keys(groups)
                    .filter((group) => group !== editId)
                    .map((group) => (
                      <FormControlLabel
                        key={group}
                        control={
                          <Checkbox
                            name={group}
                            checked={selectedGroups.includes(group)}
                            onChange={(e) =>
                              setSelectedGroups((prevState) =>
                                e.target.checked
                                  ? [...prevState, group]
                                  : prevState.filter((c) => c !== group)
                              )
                            }
                          />
                        }
                        label={groups[group]['display_name']}
                      />
                    ))}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classes.footer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.btnCancel}
          component={Link}
          to={closeTo}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </>
  )
}

AddGroupDialogContent.propTypes = {
  closeTo: PropTypes.string,
  editId: PropTypes.string
}

export default function AddGroupDialog({ open, closeTo, editId }) {
  const classes = useStyles()
  return (
    <Dialog open={open} maxWidth="md" classes={{ paper: classes.paper }}>
      <AddGroupDialogContent closeTo={closeTo} editId={editId} />
    </Dialog>
  )
}

AddGroupDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  closeTo: PropTypes.string,
  editId: PropTypes.string
}
