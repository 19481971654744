import useSWR from 'swr'
import { stringifyURL } from 'utils/url'
import { getBlogContents } from 'services/api/blog'
import useSingleBlog from 'hooks/useSingleBlog'

function getKey(params) {
  return stringifyURL('/get_blogs', params)
}

export default function useBlog() {
  const params = {
    page: 1
  }

  const { data, error, mutate } = useSWR(() => getKey(params), getBlogContents)

  const singleBlog = useSingleBlog({ mutate })

  const isLoading = !data && !error
  const blogs = data?.blogs || []

  return {
    isLoading,
    blogs,
    ...singleBlog
  }
}
