import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerActions from 'components/drawer/DrawerActions'
import DrawerContent from 'components/drawer/DrawerContent'
import Button from '@material-ui/core/Button'
import Select from 'components/form/Select'
import {
  EXCHANGES,
  // BOOL_FILTERS,
  INITIAL_ASSETS_FILTERS,
  LOCK_STATUSES,
  SCRAPE_SOURCE_STATUSES,
  SORT_UPDATED_OPTIONS,
  STATUS_FILTERS,
  TVL_MIN_FILTERS
} from 'utils/filters'
import { useState } from 'react'
import PropTypes from 'prop-types'
// import TextField from '@material-ui/core/TextField'

function AssetsFiltersDrawerContent({ onClose, filters, onFilters }) {
  const [innerFilters, setInnerFilters] = useState(filters)

  const handleApply = () => {
    onFilters(innerFilters)
    onClose()
  }

  const handleReset = () => {
    onFilters(INITIAL_ASSETS_FILTERS)
    onClose()
  }

  const handleChange = (name, value) => {
    setInnerFilters((filters) => ({
      ...filters,
      [name]: value
    }))
  }

  return (
    <>
      <DrawerHead title="Apply Filters" onClose={onClose} />

      <DrawerContent>
        <Select
          name="Status"
          options={STATUS_FILTERS}
          value={STATUS_FILTERS.find((o) => o.value === innerFilters.active)}
          onChange={(e, value) => handleChange('active', value?.value || '')}
        />
        <Select
          name="Exchange Name"
          options={EXCHANGES}
          value={EXCHANGES.find((o) => o.value === innerFilters.exchangeName)}
          onChange={(e, value) =>
            handleChange('exchangeName', value?.value || '')
          }
        />
        <Select
          multiple
          name="Scrape Source Status"
          options={SCRAPE_SOURCE_STATUSES}
          value={SCRAPE_SOURCE_STATUSES.filter((o) =>
            innerFilters.scrape_source_status.includes(o.value)
          )}
          onChange={(e, value) =>
            handleChange(
              'scrape_source_status',
              value?.map((o) => o.value) || []
            )
          }
        />
        <Select
          multiple
          name="Lock"
          options={LOCK_STATUSES}
          value={LOCK_STATUSES.filter((o) =>
            innerFilters.lock.includes(o.value)
          )}
          onChange={(e, value) =>
            handleChange('lock', value?.map((o) => o.value) || [])
          }
        />
        <Select
          name="Sort by updated date"
          options={SORT_UPDATED_OPTIONS}
          value={SORT_UPDATED_OPTIONS.find(
            (o) => o.value === innerFilters.sort_by_updated
          )}
          onChange={(e, value) => handleChange('sort_by_updated', value?.value)}
        />
        <Select
          name="Farm TVL min"
          options={TVL_MIN_FILTERS}
          value={TVL_MIN_FILTERS.find(
            (o) => o?.value === innerFilters.farms_tvl_staked_gte
          )}
          onChange={(e, value) =>
            handleChange('farms_tvl_staked_gte', value ? value.value : null)
          }
        />
      </DrawerContent>

      <DrawerActions>
        <Button variant="contained" color="default" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" color="primary" onClick={handleApply}>
          Apply
        </Button>
      </DrawerActions>
    </>
  )
}

AssetsFiltersDrawerContent.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.object,
  onFilters: PropTypes.func
}

export default function AssetsFiltersDrawer({ open, ...props }) {
  return (
    <Drawer open={open}>
      <AssetsFiltersDrawerContent {...props} />
    </Drawer>
  )
}

AssetsFiltersDrawer.propTypes = {
  open: PropTypes.bool
}
