import TablePageContainer from 'components/TablePageContainer'
import AddPdfDrawer from 'components/AddPdfDrawer'
import { useState } from 'react'

const COLS = [
  { name: 'title', label: 'Title' },
  { name: 'active', label: 'Active' },
  { name: 'date', label: 'Date Added' },
  { name: 'actions', label: '', align: 'right' }
]

export default function Pdfs() {
  const [addDrawer, setAddDrawer] = useState(false)
  return (
    <>
      <TablePageContainer
        cols={COLS}
        onAdd={() => setAddDrawer(true)}
      ></TablePageContainer>

      <AddPdfDrawer open={addDrawer} onClose={() => setAddDrawer(false)} />
    </>
  )
}
