import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  sectionTitle: {
    fontWeight: 500,
    padding: '20px 0',
    color: '#3f51b5'
  },
  platformContainer: {
    padding: '1rem 0',
    gap: '20px'
  },
  linksContainer: {
    flexFlow: 'row',
    gap: '20px'
  }
})

export const BribePlatformLinks = ({ platform }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.platformContainer}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h4" className={classes.sectionTitle}>
        Platform links
      </Typography>
      <Grid
        container
        className={classes.linksContainer}
        direction="row"
        justifyContent="space-between"
      >
        <Grid container direction="column">
          <Typography variant="h6">About website:</Typography>
          <Typography>{platform.about_website || '/'}</Typography>
        </Grid>

        <Grid container direction="column">
          <Typography variant="h6">Strategy website:</Typography>
          <Typography>{platform.strategy_website || '/'}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Strategy address:</Typography>
          <Typography>{platform.strategy_address || '/'}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Cover Vault website:</Typography>
          <Typography>{platform.cover_vault_website || '/'}</Typography>
        </Grid>
        <Grid container direction="column">
          <Typography variant="h6">Insure Vault website:</Typography>
          <Typography>{platform.insure_vault_website || '/'}</Typography>
        </Grid>
        <Grid container direction="column" justifyContent="space-between">
          <Typography variant="h6">App Url:</Typography>
          <Typography>{platform.app_url || '/'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
