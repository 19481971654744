import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.25)'
  }
})

export default function LoadingOverlay() {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <CircularProgress color="primary" />
    </Grid>
  )
}
