import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Grid, TextField } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

// Components
import Drawer from 'components/drawer/Drawer'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerContent from 'components/drawer/DrawerContent'
import Button from './buttons/Buttons'

// Hooks
import useValues from 'hooks/useValues'

// Services
import { formatFormData, editBribeGauge } from 'services/api/index'

// Utils
import { BRIBE_GAUGE_FIELDS } from 'utils/forms'

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    height: '100%'
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  },
  actions: {
    padding: '1rem',
    '& button:first-child': {
      marginRight: '1rem'
    }
  },
  button: {
    marginRight: '1rem'
  },
  sectionTitle: {
    paddingTop: 10
  }
}))

const Content = ({ onClose, gauge = {}, onSuccess }) => {
  const classes = useStyles()

  // Form
  const { values, onChange } = useValues(gauge)

  useEffect(() => {
    // Initialising values for the handled fields
    for (const field of BRIBE_GAUGE_FIELDS) {
      onChange(field.key, gauge[field.key])
    }
  }, [])

  const handleSave = async () => {
    const data = formatFormData(values)
    const response = await editBribeGauge(gauge.id, data)
    if (response.error) {
      toast.error('Editting bribe gauge failed.')
      return
    }
    toast.success('Editting bribe gauge was successful.')
    if (onSuccess) {
      onSuccess()
    }
    onClose()
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.content}
    >
      <DrawerHead
        title={`Edit Bribe Gauge ${gauge.gauge_name}`}
        onClose={onClose}
      />

      <DrawerContent>
        {BRIBE_GAUGE_FIELDS.map((field) => {
          return (
            <Grid container alignItems="center" key={field.key}>
              <TextField
                className={classes.input}
                label={field.label}
                variant="outlined"
                value={values[field.key]?.value || ''}
                size="small"
                margin="normal"
                disabled={field.disabled}
                onChange={(e) => onChange(field.key, e.target.value)}
              />
            </Grid>
          )
        })}
      </DrawerContent>

      <Grid container justifyContent="flex-end" className={classes.actions}>
        <Button
          component={Link}
          to={'/bribe-gauges'}
          variant="contained"
          color="default"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export const EditBribeGaugeDrawer = ({ open, onClose, gauge, onSuccess }) => {
  return (
    <Drawer open={open} anchor="right" size={600}>
      <Content onClose={onClose} gauge={gauge} onSuccess={onSuccess} />
    </Drawer>
  )
}
