import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import DrawerHead from 'components/drawer/DrawerHead'
import DrawerActions from 'components/drawer/DrawerActions'
import DrawerContent from 'components/drawer/DrawerContent'
import LoadingOverlay from 'components/LoadingOverlay'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { useContext, useState } from 'react'
import {
  BLOCKCHAIN_OPTIONS,
  BOOL_FIELDS,
  FARM_FIELDS,
  FARM_TYPES,
  FLOAT_FIELDS,
  formatValues,
  READ_ONLY_FARM,
  TOOLTIP_LOCK
} from 'utils/forms'
import { useHistory, useParams } from 'react-router-dom'
import useFarmDetails from 'hooks/useFarmDetails'
import useValues from 'hooks/useValues'
import Tooltip from '@material-ui/core/Tooltip'
import { KeyboardDatePicker } from '@material-ui/pickers'
import useUpdateFarm from 'hooks/useUdpateFarm'
import { FarmsContext } from 'utils/contexts'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createOption } from 'utils/form'
import FloatInput from 'components/form/FloatInput'
import Select from 'components/form/Select'
import SelectBoolean from 'components/form/SelectBoolean'
import ImagePicker from 'components/form/ImagePicker'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 500
  },
  content: {
    flex: 1,
    height: '100%'
  },
  input: {
    flex: 1,
    marginRight: '1rem'
  },
  selectPaper: {
    boxShadow: theme.shadows[5]
  },
  imageContainer: {
    marginTop: '1rem'
  }
}))

function AddFarmDrawerContent({ onClose, edit, closeTo }) {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()

  const farmId = typeof edit === 'string' ? edit : params.id

  const { farm, isLoading } = useFarmDetails(edit ? farmId : null)
  const [search, setSearch] = useState('')
  const { values, onLock, onChange, onChangeDebounce } = useValues({
    currentDate: !edit
  })

  const { mutate: mutateFarms } = useContext(FarmsContext)

  const { onSaveFarm, isLoading: isUpdateLoading } = useUpdateFarm()

  const handleSuccess = () => {
    mutateFarms && mutateFarms()
    history.push(closeTo)
    onClose()
  }

  const handleSave = () => {
    onSaveFarm(farmId, formatValues(values), handleSuccess)
  }

  if (edit && isLoading) {
    return <LoadingOverlay />
  }

  const filterKey = FARM_FIELDS.filter((key) =>
    key.toLowerCase().startsWith(search.toLowerCase())
  )

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.content}
    >
      <DrawerHead
        title={
          'Farm: ' +
          (edit
            ? farm['farmName']?.value || 'N/A'
            : values['farmName']?.value || '')
        }
        closeTo={closeTo}
        onClose={onClose}
        component={
          <TextField
            variant="outlined"
            size="small"
            label="Search field"
            onChange={(e) => setSearch(e.target.value)}
          />
        }
      />

      <DrawerContent>
        {filterKey.map((key) => {
          const lockValue = values[key]
            ? values[key].locked
            : !!farm[key]?.locked
          const Lock = (
            <Tooltip title={TOOLTIP_LOCK}>
              <IconButton onClick={() => onLock(key, !lockValue)}>
                {lockValue ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>
            </Tooltip>
          )

          if (['dateAdded', 'dateUpdated'].includes(key)) {
            return (
              <Grid container alignItems="center" key={key}>
                <KeyboardDatePicker
                  clearable
                  value={
                    values[key]?.value
                      ? values[key]?.value
                      : farm[key]?.value
                      ? new Date(farm[key]?.value)
                      : null
                  }
                  placeholder="10/10/2018"
                  onChange={(date) => onChangeDebounce(key, date)}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  size="small"
                  margin="normal"
                  className={classes.input}
                  label={key}
                />
                {Lock}
              </Grid>
            )
          }
          if (key === 'blockchain') {
            return (
              <Grid container alignItems="center" key={key}>
                <Autocomplete
                  options={BLOCKCHAIN_OPTIONS}
                  getOptionLabel={(o) => o.label || ''}
                  className={classes.input}
                  onChange={(e, o) => onChangeDebounce(key, o)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={key}
                      variant="outlined"
                      size="small"
                      margin="normal"
                    />
                  )}
                  classes={{ paper: classes.selectPaper }}
                  defaultValue={createOption(
                    farm[key]?.value,
                    farm[key]?.value
                  )}
                />
                {Lock}
              </Grid>
            )
          }
          if (key === 'farmType') {
            return (
              <Grid container alignItems="center" key={key}>
                <Select
                  className={classes.input}
                  name={key}
                  options={FARM_TYPES.map((type) =>
                    createOption(type === null ? 'None' : type, type)
                  )}
                  defaultValue={createOption(
                    [null, ''].includes(farm[key]?.value)
                      ? 'None'
                      : farm[key]?.value,
                    farm[key]?.value
                  )}
                  onChange={(e, o) => onChangeDebounce(key, o)}
                />
                {Lock}
              </Grid>
            )
          }
          if (BOOL_FIELDS.includes(key)) {
            return (
              <Grid container alignItems="center" key={key}>
                <SelectBoolean
                  defaultValue={
                    typeof values[key]?.value === 'boolean'
                      ? values[key]?.value
                      : typeof farm[key]?.value === 'boolean'
                      ? farm[key]?.value
                      : null
                  }
                  onChange={(e, o) => onChangeDebounce(key, o.value)}
                  className={classes.input}
                  name={key}
                />
                {Lock}
              </Grid>
            )
          }
          if (FLOAT_FIELDS.includes(key)) {
            return (
              <Grid container alignItems="center" key={key}>
                <FloatInput
                  name={key}
                  value={values[key]?.value || farm[key]?.value || ''}
                  onChange={onChange}
                  variant="outlined"
                  size="small"
                  margin="normal"
                />
                {Lock}
              </Grid>
            )
          }

          if (key === 'farmIconUrl') {
            const farmIconUrl = (values[key] || farm[key])?.value
            return (
              <Grid
                className={classes.imageContainer}
                wrap="nowrap"
                container
                alignItems="center"
                key={key}
              >
                <ImagePicker
                  imageSrc={farmIconUrl}
                  imageAlt="Farm icon"
                  onChange={(url) => onChangeDebounce(key, url)}
                />
                {Lock}
              </Grid>
            )
          }

          return (
            <Grid container alignItems="center" key={key}>
              <TextField
                className={classes.input}
                label={key}
                variant="outlined"
                defaultValue={farm[key]?.value || ''}
                size="small"
                margin="normal"
                onChange={(e) => onChangeDebounce(key, e.target.value)}
                disabled={READ_ONLY_FARM.includes(key)}
              />
              {Lock}
            </Grid>
          )
        })}
      </DrawerContent>

      <DrawerActions
        cancelTo="/farms"
        onClose={onClose}
        onSave={handleSave}
        saveDisabled={isUpdateLoading}
      />
    </Grid>
  )
}

export default function AddFarmDrawer({ open, ...props }) {
  const classes = useStyles()
  return (
    <Drawer open={open} classes={{ paper: classes.paper }} anchor="right">
      <AddFarmDrawerContent {...props} />
    </Drawer>
  )
}
