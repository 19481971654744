import { useDropzone } from 'react-dropzone'
import { useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { uploadImage } from 'services/api/files'
import { toast } from 'react-toastify'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: (props) => ({
    ...(props.fullWidth && {
      width: '100%'
    })
  }),
  btnContainer: {
    margin: '1rem 0',
    display: 'flex',

    '& button': {
      marginRight: '1rem',
      alignItems: 'center'
    }
  }
})

export default function Dropzone({
  onChange,
  value,
  title = 'Select a file...',
  className,
  renderOnBtn,
  fullWidth
}) {
  const classes = useStyles({ fullWidth })
  const [isLoading, setLoading] = useState(false)

  const onDrop = useCallback(async (files) => {
    try {
      const file = files[0]
      setLoading(true)
      const res = await uploadImage(files[0])
      onChange(res.url, file.name)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
      toast.error('Cannot upload image')
    }
  }, [])

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    multiple: false
  })

  return (
    <Grid container alignItems="center">
      <div {...getRootProps()} className={classes.root}>
        <input {...getInputProps()} />

        <div className={clsx(classes.btnContainer, className)}>
          <Button variant="contained" color="primary" size="large">
            {isLoading
              ? 'Loading...'
              : renderOnBtn && value?.name
              ? value?.name
              : title}
          </Button>
        </div>
      </div>

      {!renderOnBtn && <Typography>{value?.name}</Typography>}
    </Grid>
  )
}

Dropzone.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  title: PropTypes.string
}
