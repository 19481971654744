// import { toast } from 'react-toastify'
// import Toast from 'components/badges/Toast'
// import { ERROR_PLACEHOLDER } from 'utils/errors'

export default function handleError(error) {
  const code = (error && error.response && error.response.status) || 404

  const message =
    error?.response?.data ||
    error?.response?.data?.error ||
    error?.message ||
    'Error'

  const err = {
    message,
    code
  }

  // errorToast && toast(<Toast variant="error" message={err.message} />)

  throw err
}
