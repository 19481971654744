export const INITIAL_ASSETS_FILTERS = {
  active: 'true',
  scrape_source_status: [],
  lock: [],
  sort_by_updated: undefined,
  farms_tvl_staked_gte: 0,
  exchangeName: '',
  mergeFeeAprYearly: false
}

export const INITIAL_FARMS_FILTERS = {
  active: 'true',
  riskLevel: '',
  tvl_min: 0
}

export const EXCHANGES = [{ label: 'Uniswap', value: 'Uniswap' }]

export const STATUS_FILTERS = [
  { label: 'Active', value: 'true' },
  { label: 'Inactive', value: 'false' },
  { label: 'All', value: 'all' }
]

export const SCRAPE_SOURCE_STATUSES = [
  { label: 'vfat scraping', value: 'vfat scraping' },
  { label: 'direct website scraping', value: 'direct website scraping' },
  { label: 'manually entered', value: 'manually entered' }
]

export const LOCK_STATUSES = [
  { label: 'tvlStaked', value: 'tvlStaked' },
  { label: 'aprYearly', value: 'aprYearly' },
  { label: 'stakingLink', value: 'stakingLink' }
]

export const SORT_UPDATED_OPTIONS = [
  { label: 'From oldest to newest', value: 'desc' },
  { label: 'From newest to oldest', value: 'asc' }
]

export const BOOL_FILTERS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
]

export const TVL_MIN_FILTERS = [
  { label: '0', value: 0 },
  { label: '10k', value: 10000 },
  { label: '50k', value: 50000 },
  { label: '100k', value: 100000 },
  { label: '1M', value: 1000000 },
  { label: '5M', value: 5000000 },
  { label: '10M', value: 10000000 },
  { label: '50M', value: 50000000 },
  { label: '100M', value: 100000000 },
  { label: '500M', value: 500000000 },
  { label: '1B', value: 1000000000 }
]

export const PREMIUM_PAGES_OPTIONS = [
  { value: 'saved_farms', label: 'Saved Farms' },
  { value: 'saved_assets', label: 'Saved Assets' },
  { value: 'wallets', label: 'Wallets' },
  { value: 'portfolio_allocation', label: 'Portfolio Allocation' },
  { value: 'strategies_performance', label: 'Strategies Performance' },
  { value: 'strategies_detailed', label: 'Strategies Detailed' },
  { value: 'pnl', label: 'PnL' },
  { value: 'other_revenue', label: 'Other Revenue' },
  { value: 'protocol_trading_data', label: 'Protocol Trading Data' },
  { value: 'cms', label: 'CMS' },
  { value: 'triggers', label: 'Triggers' },
  { value: 'exchange_data', label: 'Exchange Data' },
  { value: 'research_alpha', label: 'Research & Alpha' },
  { value: 'leaderboard', label: 'Leaderboard' },
  { value: 'labels', label: 'Labels' },
  { value: 'trending_pools', label: 'Trending Pools' }
]

export const RISK_LEVEL_OPTIONS = [{ label: 'High Risk', value: 'High Risk' }]

export function GTLTFilter(value) {
  return {
    gt: value[0],
    lt: value[1]
  }
}

export function GTFilter(value) {
  return {
    gt: value
  }
}

export function EQFilter(value) {
  return {
    eq: value
  }
}

export function INFilter(value) {
  return {
    in: value
  }
}

export function GTLTFilterEmpty(e) {
  return !!e.filter(Boolean).length
}

export function GTFilterEmpty(e) {
  return !!e
}

export function EQFilterEmpty(e) {
  return !!e
}

export function INFilterEmpty(e) {
  return !!e.length
}

export const QUERY_KEYS = {
  pairPriceStd30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  lpPriceStd30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  netRoiLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  lpReturnHODLLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  netPrincipalRoiLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  impLossLast30d: {
    valid: GTLTFilterEmpty,
    format: GTLTFilter
  },
  exchangeName: {
    valid: INFilterEmpty,
    format: INFilter
  },
  blockchain: {
    valid: INFilterEmpty,
    format: INFilter
  },
  liquidityUSD: {
    valid: GTFilter,
    format: GTFilter
  }
}

export function formatQueryFilters(obj, keys) {
  const filtersObj = {}

  keys.forEach((key) => {
    if (obj[key] && QUERY_KEYS[key] && QUERY_KEYS[key].valid(obj[key])) {
      filtersObj[key] = QUERY_KEYS[key].format(obj[key])
    }
  })

  return filtersObj
}
