import { Switch, Route } from 'react-router-dom'
import ContentSelect from 'pages/landing/ContentSelect'
import Blog from 'pages/landing/Blog'
import AddBlog from 'pages/landing/AddBlog'
import Careers from 'pages/landing/Careers'
import AddCareer from 'pages/landing/AddCareer'

export default function Landing() {
  return (
    <Switch>
      <Route exact path="/landing/blog/:id">
        <AddBlog />
      </Route>
      <Route exact path="/landing/blog">
        <Blog />
      </Route>
      <Route exact path="/landing/careers/:id">
        <AddCareer />
      </Route>
      <Route path="/landing/careers">
        <Careers />
      </Route>
      <Route path="/landing">
        <ContentSelect />
      </Route>
    </Switch>
  )
}
