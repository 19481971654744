import Api from 'services/Api'
import { getToken, setToken } from 'services/auth'

export async function login(user, pass) {
  const token = getToken(user, pass)
  const response = await Api.get(
    '/login',
    {},
    {
      headers: {
        Authorization: token
      }
    }
  )
  const data = response.data
  setToken(token, data.user, data['user_color'])
  return data
}
