import TablePageContainer from 'components/TablePageContainer'
import useLeaderboards from 'hooks/useLeaderboards'
import AddLeaderboardDrawer from 'components/AddLeaderboardDrawer'
import { useState } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import format from 'date-fns/format'
import { DATE_FORMAT } from 'utils/date'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { Link, useParams } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import makeStyles from '@material-ui/core/styles/makeStyles'
import LeaderboardsProvider from 'components/providers/LeaderboardsProvider'

const useStyles = makeStyles({
  button: {
    marginRight: '1rem'
  }
})

const COLS = [
  { label: 'Twitter ID', name: 'name' },
  { label: 'Name', name: 'leaderboard_name' },
  { label: 'Blockchain', name: 'blockchain' },
  { label: 'Category', name: 'category' },
  { label: 'Created', name: 'createdDate' },
  { label: '', name: 'actions', align: 'right' }
]

export default function Leaderboards() {
  const classes = useStyles()
  const params = useParams()
  const [addDrawer, setAddDrawer] = useState(false)
  const { isLoading, leaderboards, mutate, onRemove } = useLeaderboards()

  return (
    <LeaderboardsProvider mutate={mutate}>
      <TablePageContainer
        page={1}
        maxPages={1}
        cols={COLS}
        onSearch={() => {}}
        loading={isLoading}
        onPageChange={() => {}}
        onAdd={() => setAddDrawer(true)}
        onFilter={() => {}}
      >
        {leaderboards.map((row, index) => (
          <TableRow key={row.name + index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.leaderboard_name || '-'}</TableCell>
            <TableCell>{row.blockchain || '-'}</TableCell>
            <TableCell>{row.category || '-'}</TableCell>
            <TableCell>
              {row.createdDate
                ? format(new Date(row['createdDate']), DATE_FORMAT)
                : '-'}
            </TableCell>
            <TableCell align="right">
              <Tooltip title="Edit Leaderboard">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component={Link}
                  to={`/leaderboards/${row['name']}`}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete Leaderboard">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onRemove(row['name'])}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TablePageContainer>

      <AddLeaderboardDrawer
        open={addDrawer || !!params.id}
        onClose={() => setAddDrawer(false)}
        closeTo="/leaderboards"
      />
    </LeaderboardsProvider>
  )
}
