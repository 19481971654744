import Api from 'services/Api'

export async function getGroups(url) {
  const response = await Api.get(url)
  return response.data
}

export async function updateGroup(groups) {
  const response = await Api.put('/edit_coin_group_filters', {
    groups
  })
  return response.data.data
}
