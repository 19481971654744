import useFarms from 'hooks/useFarms'
import { createOption } from 'utils/form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5]
  }
}))

function farmsToOptions(farms) {
  try {
    return farms.map((row) => {
      return createOption(
        `${row['farmName']} (${row['blockchain']})`,
        row['farmId']
      )
    })
  } catch (e) {
    console.error(e)
    return []
  }
}

export default function SelectFarms({
  className,
  onChange,
  defaultValue,
  ...props
}) {
  const classes = useStyles()
  const { farms, onSearch } = useFarms()
  const options = farmsToOptions(farms)
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(o) => o.label || ''}
      className={className}
      onChange={(e, o) => onChange(o)}
      renderInput={(params) => <TextField {...params} {...props} />}
      classes={{ paper: classes.paper }}
      defaultValue={defaultValue}
      onInputChange={(e) => onSearch(e?.target?.value || '')}
    />
  )
}

SelectFarms.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any
}
